export enum DocumentReviewActionDispatchTypes {
  DOCUMENT_REVIEW_INVOKE = "DOCUMENT_REVIEW_INVOKE",
  DOCUMENT_REVIEW_SUCCESS = "DOCUMENT_REVIEW_SUCCESS",
  DOCUMENT_REVIEW_RESET = "DOCUMENT_REVIEW_RESET",
}

export enum SaveUpdateDocumentDetailActionDispatchTypes {
  DOCUMENT_DETAIL_SAVE_ERROR = "DOCUMENT_DETAIL_SAVE_ERROR",
  DOCUMENT_DETAIL_SAVE_INVOKE = "DOCUMENT_DETAIL_SAVE_INVOKE",
  DOCUMENT_DETAIL_SAVE_SUCCESS = "DOCUMENT_DETAIL_SAVE_SUCCESS",
}

export enum ReferralDocumentFileDispatchTypes {
  REFERRAL_DOCUMENT_ERROR = "REFERRAL_DOCUMENT_ERROR",
  REFERRAL_DOCUMENT_INVOKE = "REFERRAL_DOCUMENT_INVOKE",
  REFERRAL_DOCUMENT_SUCCESS = "REFERRAL_DOCUMENT_SUCCESS",
  REFERRAL_DOCUMENT_RESET = "REFERRAL_DOCUMENT_RESET",
}

export enum DocumentDownloadDispatchTypes {
  DOCUMENT_DOWNLOAD_ERROR = "DOCUMENT_DOWNLOAD_ERROR",
  DOCUMENT_DOWNLOAD_INVOKE = "DOCUMENT_DOWNLOAD_INVOKE",
  DOCUMENT_DOWNLOAD_RESET = "DOCUMENT_DOWNLOAD_RESET",
  DOCUMENT_DOWNLOAD_SUCCESS = "DOCUMENT_DOWNLOAD_SUCCESS",
}

export enum ExcelDownloadDispatchTypes {
  EXCEL_DOWNLOAD_ERROR = "EXCEL_DOWNLOAD_ERROR",
  EXCEL_DOWNLOAD_INVOKE = "EXCEL_DOWNLOAD_INVOKE",
  EXCEL_DOWNLOAD_RESET = "EXCEL_DOWNLOAD_RESET",
  EXCEL_DOWNLOAD_SUCCESS = "EXCEL_DOWNLOAD_SUCCESS",
}

const itemHeight = 48;
const itemPaddingTop = 8;
export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: "15.5rem",
    },
  },
};

export enum DocumentType {
  PDF = "pdf",
  DOC = "msword",
  DOCX = "vnd.openxmlformats-officedocument.wordprocessingml.document",
  PNG = "png",
  JPEG = "jpeg",
  JPG = "jpg",
  INVALID = "",
}

export const faxDefaultValues = {
  referralBlobId: 0,
  referralId: 0,
  blobId: 0,
  faxNumber: "",
  receivedDateTime: "",
  notes: "",
  sendTo: "",
  sentFrom: "",
  faxFile: "",
};

export enum GenerateAuthTemplateDispatchTypes {
  GENERATE_AUTH_TEMPLATE_ERROR = "GENERATE_AUTH_TEMPLATE_ERROR",
  GENERATE_AUTH_TEMPLATE_INVOKE = "GENERATE_AUTH_TEMPLATE_INVOKE",
  GENERATE_AUTH_TEMPLATE_SUCCESS = "GENERATE_AUTH_TEMPLATE_SUCCESS",
}

export const Source = {
  FAX: "Fax",
  INBOUND: "Inbound",
};

export enum DocumentTabType {
  DOCUMENT = 1,
  AUTH_DETAILS = 0,
}

export enum FaxSentStatus {
  Success = "Success",
  Error = "Error",
  InProgress = "In Progress",
  Queued = "Queued",
  LineBusy = "Line Busy",
  LineDead = "Line Dead",
  LineFailure = "Line Failure",
  NoDialTone = "No Dial Tone",
  NoAnswer = "No Answer",
  InvalidOrMissingNumber = "Invalid Or Missing Number",
  InvalidOrMissingFile = "Invalid Or Missing File",
  InvalidChannel = "Invalid Channel",
  UnexpectedDisconnect = "Unexpected Disconnect",
  NoChannelsAvailable = "No Channels Available",
  ChannelUnavailable = "Channel Unavailable",
  NothingToCancel = "Nothing To Cancel",
  DeviceTimeout = "Device Timeout",
  DeviceBusy = "Device Busy",
  NotFaxMachine = "Not Fax Machine",
  IncompatibleFaxMachine = "Incompatible Fax Machine",
  FileError = "File Error",
  FileNotFound = "File Not Found",
  FileUnsupported = "File Unsupported",
  CallCollision = "Call Collision",
  Cancelled = "Cancelled",
  CallBlocked = "Call Blocked",
  DestinationBlackListed = "Destination BlackListed",
  RemoteDisconnect = "Remote Disconnect",
  NegotiationError = "Negotiation Error",
  TrainingError = "Training Error",
  Unauthorized = "Unauthorized",
  InvalidParameter = "Invalid Parameter",
  NotImplemented = "Not Implemented",
  ItemNotFound = "Item Not Found",
  EncryptionDisabled = "Encryption Disabled",
  EncryptionRequired = "Encryption Required",
  DecryptFailure = "Decrypt Failure",
  DocumentRejected = "Document Rejected",
  DocumentNotSupported = "Document Not Supported",
  DocumentTooLarge = "Document Too Large",
}
