import { Type } from "../models/HealthPlan";

export enum TrackingLabels {
  MODULE_RESPONSE = "Module Response",
  SUB_MODULE_RESPONSE = "Sub Module Response",
}

export enum referralPageIds {
  CREATE_NEW_REFERRAL = 1,
  REFERRAL_SOURCE_DETAILS = 2,
  INSURANCE_DETAILS = 3,
  PHYSICIAN_DETAILS = 4,
}

export const inputType = {
  patientImage: "patientImage",
  patientDocs: "patientDocs",
  profilePicture: "profilePicture",
  referralDocs: "referralDocs",
  userProfile: "userProfile",
};

export enum PatientPreferredLanguage {
  SPANISH = "SPANISH",
  ENGLISH = "ENGLISH",
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export enum RequesterComponentInjectType {
  PRIOR_AUTH = "priorAuth",
  AUTH_DETAILS = "authDetails",
}
export const GENDER_DROPDOWN_OPTIONS: Type[] = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
];

export enum NotifyInternalDataType {
  PRIOR_AUTH_REQUEST_EMERGENCY_CONTACT = "PRIOR_AUTH_REQUEST_EMERGENCY_CONTACT",
  PATIENT_HEADER_EMERGENCY_CONTACT = "PATIENT_HEADER_EMERGENCY_CONTACT",
  PRIOR_AUTH_REQUEST_PRIMARY_PHYSICIAN_INFO = "PRIOR_AUTH_REQUEST_PRIMARY_PHYSICIAN_INFO",
}

export const DISCIPLINE_DETAILS = true;
export const URGENCY_STATUS_EXPEDITED_ID = 2;
export const REQUEST_FACILITY_NAME = "Health Plan Case Manager";

export const requiredFieldMsg = {
  EMERGENCY_CONTACT_NAME: "Contact name is required.",
};

export enum EmergencyContactTypes {
  ALTERNATE_CONTACT = "ALTERNATE_CONTACT",
  REQUEST_EMERGENCY_CONTACT = "REQUEST_EMERGENCY_CONTACT",
}
type KeyMapping = {
  [key: string]: string;
};

export const keyMapping: KeyMapping = {
  FunctionalMobility: "Functional Mobility",
  ADLS: "ADLs",
  livingStatus: "Living Status",
  AvailableAssistance: "Available Assistance",
  BedMobility: "Bed Mobility",
  TransferSitToStand: "Transfer: Sit to Stand",
  ToiletingHygiene: "Toileting/Hygiene",
  TransferToilet: "Transfer: Toilet",
  TransferTubShower: "Transfer: Tub/Shower",
  BathingShowering: "Bathing/Showering",
  LowerBodyDressing: "Lower Body Dressing",
  UpperBodyDressing: "Upper Body Dressing",
  Grooming: "Grooming",
  Ambulation: "Ambulation",
  Inpatient: "Inpatient",
  Current: "Current",
  UpperExtremities: "Upper Extremities",
  LowerExtremities: "Lower Extremities",
  PriorDevices: "Prior Devices",
  CurrentDevices: "Current Devices",
  RecommendedDevices: "Recommended Devices",
  OtherDevices: "Other Assistive Devices",
  Established: "Established",
  PlanToEstablishHep: "Plan to Establish HEP",
};

export const DressingData = [
  { value: 1, text: "Max Assistance" },
  { value: 2, text: "Mod Assistance" },
  { value: 3, text: "Min Assistance" },
  { value: 4, text: "CGA" },
  { value: 5, text: "SBA" },
  { value: 6, text: "Sup/touching" },
  { value: 7, text: "Sup" },
  { value: 8, text: "Mod Independent" },
  { value: 9, text: "Independent" },
];

export const Clofchoices = [
  { value: "ModI", text: "Modified Independence" },
  { value: "Ind", text: "Independent" },
  { value: "ReqAsst", text: "Required Assistance" },
];

export const OTClofChoices = [
  {
    value: "Mod Ind",
    text: "Modified Independence",
  },
  {
    value: "Ind",
    text: "Independent",
  },
  {
    value: "Req Asst",
    text: "Required Assistance",
  },
];

export const AssistiveDeviceData = [
  { value: "none", text: "No assistive devices" },
  { value: "cane", text: "Cane" },
  { value: "walker", text: "Walker" },
  { value: "wheelchair", text: "Wheelchair" },
  { value: "powerwheelchair", text: "Power wheelchair" },
];

export const LofColumnKeys = {
  FunctionalMobility: {},
  ADLS: {},
  livingStatus: {},
  AvailableAssistance: {},
  BedMobility: {},
  TransferSitToStand: {},
  ToiletingHygiene: {},
  TransferToilet: {},
  TransferTubShower: {},
  BathingShowering: {},
  LowerBodyDressing: {},
  UpperBodyDressing: {},
  Grooming: {},
  Ambulation: {},
  Inpatient: {},
  Current: {},
  UpperExtremities: {},
  LowerExtremities: {},
  PriorDevices: {},
  CurrentDevices: {},
  RecommendedDevices: {},
  OtherDevices: {},
  Established: {},
  PlanToEstablishHep: {},
};

export const LofCurrentLofChoices = [
  {
    value: "MaxAsst",
    text: "Max Assistance",
  },
  {
    value: "ModAsst",
    text: "Mod Assistance",
  },
  {
    value: "MinAsst",
    text: "Min Assistance",
  },
  "CGA",
  "SBA",
  "Sup/touching",
  "Sup",
  {
    value: "ModInd",
    text: "Mod Independent",
  },
  {
    value: "Ind",
    text: "Independent",
  },
];

export const PTLivingStatus = [
  {
    value: "lives w other",
    text: "Lives with other person in the home",
  },
  {
    value: "alone",
    text: "Lives alone",
  },
  {
    value: "congregate",
    text: "Lives in Congregate setting (ALF/GH/MC)",
  },
  {
    value: "no doc",
    text: "No documentation received",
  },
];

export const OTLivingStatus = [
  "Lives with other person in the home",
  "Lives alone",
  {
    value: "Lives in Congregate ",
    text: "Lives in Congregate setting (ALF/GH/MC)",
  },
  {
    value: "NoDocs",
    text: "No documentation received",
  },
];

export const PTAvailableAssist = [
  {
    value: "ATC",
    text: "Around the clock",
  },
  {
    value: "Reg daytime",
    text: "Regular daytime",
  },
  {
    value: "Reg nighttime",
    text: "Regular nighttime",
  },
  {
    value: "limited",
    text: "Limited Assistance",
  },
  {
    value: "Occ short term",
    text: "Occasional short-term assistance",
  },
  {
    value: "Paid cg",
    text: "Paid Caregiver",
  },
  {
    value: "no asst avail",
    text: "No assistance available",
  },
  {
    value: "no doc",
    text: "No documentation received",
  },
];

export const OTAvilableAssist = [
  "Around the clock",
  "Regular daytime",
  "Regular nighttime",
  {
    value: "Limited",
    text: "Limited Assistance",
  },
  "Occasional short-term assistance",
  "Paid Caregiver",
  "No assistance available",
  {
    value: "NoDocs",
    text: "No documentation received",
  },
];

export const HeaderLabelDocumentReview = [
  { label: "DOC NO.", width: "7%", displayIcon: false, isDocNo: true },
  { label: "DOCUMENT NAME", width: "17%", displayIcon: false, isDocNo: false },
  { label: "DATE / TIME", width: "16%", displayIcon: false, isDocNo: false },
  { label: "SOURCE", width: "7%", displayIcon: false, isDocNo: false },
  { label: "DOC TYPE", width: "16%", displayIcon: false, isDocNo: false },
  { label: "CREATED BY", width: "15%", displayIcon: false, isDocNo: false },
  { label: "ACTIONS", width: "8%", displayIcon: false, isDocNo: false },
];

export const LofRowKeys = [
  "Functional Mobility",
  "Living Status",
  "Upper Extremities",
  "Prior Devices",
  "Inpatient",
  "Established",
];
