import { ServiceProviderData, StageData } from "./Authorization";
import {
  DocumentsDetail,
  ReAuthReviewBlobDoc,
  ReviewDocumentationDetail,
} from "./Document";
import {
  OrderingPhysicianDetails,
  PrimaryPhysicianDetails,
  ReAuthFollowingPhysicianDetails,
  ReAuthOrderingPhysicianDetails,
  ReAuthPrimaryPhysicianDetails,
} from "./PhysicianDetails";
import { ReAuthServiceRequest, ReviewRequest } from "./Service";

export interface PatientState {
  patientState: string;
}
export interface County {
  county: string;
}
export interface Patient {
  ahcccsid: string | null;
  dateOfBirth: string;
  fullName: string;
  gender: string;
  patientId: number;
  patientMBI: string;
}

export interface PatientInformation extends PatientDetail {
  dateRequestReceived: string;
  isPatientHomebound: string;
  timeRequestReceived: string;
  urgencyStatus: string;
}
export interface AssignedStaff {
  assignedUserFullName: string;
  assignedUserId: number;
  disciplineRequestIds: number[];
  userCreated: string | null;
}

export interface PatientDetail {
  patientEntity: {
    dateCreated: string;
    dateOfBirth: string | null;
    firstName: string;
    gender: string;
    lastName: string;
    middleName: string;
    patientId: number;
    pictureBlobId: number | null;
    referralId: number;
    referralReceivedDate?: string | null; //TBD
    referralReceivedTime?: string | null; //TBD
    userCreated: string | null;
  };
  addressEntity: {
    addressId: number;
    city: string;
    county?: string; //TBD
    email?: string;
    extension?: string; //TBD
    patientState: string;
    phoneNo: string;
    streetName: string;
    streetName2?: string;
    zipCode: string;
  };
  referralEntity: {
    isNavihealth: boolean;
    receivedDateTime: string | null;
    receivedMethod: number;
    receivedReferralSystem: any;
  }; //TBD
}

export interface ReceivedMethods {
  receivedMethodId: number | null;
  receivedMethodName: string;
  isIntegrationMethod: boolean;
}
export interface EditFlag {
  editButtonFlag: boolean;
}
export interface Status {
  id: number;
  status: string;
}
export interface MyPatient {
  totalRows: number;
  searchResponse: AllPatientDetails[];
}

export interface AllPatientDetails {
  assignedToStaffId: number;
  serviceRequestDisciplineId: number;
  patientId: number;
  referralId: number;
  fullName: string;
  patientState: string;
  gender: string;
  urgencyStatus: string;
  providerNpi: string;
  requestDateTime: string;
  healthPlanName: string;
  assignedUserFullName: string;
  disciplineName: string;
  serviceStatus: string;
  serviceStatusReason: string;
  serviceRequestTypeName: string;
  authorizationNumber: string;
}

export interface PatientReferralInfo {
  refId: number;
  fullName: string;
  dob: string;
  gender: string;
  address: string;
}

export interface PatientRecordSearch {
  totalRows: string;
  patientResponse: AllPatientRecord[];
}

export interface AllPatientRecord {
  patientId: number;
  dateOfBirth: string;
  gender: string;
  fullName: string;
  medicareBeneficiaryIdentifier: number;
  streetName1: string;
  city: string;
  patientState: string;
  eligibilityDates: string;
  subscriberId: number;
  authId: number;
  healthPlan: string;
  benefitPlan: string;
  issuanceState: string;
  product: string;
  applicableFrom: string;
  applicableTill: string;
  language: string;
  blobId: number;
  blobFileName: string;
}

export interface Review {
  referralDetail: ReferralDetail;
  emergencyContact: EmergencyContact[];
  address: Address;
  requestor: Requestor;
  referralSource: ReferralSource;
  icdCode: ICDCodes[];
  primaryPhysician: PrimaryPhysicianDetails;
  orderingPhysician: OrderingPhysicianDetails;
  serviceProvider: ServiceProviderData;
  serviceRequest: ReviewRequest;
  referralDocument: ReviewDocumentationDetail;
  blobDocument: DocumentsDetail[];
}

export interface PatientFilterOptions {
  value: string;
  label: string;
}

export interface SaveIcd10Records {
  id: number;
  userCreated: string | null;
  icdCodesInfoDto: ICDCodes[];
  typeId: number;
}

export interface ICDCodes {
  referralIcdCodeId: number;
  diagnosisTypeId: number;
  diagnosisCode: string;
  diagnosisCodeDescription: string;
  icdDiagnosisCodeId: number;
}

export interface UrgencyStatusMaster {
  urgencyId: number;
  urgencyStatus: string;
}

export interface RelationshipMaster {
  relationId: number;
  relationshipName: string;
}

export interface EmergencyContactTable {
  id: number;
  relationshipId: number;
}

export interface StageStatus {
  stageId: number;
  stageStatus: string;
}

export interface PatientRequest {
  referralDetail: ReferralDetail;
  emergencyContact: EmergencyContact[];
  residentialAddress: RequestAddress;
  serviceAddress: RequestAddress;
  requester: PriorAuthRequestor;
  referralSource: PriorAuthReferralSource;
  isSameAsResidential: boolean;
}

export interface ReAuthReferral {
  referralId: number;
  patientId: number;
  receivedDateTime: string;
  urgencyId: number | null;
  userCreated: string | null;
  urgencyStatus?: string;
  serviceRequestId?: number;
  receivedMethodName?: string;
}

export interface ReferralDetail {
  referralId: number;
  patientId: number;
  receivedDateTime: string;
  urgencyId: number | null;
  receivedMethodId: number | null;
  receivedMethodName: string | null;
  isHomeBound: string;
  isSignedHealthOrder: string;
  signedOrderDate: string | null;
  signedOrderType: string;
  orderedById: number | null;
  acceptedById: number | null;
  isCustodialCare: string;
  isNonCoveredService: string;
  userCreated: string | null;
  nonCoveredServices: string | null;
  healthProgramId: number | null;
  careProgramId: number | null;
  powerOfAttorney: string | null;
  urgencyStatus: string;
  orderedByName: string;
  acceptedByName: string;
  nonCoveredServiceNames: string;
  patientEligibilityId: number;
  authorizationTypeId: number;
  authorizationTypeName: string;
}

export interface EmergencyContact {
  contactName: string;
  relationId: number | null;
  phoneNo: string;
  extension: string;
  relationshipName: string;
  preferredLanguage: string;
}

export interface Address {
  addressTypeId: number;
  streetName1: string | null;
  streetName2: string | null;
  county: string | null;
  city: string | null;
  patientState: string | null;
  zipCode: string | null;
  latitude: number | null;
  longitude: number | null;
}

export interface RequestAddress {
  streetName1: string | null;
  streetName2: string | null;
  county: string | null;
  city: string | null;
  patientState: string | null;
  zipCode: string | null;
  latitude: number | null;
  longitude: number | null;
  streetName: string | null;
  addressTypeId: number | null;
}

export interface ReferralSource {
  facilityTypeId: number | null;
  referralSourceName: string;
  referralSourceExtension: string | null;
  referralSourcePhoneNo: string;
  referralSourceEmail: string | null;
  referralSourceFax: string | null;
  streetName1: string | null;
  streetName2: string | null;
  county: string | null;
  city: string | null;
  referralSourceState: string | null;
  zipCode: string | null;
  sameAsRequestingFacility: boolean;
  dischargeDate: string | null;
  facilityTypeName: string;
}

export interface PriorAuthRequestor extends Requestor {
  facilityName: string;
  requesterNpi: number | null;
}

export interface PriorAuthReferralSource extends ReferralSource {
  referralSourceNpi: number | null;
  facilityName: string;
}

export interface PatientInfoView {
  //TBD
  patientId: number;
  dob: string;
  dateOfInsurance: string;
  subscriberNumber: number;
  gender: string;
  firstName: string;
  lastName: string;
  mbi: number;
  plan: string;
  language: string;
  eligibilityDates: string;
  phone: string;
  emergencyPhone: string;
  residentialAddress: BasicAddressDetails;
  mailingAddress: BasicAddressDetails;
}

export interface BasicAddressDetails {
  address: string;
  city: string;
  state: string;
}

export interface AuthTimeline {
  workflowStageId: number;
  workflowStageName: string;
}

export interface TimelineAuthStatus extends AuthTimeline {
  patientId: number;
  referralId: number;
  timelineId: number;
  stageDescription: string;
  additionalInformation: string;
  stageStatus: string;
}

export interface AuthTimelineStages extends TimelineAuthStatus {
  completedOn: string;
  stageData: StageData[];
}

export interface ActiveAuth {
  patientId: number;
  authorizationDetailId: number;
  referralStatusId: number;
  referralStatusName: string;
  serviceProviderNpi: number;
  admitDate: string;
  healthPlan: string;
  dischargeDate: string;
  totalApproved: number;
  snCount: number;
  ptCount: number;
  otCount: number;
  stCount: number;
  mswCount: number;
  hhaCount: number;
  referralId: number;
}

export interface ReferralOverviewDetail {
  authId: number;
  benifitPlan: string;
  blobFileName: string;
  blobId: number;
  dateOfBirth: string;
  datesApplicable: string;
  gender: string;
  healthPlan: string;
  languagePreferenceValue: string;
  medicareBeneficiaryIdentifier: string;
  memberName: string;
  patientEligibilityId: number;
  patientId: number;
  product: string;
  referralId: number;
  referralStageId: number;
  referralStageName: string;
  stateOfInsurance: string;
  subscriberId: number;
}

export interface ReAuthReferralOverviewDetail extends ReferralOverviewDetail {
  reAuthId: number;
  insuranceState: string;
}

export interface ReferralStage {
  referralStageId: number;
  referralStageName: string;
}

export interface ReferralSubmit {
  isCompleted: boolean | null;
  referralStage: ReferralStage[];
}

export enum PatientAddressTypeId {
  RESIDENTIAL = 1,
  SERVICE = 2,
  MAILING = 3,
}

export interface SavePatientInformation {
  informationReceived: EligibilityDataInformation;
  createPatient: {
    dateOfBirth: string;
    firstName: string;
    gender: string;
    lastName: string;
    middleName: string;
    patientId: number;
    userCreated: string | null;
    emailAddress: string;
    phoneNo: string | number;
    extNo: string;
    languagePreferenceKind?: string | null;
    languagePreferenceValue?: string | null;
    cellPhone?: string | null;
    isManual: boolean;
  };
  patientResidentialAddress: PatientResidentialAddress;
  patientMailingAddress: PatientMailingAddress;
}

export interface PatientResidentialAddress {
  streetName1: string | null;
  streetName2: string | null;
  county: string | null;
  city: string | null;
  patientState: string | null;
  zipCode: string | null;
  latitude: number | null;
  longitude: number | null;
  addressTypeId: number;
  country: string | null;
}

export interface PatientMailingAddress {
  streetName1: string | null;
  streetName2: string | null;
  county: string | null;
  city: string | null;
  patientState: string | null;
  zipCode: string | null;
  latitude: number | null;
  longitude: number | null;
  addressTypeId: number;
  country: string | null;
}

export interface InformationReceived {
  ahcccsId: string | null;
  medicareBeneficiaryIdentifier: string | null | undefined;
  patientId: number;
  product: string | null | undefined;
  effectiveDate: string;
  effectiveThrough: string;
  eligibilityStatus: string;
  policyId?: string;
  subscriberId: string | null;
  healthPlanUid: string;
  benefitPlanUid: string;
  apiEligibilityId: string | null;
  eligibilityPayerId: string | null;
  eligibilityPayerName: string | null;
  fullNameCreated: string | null;
}

export interface EligibilityDataInformation extends InformationReceived {
  isManual: boolean;
  healthPlan: string;
  benefitPlanName: string;
  healthPlanState: string;
  alternatePhone: string | null;
}

export interface HealthPlanPayload {
  dob: string;
  lastName: string;
  payerId: string;
  payerName: string;
  policyId: string;
}

export interface SaveInformation {
  informationReceived: EligibilityDataInformation;
  patientResidentialAddress: PatientResidentialAddress;
  patientMailingAddress: PatientMailingAddress;
}

export interface PatientInfoResponse {
  benefitPlan: string;
  dateOfBirth: string;
  dateOfIssuance: string;
  eligibilityDates: string;
  emergencyExtension: string;
  emergencyPhoneNo: string;
  extension: string;
  gender: string;
  healthPlan: string;
  languagePreferenceValue: string;
  mailingAddress: string;
  medicareBeneficiaryIdentifier: string;
  patientId: number;
  patientName: string;
  phoneNo: string;
  product: string;
  residentialAddress: string;
  stateOfIssuance: string;
  subscriberId: string;
  patientHistory: {
    benefitPlan: string;
    eligibilityCheckDate: string;
    enrollmentDates: string;
    insurance: string;
    patientId: number;
    policyId: string;
    product: string;
    stateOfIssuance: string;
  };
}

export interface ReferralRequest {
  patientEligibilityId: number;
  receivedDateTime: string;
  receivedMethodId: number | null;
  referralId: number;
  serviceRequestId: number;
  urgencyId: number | null;
  urgencyStatus?: string;
  userCreated: string | null;
}

export interface Requestor {
  facilityTypeId: number | null;
  requesterName: string;
  requesterExtension: string;
  requesterPhoneNo: string;
  requesterEmail: string | null;
  requesterFax: string;
  streetName1: string | null;
  streetName2: string | null;
  county: string | null;
  city: string | null;
  requesterState: string | null;
  zipCode: string | null;
  facilityTypeName: string;
}

export interface ReAuthRequestor extends Requestor {
  serviceRequestId: number;
}

export interface ReAuthReferralRequest {
  referralRequest: ReferralRequest;
  requester: ReAuthRequestor;
}

export interface CheckEligibility {
  additionalNotes: {
    apiRequestId: string;
    benefitPlanUid: string;
    healthPlanUid: string;
    scrubbedSubscriberID: string;
    scrubbedZipCode: string;
    scrubbedZipCodeExtension: string;
  };
  record: {
    subscriberGender: string;
    benefitsLoop: BenefitsLoop;
    subscriberAddress: SubscriberAddress;
    subscriberDOB: string;
    subscriberDTLoop: SubscriberDTLoop;
    subscriberId: string;
    subscriberName: SubscriberName;
    version: string;
  };
  supplementalInformation: {
    info: Information[];
  };
}

export interface SubscriberName {
  last: string;
  first: string;
}

export interface SubscriberAddress {
  city: string;
  state: string;
  street: string;
  zip: string;
}

export interface SubscriberDTLoop {
  subscriberDT: SubscriberDT[];
}

export interface SubscriberDT {
  dt: string;
  dtFormatQUalifier: string;
  dtQualifier: string;
}

export interface BenefitsLoop {
  benefits: Benefits[];
}

export interface Benefits {
  amount: string;
  coverageLevelCode: string;
  inPlanNetworkIndicator: string;
  messageLoop: MessageLoop;
  qualifier: string;
  serviceTypeCode: string;
  timePeriodQualifier: string;
}

export interface MessageLoop {
  message: Message[];
}

export interface Message {
  messageText: string;
}

export interface Information {
  address: InfoAddress;
  alternatePhone: string;
  emailAddress: string;
  employeeId: string;
  employerName: string;
  employmentStatusCode: string;
  groupOrPolicyNumber: string;
  handicapIndicator: string;
  healthCoverage: HealthCoverage;
  hicNumber: string;
  homePhone: string;
  lastTouchedDate: string;
  mbi: string;
  medicaidId: string;
  memberFound: string;
  mobilePhone: string;
  studentStatusCode: string;
  userDefined1: string;
  userDefined2: string;
  userDefined3: string;
  userDefined4: string;
  userDefined5: string;
  userDefined6: string;
  workPhone: string;
}

export interface InfoAddress {
  addressLine: string;
  addressLine2: string;
  addressType: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface HealthCoverage {
  beginDate: string;
  coverageLevelCode: string;
  endDate: string;
  groupOrPolicyNumber: string;
  insuranceLineCode: string;
  planCoverageDescription: string;
  providerInfo: ProviderInfo;
}

export interface ProviderInfo {
  addressLine: string;
  cityName: string;
  firstName: string;
  orgOrLastName: string;
  providerIdentifier: string;
  providerType: string;
  stateCode: string;
  zipCode: string;
}

export interface PatientSearch {
  contains: boolean;
  dateOfBirth: string;
  firstName: string;
  authorizationNumber: string;
  lastName: string;
  pageNo: number;
  pageSize: number;
}

export interface PatientSearchPayload extends PatientSearch {
  healthPlan: string;
  subscriberId: string;
}

export interface MyPatientSearch {
  assignedToStaffId?: string;
  contains: boolean;
  dynamic: string;
  filterBy: string;
  firstName: string;
  healthPlanName?: string;
  lastName: string;
  pageNo: number;
  pageSize: number;
  sortColumn?: string;
  sortOrder?: string;
}

export interface IncompletePatientSearch {
  totalRows: number;
  searchResponse: IncompleteRequestSearchResp[];
}

export interface IncompleteRequestSearchResp {
  assignedUserFullName: string;
  assignedUserId: number;
  createdBy: string;
  fullName: string;
  healthPlanName: string;
  patientId: number;
  patientState: string;
  facilityTypeName: string;
  referralId: number;
  requestDateTime: string;
  serviceRequestId: number;
  serviceRequestTypeName: string;
  urgencyStatus: string;
}

export interface ReAuthReview {
  reAuthFollowingPhysicianDetail: ReAuthFollowingPhysicianDetails;
  reAuthIcdCode: ICDCodes[];
  reAuthOrderingPhysician: ReAuthOrderingPhysicianDetails;
  reAuthPrimaryPhysician: ReAuthPrimaryPhysicianDetails;
  reAuthReferralDetail: ReAuthReferral;
  reAuthReferralDocuments: ReviewDocumentationDetail;
  reAuthRequester: ReAuthRequestor;
  reAuthServiceRequestDetails: ReAuthServiceRequest;
  reAuthBlobDocumentDetail: ReAuthReviewBlobDoc[];
}

export interface ReviewServiceDiscipline {
  currentRequestedVisit: number;
  disciplineId: number;
  disciplineName: string;
  nextRequestedVisit: number;
  serviceNumber: number;
  totalRequestedVisit: number;
}

export interface PatientPhotoUpdate {
  blobId: number;
  patientId: number;
  patientPhoto: string;
}

export interface PatientInformationView {
  patientId: number;
  referralId: number;
  serviceRequestId: number;
  patientName: string;
  patientFirstName: string | null;
  patientLastName: string | null;
  languagePreferenceKind: string | null;
  languagePreferenceValue: string | null;
  isPriorAuthIntakeStageComplete: boolean;
  isReAuthIntakeStageComplete: boolean;
  isPrioAuthCompleted: boolean;
  isPriorAuthCreated: boolean;
  blobId: number;
  blobFileName: string;
  patientEligibilityId: number;
  intakeStageId: number;
  eligibilityPayerId: string;
  eligibilityPayerName: string;
  ahcccsId: string;
  authorizationTypeId: number;
  memberInfo: {
    dateOfBirth: string;
    gender: string | null;
    subscriberId: string | null;
    medicareBeneficiaryIdentifier: string | null;
    phoneNo: string | null;
    healthPlan: string | null;
    effectiveDate: string;
    effectiveThrough: string;
    dateOfIssuance: string;
    benefitPlan: string | null;
    product: string | null;
    insuranceState: string | null;
    languageId: number | null;
    languageName: string | null;
    extension: string | null;
    cellPhone: string | null;
    accommodationName: string | null;
    accommodationId: number | null;
    mobilePhone: string | null;
    alternatePhone: string | null;
    contractID_PBP: string | null;
  };
  addressInfo: {
    residentialAddress: string;
    mailingAddress: string;
    patientResidentialAddress: PatientResidentialAddress;
    patientMailingAddress: PatientMailingAddress;
  };
  primaryPhysician: {
    referralId: number;
    referralPhysicianDetailId: number;
    physicianId: number;
    physicianName: string;
    physicianNpiId: string;
    phoneExt: string | null;
    phoneNo: string;
    taxId: string | null;
    streetName1: string;
    streetName2: string | null;
    fax: string | null;
    county: string;
    city: string;
    physicianState: string;
    zipCode: string;
    physicianTypeId: number;
    physicianAddress: string | null;
    physicianUid: string;
    fullNameCreated: string;
  };
  emergencyContact: EmergencyContact[];
  isManual: boolean;
}

export interface AssignStaffIncompleteRequest {
  assignedUserFullName: string;
  assignedUserId: number;
  serviceRequestIds: string[];
  userUpdated: string;
}

export interface GetRemovePatientData {
  totalRows: 0;
  searchResponse: AllPatientDetails[];
}

export interface PutRemovePatientData {
  assignedUserId: number;
  createdBy: string;
  serviceRequestDisciplineIds: number[];
}

export type ServiceProviderOption = {
  providerName: string;
  providerNpi: number;
};

export type HealthPlanOption = {
  label: string;
  value: string | number;
};

export interface HealthPlan {
  label: string;
  value: string;
  healthPlanId: number;
  healthPlanName: string;
}

export interface BenefitPlan {
  label: string;
  value: string;
  healthPlanId: number;
  benefitPlanId: number;
  product: string;
  benefitPlanName: string;
  benefitPlanUid: string;
  healthPlanUid: string;
  healthPlanState: string;
  benefitPlanNm: string;
}

export interface NotifyInternalData {
  type: string;
  value: any;
}

export interface DefaultPatientSearchValues {
  firstName: string;
  lastName: string;
  authorizationNumber: string;
  healthPlan: string;
  dateOfBirth: any;
  subscriberId: string;
  contains: boolean;
  pageNo: number;
  pageSize: number;
}
