import { useState, useEffect, useRef, MutableRefObject } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { useParams } from "react-router-dom";
import {
  Divider,
  Grid,
  Typography,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Tooltip
} from "@mui/material";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  LofContainerStyle,
  LOFInputDevider,
  LofFirstTableCellStickyHeaderLeftSecond,
  LofTableMain,
  LofSecondTableCellStickyHeaderLeftSecond,
  LofTableMainHead,
  LofTableCellHeaderLeft,
  LofTableMainStyle,
  LofHeaderBoxStyle,
  LofTableCellWidth,
  LofLatestColumn,
  LofTableCellBorderBottom,
  LofScrollLableTableCellStyle,
  LofLatestTableCellWidth,
  LoffirstFixedTableCellBorder,
  LofTableCellBorder,
  FaRightArrowIcon,
  LofLofHeaderTitleStyle,
  LofRowColumnsStyleTooltip,
} from "../../../../../components/formComponents/styles/style";
import {
  LevelOfFunctionRecordsResponse,
  SaveDisciplineLevelNote,
  UpdateDisciplineLevelNote,
} from "../../../../../models/Api/Notes";
import { LofOuterBodyGrid } from "../styles/GeneralInfo";
import {
  Clofchoices,
  DressingData,
  AssistiveDeviceData,
  LofColumnKeys,
  LofCurrentLofChoices,
  OTLivingStatus,
  PTLivingStatus,
  PTAvailableAssist,
  OTAvilableAssist,
  OTClofChoices,
} from "../../../../../constants/Constants";
import {
  CombinedLofEntry,
  DisciplineName,
  LevelOfFunctionRecord,
  LofData,
  NoteEntryDateTime,
} from "../../../../../models/Notes";
import {
  generateColumns,
  LofRowData,
  getNoteEntryValue,
  isNil,
} from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { DateFormats } from "../../../../../constants/Date";
import { LevelOfFunctionMaxCharLength } from "../../../../../constants/Notes";

export interface PropsState {
  LevelOfFunctionRecordsData: LevelOfFunctionRecordsResponse;
  saveDisciplineLevelNotesDataResp: SaveDisciplineLevelNote;
  updateDisciplineLevelNoteDataresp: UpdateDisciplineLevelNote;
}

export interface PropsFromDispatch {
  getLevelOfFunctionRecords: (referralId: number) => void;
}

type AllProps = PropsState & PropsFromDispatch;

const LevelOfFunction: React.FC<AllProps> = ({
  LevelOfFunctionRecordsData,
  saveDisciplineLevelNotesDataResp,
  updateDisciplineLevelNoteDataresp,
  getLevelOfFunctionRecords,
}: AllProps) => {
  const [levelOfFunctionHeader, setLevelOfFunctionHeader] = useState<
    CombinedLofEntry[]
  >([]);
  const [newColumns, setNewColumns] = useState<LofData>(LofColumnKeys);
  const [secondColumnDocType, setSecondColumnDocType] = useState<number>(0);
  const [secondColumnOrderNo, setSecondColumnOrderNo] = useState<number>(0);
  const [indexForRecordWithTypeTwo, setIndexForRecordWithTypeTwo] =
    useState<number>(0);

  const containerRef = useRef<any>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(containerRef);
  const { response: LevelOfFunctionRsponse } = LevelOfFunctionRecordsData;
  const { authNo } = useParams();

  const columns = generateColumns(
    LevelOfFunctionRsponse ? LevelOfFunctionRsponse.length : 0
  );

  const rows = LofRowData(newColumns);

  const clofMap = DressingData.reduce((map: any, choice: any) => {
    map[choice.value] = choice.text;
    return map;
  }, {});

  useEffect(() => {
    if (
      !isNil(saveDisciplineLevelNotesDataResp) &&
      saveDisciplineLevelNotesDataResp.response.disciplineNoteId !== -1
    ) {
      getLevelOfFunctionRecords(Number(authNo));
    }
    if (
      !isNil(updateDisciplineLevelNoteDataresp) &&
      updateDisciplineLevelNoteDataresp.response.disciplineNoteId !== -1
    ) {
      getLevelOfFunctionRecords(Number(authNo));
    }
  }, [saveDisciplineLevelNotesDataResp, updateDisciplineLevelNoteDataresp]);

  const extractValues = (
    dataArray: LevelOfFunctionRecord[],
    keyPath: string,
    valueMap?: any[],
    identityKey?: string
  ) => {
    const values: any = [];

    const map = Array.isArray(valueMap)
      ? Object.fromEntries(
          valueMap.map(({ value, text }: any) => [value, text])
        )
      : {};

    dataArray.forEach((item: any) => {
      const noteEntry = JSON.parse(item.noteEntryJson);
      const data = getNoteEntryValue(noteEntry, keyPath);
      if (Array.isArray(data)) {
        const mappedData =
          data
            .map((value) => {
              if (typeof value === "object" && value.text) {
                return value.text;
              } else if (typeof value === "string") {
                return map[value] || value;
              } else {
                return value;
              }
            })
            .join(", ") || "-";
        values.push(mappedData);
      } else {
        if (data) {
          if (identityKey) {
            if (valueMap) {
              const value = data[identityKey];
              values.push(valueMap[value] || "-");
            }
          } else {
            values.push(data ? data : "-");
          }
        } else {
          values.push("-");
        }
      }
    });
    return values;
  };

  const updateState = (key: string, newValues: any) => {
    setNewColumns((prevData: any) => {
      const updatedValues = { ...prevData[key] };

      newValues.forEach((value: any, index: any) => {
        const columnKey = `column${index + 1}`;
        updatedValues[columnKey] = value;
      });
      return {
        ...prevData,
        [key]: updatedValues,
      };
    });
  };

  useEffect(() => {
    if (LevelOfFunctionRsponse) {
      const checkSecondSticyColumn =
        LevelOfFunctionRsponse && LevelOfFunctionRsponse[1];
      const firstRecordtWithTypeTwoIndex =
        LevelOfFunctionRsponse &&
        LevelOfFunctionRsponse.findIndex(
          (item) => item.documentationType === 2
        );
      setIndexForRecordWithTypeTwo(firstRecordtWithTypeTwoIndex);
      const documentationType =
        LevelOfFunctionRsponse.length > 1
          ? checkSecondSticyColumn.documentationType
          : 0;
      const orderNumber =
        LevelOfFunctionRsponse.length > 1
          ? checkSecondSticyColumn.orderNumber
          : 0;
      setSecondColumnDocType(documentationType);
      setSecondColumnOrderNo(orderNumber);
      const tempDisciplineNamesData: DisciplineName[] =
        LevelOfFunctionRsponse.map((item) => ({
          disciplineName: item.disciplineName,
        }));
      const tempNoteEntryDateTimeArray: NoteEntryDateTime[] =
        LevelOfFunctionRsponse.map((item) => ({
          dateCreated: formatDate(item.dateCreated, DateFormats.MM_DD_YYYY),
        }));
      const tempDocumentationType = LevelOfFunctionRsponse.map((item) => ({
        documentationType: item.documentationType,
      }));
      const temporderNumber = LevelOfFunctionRsponse.map((item) => ({
        orderNumber: item.orderNumber,
      }));
      const combinedArray: CombinedLofEntry[] = tempDisciplineNamesData.map(
        (item, index) => ({
          disciplineName: item.disciplineName,
          dateCreated:
            formatDate(
              tempNoteEntryDateTimeArray[index]?.dateCreated,
              DateFormats.MM_DD_YYYY
            ) || "",
          documentationType:
            tempDocumentationType[index]?.documentationType || 0,
          orderNumber: temporderNumber[index]?.orderNumber || 0,
        })
      );

      const clofMapData = Clofchoices.reduce((map: any, choice: any) => {
        map[choice.value] = choice.text;
        return map;
      }, {});

      const OTClofMapData = OTClofChoices.reduce((map: any, choice: any) => {
        map[choice.value] = choice.text;
        return map;
      }, {});

      const bedMobilityMap = LofCurrentLofChoices.reduce(
        (map: any, choice: any) => {
          if (typeof choice === "object" && choice.value && choice.text) {
            map[choice.value] = choice.text;
          } else if (typeof choice === "string") {
            map[choice] = choice;
          }
          return map;
        },
        {}
      );

      const PTLivingStatusData = PTLivingStatus.reduce(
        (map: any, choice: any) => {
          map[choice.value] = choice.text;
          return map;
        },
        {}
      );

      const OTLivingStatusData = OTLivingStatus.reduce(
        (map: any, choice: any) => {
          if (typeof choice === "object" && choice.value && choice.text) {
            map[choice.value] = choice.text;
          } else if (typeof choice === "string") {
            map[choice] = choice;
          }
          return map;
        },
        {}
      );

      const PTAvailableAssistData = PTAvailableAssist.reduce(
        (map: any, choice: any) => {
          map[choice.value] = choice.text;
          return map;
        },
        {}
      );

      const OTAvailableAssistData = OTAvilableAssist.reduce(
        (map: any, choice: any) => {
          if (typeof choice === "object" && choice.value && choice.text) {
            map[choice.value] = choice.text;
          } else if (typeof choice === "string") {
            map[choice] = choice;
          }
          return map;
        },
        {}
      );

      const CombinedData = { ...bedMobilityMap, ...clofMap };
      const CombinedLivingStatusData = {
        ...PTLivingStatusData,
        ...OTLivingStatusData,
      };
      const CombinedAvailableAssistData = {
        ...PTAvailableAssistData,
        ...OTAvailableAssistData,
      };

      const CombinedFunctionalMobility = { ...clofMapData, ...OTClofMapData };

      const functionalMobilityValues = extractValues(
        LevelOfFunctionRsponse,
        "PLOF.Functional Mobility",
        CombinedFunctionalMobility,
        "PLOF LOA"
      );

      const adlsValues = extractValues(
        LevelOfFunctionRsponse,
        "PLOF.ADLs",
        CombinedFunctionalMobility,
        "PLOF LOA"
      );
      const livingStatusValues = extractValues(
        LevelOfFunctionRsponse,
        "",
        CombinedLivingStatusData,
        "Living status"
      );
      const availableAssistance = extractValues(
        LevelOfFunctionRsponse,
        "",
        CombinedAvailableAssistData,
        "Available assistance"
      );
      const bedMobility = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Bed Mobility",
        bedMobilityMap,
        "CLOF"
      );
      const transferSitToStand = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Transfer:sittostand",
        bedMobilityMap,
        "CLOF"
      );
      const toiletingHygiene = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Toileting/Hygiene",
        CombinedData,
        "CLOF"
      );
      const transferToilet = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Transfer:toilet",
        CombinedData,
        "CLOF"
      );
      const transferTubshower = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Transfer:tub/shower",
        CombinedData,
        "CLOF"
      );
      const bathingShowering = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Bathing/Showering",
        CombinedData,
        "CLOF"
      );
      const lowerBodyDressing = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Lower Body Dressing",
        clofMap,
        "CLOF"
      );
      const upperBodyDressing = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Upper Body Dressing",
        clofMap,
        "CLOF"
      );
      const grooming = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Grooming",
        clofMap,
        "CLOF"
      );
      const ambulation = extractValues(
        LevelOfFunctionRsponse,
        "Inpt/Current LOF.Row 5",
        bedMobilityMap,
        "CLOF"
      );
      const ambulationDistanceInpatient = extractValues(
        LevelOfFunctionRsponse,
        "Ambulation.Inpt"
      );
      const ambulationDistanceCurrent = extractValues(
        LevelOfFunctionRsponse,
        "Ambulation.Current"
      );
      const UpperExtremities = extractValues(
        LevelOfFunctionRsponse,
        "Weight Bearing Status.UpperExtremities.LOA"
      );
      const lowerExtremities = extractValues(
        LevelOfFunctionRsponse,
        "Weight Bearing Status.LowerExtremities.LOA"
      );
      const assistiveDevices = extractValues(
        LevelOfFunctionRsponse,
        "Prior Assistive Devices",
        AssistiveDeviceData
      );
      const currentDevices = extractValues(
        LevelOfFunctionRsponse,
        "current Assistive Devices",
        AssistiveDeviceData
      );
      const recommendedDevices = extractValues(
        LevelOfFunctionRsponse,
        "recommended Assistive Devices",
        AssistiveDeviceData
      );
      const otherDevices = extractValues(
        LevelOfFunctionRsponse,
        "Other devices"
      );
      const HepEstablished = extractValues(
        LevelOfFunctionRsponse,
        "HEP established"
      );
      const HepPlanToEstablish = extractValues(
        LevelOfFunctionRsponse,
        "Plan to Establish HEP"
      );

      updateState("ADLS", adlsValues);
      updateState("livingStatus", livingStatusValues);
      updateState("AvailableAssistance", availableAssistance);
      updateState("FunctionalMobility", functionalMobilityValues);
      updateState("BedMobility", bedMobility);
      updateState("TransferSitToStand", transferSitToStand);
      updateState("ToiletingHygiene", toiletingHygiene);
      updateState("TransferToilet", transferToilet);
      updateState("TransferTubShower", transferTubshower);
      updateState("BathingShowering", bathingShowering);
      updateState("LowerBodyDressing", lowerBodyDressing);
      updateState("UpperBodyDressing", upperBodyDressing);
      updateState("Grooming", grooming);
      updateState("Ambulation", ambulation);
      updateState("Inpatient", ambulationDistanceInpatient);
      updateState("Current", ambulationDistanceCurrent);
      updateState("UpperExtremities", UpperExtremities);
      updateState("LowerExtremities", lowerExtremities);
      updateState("PriorDevices", assistiveDevices);
      updateState("CurrentDevices", currentDevices);
      updateState("RecommendedDevices", recommendedDevices);
      updateState("OtherDevices", otherDevices);
      updateState("Established", HepEstablished);
      updateState("PlanToEstablishHep", HepPlanToEstablish);
      setLevelOfFunctionHeader(combinedArray);
    }
  }, [LevelOfFunctionRsponse]);

  return (
    <>
      <Grid container>
        <Box sx={LofLofHeaderTitleStyle(indexForRecordWithTypeTwo)}>
          <Typography
            variant="h6"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
            pt={"2rem"}
            pl={"1.5rem"}
          >
            LEVEL OF FUNCTION
          </Typography>
          {LevelOfFunctionRsponse && LevelOfFunctionRsponse.length >= 3 && (
            <Divider
              orientation="vertical"
              variant="fullWidth"
              sx={LOFInputDevider}
              flexItem
            />
          )}
        </Box>
        {LevelOfFunctionRsponse && LevelOfFunctionRsponse.length >= 3 ? (
          <>
            <Grid item xs={0.2} pl={"0.5rem"} pt={"1.8rem"}>
              <FaRightArrowIcon />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle2"
                fontWeight={fontWeight.Weight[1]}
                color={colors.black[2]}
                pt={"2.16rem"}
                pl={"0.5rem"}
              >
                Please scroll for latest to oldest from here.
              </Typography>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
      <Grid container sx={LofOuterBodyGrid(columns.length)}>
        <Grid item xs={columns.length > 0 ? 12 : 4}>
          <TableContainer
            component={Paper}
            {...events}
            ref={containerRef}
            sx={LofContainerStyle}
          >
            <LofTableMain sx={LofTableMainStyle(LevelOfFunctionRsponse)}>
              <LofTableMainHead>
                <LofTableCellHeaderLeft>
                  <Box sx={LofHeaderBoxStyle}>
                    <Typography
                      variant="subtitle2"
                      color={others.otherColors[63]}
                      fontWeight={fontWeight.Weight[5]}
                      pb={"1rem"}
                    >
                      NOTE TYPE:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={others.otherColors[63]}
                      fontWeight={fontWeight.Weight[5]}
                      pb={"1rem"}
                    >
                      DATE
                    </Typography>
                  </Box>
                </LofTableCellHeaderLeft>
                {levelOfFunctionHeader.map((label: any, index: any) =>
                  label.documentationType === 1 ? (
                    label.orderNumber === 1 ? (
                      <LofFirstTableCellStickyHeaderLeftSecond
                        key={index}
                        sx={LoffirstFixedTableCellBorder(
                          indexForRecordWithTypeTwo
                        )}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"2.5rem"}
                            pb={"1rem"}
                          >
                            {label.disciplineName}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"1.3rem"}
                            pb={"0.7rem"}
                          >
                            {label.dateCreated}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[62]}
                            fontWeight={fontWeight.Weight[4]}
                          >
                            (First documented)
                          </Typography>
                        </Box>
                      </LofFirstTableCellStickyHeaderLeftSecond>
                    ) : (
                      <LofSecondTableCellStickyHeaderLeftSecond key={index}>
                        <Box sx={LofTableCellWidth}>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"2.5rem"}
                            pb={"1rem"}
                          >
                            {label.disciplineName}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[63]}
                            fontWeight={fontWeight.Weight[5]}
                            pl={"1.3rem"}
                          >
                            {label.dateCreated}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color={others.otherColors[62]}
                            fontWeight={fontWeight.Weight[4]}
                            pt={"0.7rem"}
                          >
                            (First documented)
                          </Typography>
                        </Box>
                      </LofSecondTableCellStickyHeaderLeftSecond>
                    )
                  ) : (
                    <TableCell
                      key={index}
                      sx={LofLatestColumn(indexForRecordWithTypeTwo, index)}
                    >
                      <Box sx={LofLatestTableCellWidth}>
                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[63]}
                          fontWeight={fontWeight.Weight[5]}
                          pb={"1rem"}
                          pl={"4rem"}
                        >
                          {label.disciplineName}
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[63]}
                          fontWeight={fontWeight.Weight[5]}
                          pl={"2.3rem"}
                        >
                          {label.dateCreated}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[62]}
                          fontWeight={fontWeight.Weight[4]}
                          pt={"0.75rem"}
                          pl={"3.3rem"}
                        >
                          {indexForRecordWithTypeTwo === index ? "Latest" : ""}
                        </Typography>
                      </Box>
                    </TableCell>
                  )
                )}
              </LofTableMainHead>
              <TableBody>
                {rows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <LofTableCellHeaderLeft
                      sx={LofTableCellBorderBottom(row.key)}
                    >
                      <Box sx={LofHeaderBoxStyle}>
                        <Grid container>
                          <Grid item xs={12}>
                            {row.key === ("Functional Mobility" || "ADLs") && (
                              <Typography
                                variant="subtitle1"
                                color={others.otherColors[62]}
                                fontWeight={fontWeight.Weight[5]}
                                pb={"1rem"}
                              >
                                PLOF
                              </Typography>
                            )}
                            {row.key === "Living Status" && (
                              <Typography
                                variant="subtitle1"
                                color={others.otherColors[62]}
                                fontWeight={fontWeight.Weight[5]}
                                pb={"1rem"}
                              >
                                CLOF
                              </Typography>
                            )}
                            {row.key === ("Inpatient" || "Current") && (
                              <Typography
                                variant="subtitle1"
                                color={others.otherColors[62]}
                                fontWeight={fontWeight.Weight[6]}
                                pb={"1rem"}
                              >
                                Ambulation Distance
                              </Typography>
                            )}
                            {row.key ===
                              ("Upper Extremities" || "Lower Extremities") && (
                              <Typography
                                variant="subtitle1"
                                color={others.otherColors[62]}
                                fontWeight={fontWeight.Weight[5]}
                                pb={"1rem"}
                              >
                                Weight bearing status
                              </Typography>
                            )}
                            {row.key ===
                              ("Prior Devices" ||
                                "Current tDevices" ||
                                "Recommended Devices" ||
                                "Other Assistive Devices") && (
                              <Typography
                                variant="subtitle1"
                                color={others.otherColors[62]}
                                fontWeight={fontWeight.Weight[6]}
                                pb={"1rem"}
                              >
                                Assistive devices
                              </Typography>
                            )}
                            {row.key ===
                              ("Established" || "Plan to Establish HEP") && (
                              <Typography
                                variant="subtitle1"
                                color={others.otherColors[62]}
                                fontWeight={fontWeight.Weight[6]}
                                pb={"1rem"}
                              >
                                HEP
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} pl={"2.8rem"}>
                            <Typography
                              variant="subtitle2"
                              color={colors.black[2]}
                              fontWeight={fontWeight.Weight[3]}
                            >
                              {row.key}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </LofTableCellHeaderLeft>
                    {columns.map((column) => {
                      if (column.id === "column1") {
                        return (
                          <LofFirstTableCellStickyHeaderLeftSecond
                            key={column.id}
                            sx={LofTableCellBorder(
                              row.key,
                              indexForRecordWithTypeTwo
                            )}
                          >
                            <Box sx={LofTableCellWidth}>
                              {row[column.id as keyof typeof row] &&
                              row[column.id as keyof typeof row].length >
                                LevelOfFunctionMaxCharLength ? (
                                <Tooltip
                                  title={
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight={fontWeight.Weight[4]}
                                      lineHeight="1rem"
                                    >
                                      {row[column.id as keyof typeof row]}
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <Typography
                                    variant="subtitle2"
                                    color={others.otherColors[63]}
                                    fontWeight={fontWeight.Weight[5]}
                                    sx={LofRowColumnsStyleTooltip(row.key)}
                                  >
                                    {row[column.id as keyof typeof row]}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  color={others.otherColors[63]}
                                  fontWeight={fontWeight.Weight[5]}
                                  sx={LofRowColumnsStyleTooltip(row.key)}
                                >
                                  {row[column.id as keyof typeof row]}
                                </Typography>
                              )}
                            </Box>
                          </LofFirstTableCellStickyHeaderLeftSecond>
                        );
                      }
                      if (
                        column.id === "column2" &&
                        secondColumnDocType === 1 &&
                        secondColumnOrderNo === 2
                      ) {
                        return (
                          <LofSecondTableCellStickyHeaderLeftSecond
                            key={column.id}
                            sx={LofTableCellBorderBottom(row.key)}
                          >
                            <Box sx={LofTableCellWidth}>
                              {row[column.id as keyof typeof row] &&
                              row[column.id as keyof typeof row].length >
                                LevelOfFunctionMaxCharLength ? (
                                <Tooltip
                                  title={
                                    <Typography
                                      variant="subtitle1"
                                      fontWeight={fontWeight.Weight[4]}
                                      lineHeight="1rem"
                                    >
                                      {row[column.id as keyof typeof row]}
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <Typography
                                    variant="subtitle2"
                                    color={others.otherColors[63]}
                                    fontWeight={fontWeight.Weight[5]}
                                    sx={LofRowColumnsStyleTooltip(row.key)}
                                  >
                                    {row[column.id as keyof typeof row]}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  color={others.otherColors[63]}
                                  fontWeight={fontWeight.Weight[5]}
                                  sx={LofRowColumnsStyleTooltip(row.key)}
                                >
                                  {row[column.id as keyof typeof row]}
                                </Typography>
                              )}
                            </Box>
                          </LofSecondTableCellStickyHeaderLeftSecond>
                        );
                      } else {
                        return (
                          <TableCell
                            key={column.id}
                            sx={LofScrollLableTableCellStyle(
                              row.key,
                              column.id,
                              indexForRecordWithTypeTwo
                            )}
                          >
                            {row[column.id as keyof typeof row] &&
                            row[column.id as keyof typeof row].length >
                              LevelOfFunctionMaxCharLength ? (
                              <Tooltip
                                title={
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={fontWeight.Weight[4]}
                                    pl={"2.2rem"}
                                    lineHeight="1rem"
                                  >
                                    {row[column.id as keyof typeof row]}
                                  </Typography>
                                }
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  color={others.otherColors[63]}
                                  fontWeight={fontWeight.Weight[5]}
                                  sx={LofRowColumnsStyleTooltip(row.key)}
                                  pl={"2.2rem"}
                                >
                                  {row[column.id as keyof typeof row]}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography
                                variant="subtitle2"
                                color={others.otherColors[63]}
                                fontWeight={fontWeight.Weight[5]}
                                sx={LofRowColumnsStyleTooltip(row.key)}
                                pl={"2.2rem"}
                              >
                                {row[column.id as keyof typeof row]}
                              </Typography>
                            )}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </LofTableMain>
          </TableContainer>
        </Grid>
        {columns.length === 0 && (
          <Grid item xs={8}>
            <Typography
              variant="h6"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[7]}
              pt={"25rem"}
              pl={"20rem"}
            >
              No Data Found.
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LevelOfFunction;
