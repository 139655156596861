import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { DocumentReviewDispatchTypes } from "../../../../../models/DispatchTypes/Document";
import DocumentsReview from "../components/DocumentReview";
import {
  getDocumentSource,
} from "../../../../../actions/Master";
import {
  deleteDocumentFile,
  deleteReauthDocumentFile,
  documentDownload,
  getReferralDocuments,
  postGenerateAuthTemplate,
  saveDocumentFile,
  updateDocumentFile,
} from "../../../../../actions/AllPatientRecord";
import { UserDetailsPayload } from "../../../../../models/User";
import { resetStates } from "../../../../../actions/Common";
import { SaveInitiateFax } from "../../../../../actions/AuthorizationDetails";

const mapStateToProps = ({
  documentCreatedState,
  documentDownloadState,
  documentSourceState,
  loggedInUserState,
  saveDocumentFileState,
  faxProviderMasterState,
  generateAuthTemplateState,
  postFaxSendingState,
}: ApplicationState) => ({
  documentCreatedBy: documentCreatedState,
  documentDownloadData: documentDownloadState,
  documentSource: documentSourceState,
  saveDocumentData: saveDocumentFileState,
  user: loggedInUserState.response as UserDetailsPayload,
  faxProviderMasterData: faxProviderMasterState,
  generateAuthTemplate: generateAuthTemplateState.response,
  faxSendResponse: postFaxSendingState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<DocumentReviewDispatchTypes>
  >
) => {
  return {
    getDocumentSource: () => {
      dispatch(getDocumentSource());
    },
    updateDocumentFile: async (payload: any, referralId: string) => {
      dispatch(await updateDocumentFile(payload, referralId));
    },
    saveDocumentFile: async (payload: any) => {
      dispatch(await saveDocumentFile(payload));
    },
    deleteDocumentFile: (blobId: any, userDeleted: string) => {
      dispatch(deleteDocumentFile(blobId, userDeleted));
    },
    deleteReauthDocumentFile: (blobId: any, userDeleted: string) => {
      dispatch(deleteReauthDocumentFile(blobId, userDeleted));
    },
    documentDownload: (blobId: number) => {
      dispatch(documentDownload(blobId));
    },
    getReferralDocuments: (referralId: number, type: number) => {
      dispatch(getReferralDocuments(referralId, type));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    saveInitiateFax: (payload: FormData) => {
      dispatch(SaveInitiateFax(payload));
    },
    postGenerateAuthTemplate: (referralId: string) => {
      dispatch(postGenerateAuthTemplate(referralId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsReview);
