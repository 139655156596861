import { styled } from "@mui/system";
import { FaChevronCircleUp, FaChevronCircleDown } from "react-icons/fa";

import { accent, colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { RequesterComponentInjectType } from "../../../../../constants/Constants";

export const RequestContainer = () => ({
  padding: "0 0 1rem 1rem",
});

export const RequestHeader = () => ({
  margin: "1rem 0 1.5rem 2rem",
});

export const VerticalDevider = () => ({
  margin: "0 1rem",
  height: "1.5rem",
});

export const HowToCheckBox = () => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "2rem",
  svg: {
    marginRight: "0.5rem",
  },
});

export const GridContainer = () => ({
  marginTop: "0rem",
  padding: "0.5rem 2rem",
  '[class*= "MuiGrid-item"]': {
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
});

export const RequestDetailsContainer = () => ({
  marginTop: "0rem",
  padding: "0 0 0 2rem",
});

export const HorizontalDivider = () => ({
  marginLeft: "1.8rem",
});

export const RequestDetailsItems = () => ({
  paddingBottom: "1rem",
  "&.MuiGrid-item": {
    paddingTop: "2rem",
  },
});

export const DateFormControl = () => ({
  marginRight: "2rem",
});

export const RefManagementLabel = () => ({
  padding: "1rem 0 1rem 0.5rem",
  marginBottom: "1rem",
  marginTop: "0.5rem",
  display: "flex",
});

export const RadioGroupSecondRoot = () => ({
  backgroundColor: others.otherColors[44],
  padding: ".25rem 0 .25rem 2rem",
  borderRadius: "5px",
});

export const ReceivedMethodBox = () => ({
  alignItems: "center",
  marginBottom: "1.5rem",
});

export const RequestDetailGrid = () => ({
  display: "flex",
  padding: "0.5rem 2rem",
  marginTop: "1rem",
  marginBottom: "1.5rem",
});

export const FaChevronCircleUpIcon = styled(FaChevronCircleUp)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const FaChevronCircleDownIcon = styled(FaChevronCircleDown)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const AddressDetailHeader = () => ({
  display: "flex",
  marginLeft: "1rem",
});

export const AddressHeaderBox = () => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "2rem",
  marginBottom: "1rem",
});

export const AddressHeaderBoxForReauth = () => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "2rem",
  marginBottom: "1rem",
  opacity: "0.5",
});

export const AddressBox = () => ({
  marginLeft: "1.5rem",
  marginTop: "2rem",
});

export const AddressRadio = () => ({
  display: "flex",
  flexDirection: "row",
  marginLeft: "1rem",
  marginTop: "0.2rem",
  marginBottom: "2rem",
  padding: "0.3rem 1rem",
});

export const BoxRadio = () => ({
  background: colors.backgroundColor[7],
  borderRadius: "0.5rem",
});

export const MemberAddressBox = () => ({
  background: others.SchedulerColors[1],
  padding: "0.7rem",
  marginTop: "1.5rem",
  margin: "0 1rem 0",
  borderRadius: "0.5rem",
});

export const AddAddressGrid = () => ({
  marginTop: "2rem",
  marginLeft: "0.5rem",
});

export const AddAddressBox = () => ({
  marginTop: "1.5rem",
});

export const AttorneyDivider = () => ({
  marginLeft: "2rem",
  marginRight: "1rem",
  height: "1.5rem",
});

export const AttorneyGrid = () => ({
  display: "flex",
  alignItems: "end",
});

export const HealthPlanControl = () => ({
  marginRight: "2rem",
});

export const ReceivedMethodRadio = () => ({
  marginTop: "1.5rem",
  marginRight: "0rem",
});

export const EmergencyContactTableStyle = () => ({
  paddingTop: "0.3rem",
  paddingBottom: "2rem",
  overflowX: "unset",
  tbody: {
    '[class*="MuiTableCell-root"]': {
      borderBottom: "none",
    },
  },
});

export const AttorneyBox = () => ({
  marginTop: "0.2rem",
  marginLeft: "0.7rem",
});

export const RequestHeaderBox = (type: string) => ({
  display: "flex",
  alignItems: "flex-start",
  marginLeft:
    type === RequesterComponentInjectType.AUTH_DETAILS ? "1.6rem" : "2rem",
  marginBottom: "0.5rem",
  marginTop: "1rem",
});

export const RefSourceRadio = (flag?: boolean) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "1rem",
  pointerEvents: flag ? "none" : "initial",
});

export const BoxStyle = () => ({
  paddingRight: "2rem",
  "&:last-child": {
    paddingRight: "0rem",
  },
});

export const RequestorBox = () => ({
  marginLeft: "5rem",
  marginTop: "1.2rem",
});

export const AddressLine = () => ({
  marginTop: "1.5rem",
  marginRight: "3rem",
});

export const RequestorGrid = (flag?: boolean | null) => ({
  padding: "0 4rem 0 0",
  pointerEvents: flag ? "none" : "initial",
});

export const phoneNoInputStyle = (injectedFrom: string) => ({
  maxWidth:
    RequesterComponentInjectType.PRIOR_AUTH === injectedFrom
      ? "16rem"
      : "14.2rem",
});

export const RequestorFaxGrid = (flag?: boolean | null) => ({
  pointerEvents: flag ? "none" : "initial",
});

export const ReferralHeader = () => ({
  display: "flex",
  margin: "2rem 0 2rem",
});

export const FacilityAddress = () => ({
  paddingTop: "1rem ",
});

export const OrderBox = () => ({
  margin: "3rem 4.2rem 1.5rem",
});

export const SelectBox = () => ({
  display: "flex",
  alignItems: "center",
  svg: {
    marginTop: "1rem",
    marginRight: "1.5rem",
    "&:first-of-type": {
      marginRight: "0.3rem",
    },
  },
});

export const OrderGrid = () => ({
  display: "flex",
  alignItems: "start",
  marginTop: "1rem",
});

export const OrderLine = () => ({
  margin: "1rem -1rem 2.5rem",
});

export const OrderRadio = () => ({
  display: "flex",
  flexDirection: "row",
  marginLeft: "0.5rem",
  marginTop: "1rem",
});

export const NonCoveredCheckbox = () => ({
  marginLeft: "0.2rem",
  display: "flex",
  flexDirection: "row",
});

export const CustDialRadio = () => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "1rem",
});

export const AddButton = () => ({
  marginLeft: "auto",
});

export const deleteIcon = {
  cursor: "pointer",
  color: accent.accentOrange[100],
  marginTop: "1.4rem",
};

export const numberTextField = {
  "& .MuiInput-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
    },
  },
};

export const NameWidth = () => ({
  minWidth: "14rem",
  width: "14rem",
  paddingLeft: 0,
  paddingRight: "1.5rem",
});

export const RelationshipWidth = () => ({
  minWidth: "12rem",
  width: "12rem",
  paddingLeft: 0,
  paddingRight: "1rem",
  paddingBottom: "0.5rem",
});

export const ContactDetails = () => ({
  paddingLeft: 0,
  "& .MuiFormHelperText-root ": {
    position: "absolute",
    top: "2.75rem",
  },
});
export const RelationshipDetails = () => ({
  paddingLeft: 0,
  "& .MuiFormHelperText-root ": {
    position: "absolute",
    top: "2.6rem",
  },
});

export const ChooseInputLabel = () => ({
  paddingTop: "0.7rem",
  color: colors.fonts[2],
  fontWeight: fontWeight.Weight[3],
  opacity: 0.55,
  fontFamily: "DM Sans",
});

export const RequiredSymbol = () => ({
  marginLeft: "0.5rem",
});

export const ErrorMessage = () => ({
  color: colors.red[100],
  position: "absolute",
  bottom: "-1.2rem",
});

export const AuthRequestorBox = () => ({
  marginLeft: "2.5rem",
  marginTop: "1.2rem",
});

export const emergencyContactErrorMsg = () => ({
  "& .MuiInputLabel-root": {
    display: "flex",
  },
  "& .MuiFormHelperText-root ": {
    position: "absolute",
    bottom: "-1.2rem",
  },
});
