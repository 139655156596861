import { Reducer } from "redux";

import { FaxRecipientMasterResponse } from "../models/Api/Master";
import { FaxreceipentDispatchTypes } from "../models/DispatchTypes/Master";
import { FaxRecipientMasterActionDispatchTypes } from "../constants/Master";

export const initialState: FaxRecipientMasterResponse = {
  loading: false,
  response: [
    {
      faxReceiverId: -1,
      faxReceiver: "",
    },
  ],
};

const MasterFaxProviderReducer: Reducer<
  FaxRecipientMasterResponse,
  FaxreceipentDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case FaxRecipientMasterActionDispatchTypes.FAX_RECIPIENT_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case FaxRecipientMasterActionDispatchTypes.FAX_RECIPIENT_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default MasterFaxProviderReducer;
