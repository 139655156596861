import {
  Box,
  Grid,
  Tab,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { alpha, styled } from "@mui/system";

import { colors, others } from "../../../styles/colors";
import fontWeight from "../../../styles/mui/fontWeight";

export const HeaderBox = styled(Grid)(() => ({
  padding: "1.5rem",
}));

export const HeaderModuleName = () => ({
  textTransform: "uppercase",
  color: others.DashboardBreadCumb[1],
  marginBottom: "0.5rem",
});

export const TabsPanelSectionBox = styled(Box)((props) => ({
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  backgroundColor: props.theme.palette.common.white,
  borderRadius: "10px",
  marginBottom: "1rem",
}));

export const PanelBox = styled(Box)(() => ({
  padding: "1.5rem 1.5rem 0 1.5rem",
}));

export const RefTabs = styled(Tabs)((props) => ({
  overflow: "visible",
  "& .MuiTabs-scroller": {
    overflow: "visible",
  },
  marginLeft: "6rem",
  "& .MuiTabs-indicator": {
    backgroundColor: "unset",
  },
  "& .Mui-selected": {
    border: `1px solid ${colors.borders[1]}`,
    color: colors.fonts[18],
    fontWeight: fontWeight.Weight[6],
    opacity: 1,
    textTransform: "uppercase",
    borderBottom: `1px solid ${props.theme.palette.common.white}`,
  },
  "& .MuiTabs-flexContainer": {
    paddingTop: "20px",
  },
}));

export const RefTab = styled(Tab)((props) => ({
  backgroundColor: props.theme.palette.common.white,
  marginRight: "1rem",
  borderRadius: "10px",
  borderBottomRightRadius: "0px",
  borderBottomLeftRadius: "0px",
  border: `1px solid ${colors.borders[1]}`,
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
  textTransform: "uppercase",
  overflow: "visible",
  color: colors.fonts[19],
  padding: "0.7rem 0.5rem 0.5rem 0.5rem",
  minHeight: "2.45rem",
  fontSize: ".75rem",
  opacity: 0.9,
  width: "10.8rem",
}));

export const SelectControl = () => ({
  padding: "0.6rem",
  width: "100%",
});

export const CenterTypography = {
  alignItems: "center",
  padding: "2.3rem 1.5rem 1.5rem",
};

export const PatientDetailBox = styled(Box)(() => ({
  backgroundColor: colors.backgroundColor[7],
  borderRadius: "0.7rem",
  margin: "1rem .25rem 0",
  padding: "0.5rem 0.6rem 1rem 0.4rem",
}));

export const PatientContainerBox = styled(Box)(() => ({
  backgroundColor: colors.backgroundColor[2],
  borderRadius: "1rem",
  marginBottom: "1rem",
  paddingBottom: "1rem",
  minHeight: "80vh",
}));

export const PatientBox = styled(Box)(() => ({
  padding: "1.5rem 1.5rem 0 1.5rem",
}));

export const IconFaSearch = styled(FaSearch)(() => ({
  display: "flex",
  color: alpha(colors.fonts[4], 0.32),
  justifyContent: "left",
}));

export const PatientHeaderBox = styled(Grid)(() => ({
  display: "flex",
  padding: "1.25rem",
  alignItems: "center",
}));

export const ErrorMessage = {
  display: "flex",
  alignItems: "center",
};

export const SelectedOption = (currentTab?: number) => ({
  background: colors.black[7],
  borderRadius: "1rem",
  padding: "0.2rem 0.3rem 0.2rem 0.6rem",
  margin: "0.3rem 0.2rem 0.4rem 0",
  display: "flex",
  textTransform: "uppercase",
  maxWidth: currentTab == 3 ? "25rem" : "45rem",
  wordBreak: "break-all",
  width: "fit-content",
});

export const Header = () => ({
  alignItems: "center",
  display: "flex",
});

export const Heading = () => ({
  paddingLeft: "1.25rem",
  display: "flex",
});

export const SearchedText = (activeTab: number) => ({
  display: "flex",
  maxWidth: activeTab == 3 ? "25rem" : "45rem",
  paddingLeft: "0.4rem",
});

export const ClearButtonGrid = () => ({
  display: "flex",
  justifyContent: "flex-end",
});

export const ClearButton = () => ({
  width: "6rem",
  height: "1.9rem",
  display: "flex",
});

export const DividerLine = () => ({
  margin: "0 0rem 0 1.5rem",
});

export const PatientTableHeadCell = () => ({
  backgroundColor: alpha(colors.black[7], 0.35),
  padding: "1.35rem 0 1.35rem 0",
  opacity: 1,
  border: 0,
  "&.MuiTableCell-root:last-child": {
    borderRadius: "0 4px 4px 0",
    paddingRight: "2%",
  },
  "&.MuiTableCell-root:first-of-type": {
    borderRadius: "4px 0 0 4px",
    paddingLeft: "2.2rem",
    textAlign: "left",
  },
});

export const AlignCenter = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

export const NameContainer = () => ({
  alignItems: "center",
  height: "100%",
});

export const Flex = () => ({
  display: "flex",
  backgroundColor: alpha(colors.black[7], 0.35),
});

export const TableNameDetails = (rowIndex: number, totalRow: number) => ({
  textDecoration: "underline",
  textDecorationColor: colors.fonts[3],
  borderBottom:
    rowIndex === totalRow - 1
      ? `1px solid ${others.DashboardReferralProgress[1]}`
      : 0,
  borderRight: 0,
  borderTop:
    rowIndex === 0 ? `1px solid ${others.DashboardReferralProgress[1]}` : 0,
  padding: 0,
  textAlign: "left",
  paddingLeft: "2rem",
});

export const PatientTableCell = (rowIndex: number, totalRow: number) => ({
  position: "sticky",
  borderRight: 0,
  borderBottom:
    rowIndex === totalRow - 1
      ? `1px solid ${others.DashboardReferralProgress[1]}`
      : 0,
  borderLeft: 0,
  borderTop:
    rowIndex === 0 ? `1px solid ${others.DashboardReferralProgress[1]}` : 0,
  padding: 0,
  textAlign: "left",
  paddingRight: "2%",
  paddingLeft: "0rem",
});

export const StyledTableHead = styled(TableHead)(() => ({
  background: colors.black[7],
}));

export const Headcell40 = {
  minWidth: "1.5rem",
  width: "1.5rem",
};

export const Headcell60 = {
  minWidth: "2.4rem",
  width: "2.4rem",
};

export const Headcell80 = {
  minWidth: "4.1rem",
  width: "4.1rem",
};

export const Headcell100 = {
  minWidth: "4.87rem",
  width: "4.87rem",
};

export const Headcell130 = {
  minWidth: "5.6rem",
  width: "5.6rem",
};

export const Headcell140 = {
  minWidth: "6rem",
  width: "6rem",
};

export const Headcell150 = {
  minWidth: "6.8rem",
  width: "6.8rem",
};

export const Headcell160 = {
  minWidth: "7.2rem",
  width: "7.2rem",
};

export const Headcell170 = {
  minWidth: "7.9rem",
  width: "7.9rem",
};

export const Headcell200 = {
  minWidth: "9.5rem",
  width: "9.5rem",
};

export const Headcell250 = {
  minWidth: "10.2rem",
  width: "10.2rem",
};

export const Headcell310 = {
  minWidth: "15.09rem",
  width: "15.09rem",
};

export const TableMain = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    borderBottom: `1px solid ${others.otherColors[41]}`,
    borderRight: "none",
    borderLeft: "none",
    paddingLeft: "1rem",
  },
  "& th:first-of-type": {
    borderRadius: "0.4875rem 0 0 0.4875rem",
    borderRight: "1rem",
  },
  "& th:last-child": {
    borderRadius: "0 0.4875rem 0.4875rem 0",
  },
}));

export const TableMainHead = styled(TableHead)(() => ({
  backgroundColor: others.SchedulerColors[1],
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
}));

export const TableMainHeadRow = styled(TableRow)(() => ({
  height: "3rem",
}));

export const HeaderItem = {
  backgroundColor: colors.black[7],
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.42rem",
};

export const TableCellStickyHeader = styled(TableCell)(() => ({
  "&.MuiTableCell-root:last-child": {
    borderLeft: `1px solid ${alpha(others.otherColors[58], 0.35)}`,
    backgroundColor: others.SchedulerColors[1],
    position: "sticky",
    right: 0,
  },
}));

export const TableCellStyle = styled(TableCell)(() => ({
  "&.MuiTableCell-root": {
    padding: "0 0 0 1rem",
  },
}));

export const TableCellStickyHeaderLeft = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  backgroundColor: others.SchedulerColors[1],
}));

export const TableCellSticky = styled(TableCell)(() => ({
  position: "sticky",
  right: 0,
  background: others.otherColors[33],
}));

export const TableCellStickyLeft = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  background: others.otherColors[33],
  zIndex: "1",
}));

export const TableGrid = {
  marginRight: 1,
  padding: "0.72rem",
  boxShadow: `1px 1px 8px ${colors.dropShadow[4]}`,
};

export const FullNameBoxStyle = {
  borderRight: `1px solid ${others.otherColors[41]}`,
  width: "auto",
  display: "flex",
  alignItems: "center",
};

export const HederBoxStyle = {
  borderRight: `1px solid ${alpha(others.otherColors[58], 0.35)}`,
  width: "auto",
  display: "flex",
  alignItems: "center",
};

export const ActiveTabStyle = {
  padding: "2.25rem 0.7rem 1.25rem 1rem",
  display: "flex",
};

export const PaginationStyle = {
  padding: "0.3rem",
};

export const InputCloseIcon = {
  cursor: "pointer",
};

export const ButtonBox = () => ({
  paddingTop: "0.3rem",
});

export const RefTabsBox = () => ({
  marginLeft: "2rem",
});

export const ProviderListItem = () => ({
  color: colors.fonts[25],
  fontSize: "0.85rem",
  fontWeight: fontWeight.Weight[3],
});

export const AssignStaffButton = () => ({
  width: "10rem",
});
