import * as React from "react";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import { Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { isEqual, isNull } from "lodash";

import MemberInfo from "./MemberInfo";
import AddressInfo from "./AddressInfo";
import AlternateContact from "./AlternateContact";
import {
  PatientHeaderDefaultValues,
  PatientHeaderLabel,
} from "../../../../../../constants/Patient";
import {
  PanelBox,
  PatientHeaderTab,
  PatientHeaderTabs,
  TabsPanelSectionBox,
} from "../style";
import PhysicianInfo from "../containers/PhysicianInfo";
import InfoDialog from "../../../../../../components/custom/infoDialogue/components/InfoDialog";
import {
  EmergencyContactOption,
  MandatoryFieldAlertMessage,
} from "../../../../../../constants/AllPatientRecord";
import {
  NotifyInternalDataResponse,
  PatientInformationViewResponse,
} from "../../../../../../models/Api/PatientInformation";
import {
  getValue,
  getList,
  a11yProps,
  isEmptyValue,
  getFormattedPhoneNo,
} from "../../../../../../utils";
import {
  PhysicianTypeID,
  primaryPhysicianFormNames,
} from "../../../../../../constants/PhysicianDetails";
import {
  AccomodationMasterResponse,
  LanguageMasterResponse,
} from "../../../../../../models/Api/Master";
import Permission from "../../../../../Permission";
import { PatientRecordsAccess } from "../../../../../../constants/Permission";
import { PatientInformationView } from "../../../../../../models/Patient";
import {
  EmergencyContactTable,
  RelationshipMaster,
} from "../../../../../../models/Patient";
import { RelationshipMasterResponse } from "../../../../../../models/Api/Patient";
import { NotifyInternalDataType } from "../../../../../../constants/Constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabsPanelSectionBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <PanelBox>
          <Box>{children}</Box>
        </PanelBox>
      )}
    </TabsPanelSectionBox>
  );
}

export interface PropsFromState {
  patientData: PatientInformationViewResponse;
  LanguageMasterData: LanguageMasterResponse;
  AccomodationMasterData: AccomodationMasterResponse;
  NotifyInternalDataValuesResp: NotifyInternalDataResponse;
}

export interface PropsFromDispatch {
  getLanguageMasterResp: () => void;
  getAccomodationMasterResp: () => void;
  updatePatientHeader: (data: PatientInformationView) => void;
  relationshipData: RelationshipMasterResponse;
}

export interface PropsFromDispatch {
  getRelationshipMasterResp: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch & PropsFromDispatch;

export const SaveButton = (handleSave: any) => {
  return (
    <Button onClick={handleSave} variant="contained">
      SAVE
    </Button>
  );
};

const PatientHeader: React.FC<AllProps> = ({
  patientData,
  relationshipData,
  LanguageMasterData,
  AccomodationMasterData,
  getLanguageMasterResp,
  getAccomodationMasterResp,
  updatePatientHeader,
  getRelationshipMasterResp,
  NotifyInternalDataValuesResp,
}: AllProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [relationshipList, setRelationshipList] = useState<
    RelationshipMaster[] | any[]
  >([]);
  const [emergencyContactRelationid, setEmergencyContactRelationid] = useState<
    EmergencyContactTable[] | any[]
  >([]);

  const { loading: loadingRelationship, response: relationshipResponse } =
    relationshipData;

  const { response } = patientData;
  const { loading: LoadingLanguage, response: LanguageResponse } =
    LanguageMasterData;
  const { loading: LoadingAccomodation, response: AccomodationResponse } =
    AccomodationMasterData;

  const { response: InternalDataValuesResponse } = NotifyInternalDataValuesResp;

  useEffect(() => {
    if (!LoadingLanguage) {
      getLanguageMasterResp();
    }
    if (!LoadingAccomodation) {
      getAccomodationMasterResp();
    }
  }, []);

  const methods = useForm<any>({
    defaultValues: PatientHeaderDefaultValues,
    mode: "all",
  });
  const {
    control,
    reset,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    clearErrors,
  } = methods;

  const updatePayload = useWatch({
    name: "primaryPhysician",
    control,
  });

  const fieldNames = [
    "primaryPhysician.physicianName",
    "primaryPhysician.physicianNpiId",
    "primaryPhysician.phoneNo",
    "primaryPhysician.streetName1",
    "primaryPhysician.county",
    "primaryPhysician.zipCode",
    "primaryPhysician.taxId",
    "primaryPhysician.fax",
    "primaryPhysician.streetName2",
    "primaryPhysician.physicianState",
    "primaryPhysician.city",
  ];

  const values = getValues();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emergencyContact",
  });

  const emergencyContactValues = useWatch({
    name: "emergencyContact",
    control,
  });

  useEffect(() => {
    if (!loadingRelationship) {
      getRelationshipMasterResp();
    }
  }, []);

  useEffect(() => {
    setRelationshipList(relationshipResponse || []);
  }, [relationshipResponse]);

  useEffect(() => {
    if (getValue(response, "patientId", 0) != 0) {
      reset({
        ...response,
        memberInfo: {
          ...getValue(response, "memberInfo"),
          alternatePhone: getFormattedPhoneNo(
            getValue(response, "memberInfo.alternatePhone")
          ),
          cellPhone: getFormattedPhoneNo(
            getValue(response, "memberInfo.cellPhone")
          ),
        },
        primaryPhysician: {
          ...getValue(response, "primaryPhysician"),
          phoneNo: getFormattedPhoneNo(
            getValue(response, "primaryPhysician.phoneNo")
          ),
          fax: getFormattedPhoneNo(getValue(response, "primaryPhysician.fax")),
        },
      });
    }
  }, [response]);

  useEffect(() => {
    if (
      getValue(InternalDataValuesResponse, "type") ===
      NotifyInternalDataType.PRIOR_AUTH_REQUEST_EMERGENCY_CONTACT
    ) {
      reset({
        ...getValues(),
        emergencyContact: getValue(InternalDataValuesResponse, "value"),
      });
    }
    if (
      getValue(InternalDataValuesResponse, "type") ===
      NotifyInternalDataType.PRIOR_AUTH_REQUEST_PRIMARY_PHYSICIAN_INFO
    ) {
      reset({
        ...getValues(),
        primaryPhysician: getValue(InternalDataValuesResponse, "value"),
      });
    }
  }, [InternalDataValuesResponse]);

  useEffect(() => {
    if (isEmptyValue(getFormValues(fieldNames, getValues)) && activeTab == 2) {
      setTimeout(() => {
        clearErrors(fieldNames);
      }, 10);
    }
  }, [updatePayload]);

  type KeyValueObject = { [key: string]: any };

  const getFormValues = (
    fields: string[],
    getValues: (field: string) => any
  ): KeyValueObject => {
    return fields.reduce((acc, field) => {
      acc[field] = getValues(field);
      return acc;
    }, {} as KeyValueObject);
  };

  useEffect(() => {
    if (
      getValue(InternalDataValuesResponse, "type") ===
      NotifyInternalDataType.PRIOR_AUTH_REQUEST_EMERGENCY_CONTACT
    ) {
      reset({
        ...getValues(),
        emergencyContact: getValue(InternalDataValuesResponse, "value"),
      });
    }
  }, [InternalDataValuesResponse]);

  const handleChange = async (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    const error = await trigger();
    if (isEmptyValue(getFormValues(fieldNames, getValues)) && activeTab == 2) {
      setActiveTab(newValue);
    } else if (error) {
      setActiveTab(newValue);
    } else {
      setIsOpen(true);
    }
  };

  const updatePrimaryPhysicianPayload = () => {
    if (isNull(getValue(response, "primaryPhysician"))) {
      if (getValues("primaryPhysician.physicianId") > 0) {
        reset({
          ...getValues(),
          primaryPhysician: {
            ...getValues("primaryPhysician"),
            referralId: 0,
            referralPhysicianDetailId: 0,
            physicianTypeId: PhysicianTypeID.PRIMARY,
          },
        });
      } else {
        reset({
          ...getValues(),
          primaryPhysician: {
            ...getValues("primaryPhysician"),
            referralId: 0,
            referralPhysicianDetailId: 0,
            physicianTypeId: PhysicianTypeID.PRIMARY,
            physicianId: 0,
            physicianUid: uuidv4(),
          },
        });
      }
    } else {
      const physicianResponse = {
        ...getValue(response, "primaryPhysician"),
        phoneNo: getFormattedPhoneNo(
          getValue(response, "primaryPhysician.phoneNo")
        ),
        fax: getFormattedPhoneNo(getValue(response, "primaryPhysician.fax")),
      };
      if (
        !isEqual(physicianResponse, getValues("primaryPhysician")) &&
        getValue(response, "primaryPhysician.physicianId") ===
          getValues("primaryPhysician.physicianId")
      ) {
        reset({
          ...getValues(),
          primaryPhysician: {
            ...getValues("primaryPhysician"),
            physicianTypeId: PhysicianTypeID.PRIMARY,
            physicianId: 0,
            physicianUid: uuidv4(),
          },
        });
      }
    }
    if (isEmptyValue(getFormValues(fieldNames, getValues))) {
      reset({
        ...getValues(),
        primaryPhysician: null,
      });
    }
  };

  const handleRemove = (index: any) => {
    remove(index);
    setEmergencyContactRelationid(
      emergencyContactRelationid.filter(
        (id) =>
          id.relationshipId !==
          getValue(values, `emergencyContact.${index}.relationId`)
      )
    );
  };

  const onSubmit = () => {
    updatePrimaryPhysicianPayload();
    updatePatientHeader(getValues());
  };

  return (
    <Permission
      controlId={`|${PatientRecordsAccess.PATIENT_DETAILS_HEADER_READ}|`}
      passThrough
    >
      {(isVisible: boolean) =>
        isVisible && (
          <>
            {getValue(patientData, "response.patientId") > 0 && (
              <Box>
                <Box>
                  <PatientHeaderTabs
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="Patient Header Tabs"
                  >
                    {PatientHeaderLabel.map((data, index) => (
                      <PatientHeaderTab
                        key={index}
                        label={data.label}
                        {...a11yProps(index)}
                      />
                    ))}
                  </PatientHeaderTabs>
                </Box>
                <TabPanel value={activeTab} index={0}>
                  <MemberInfo
                    control={control}
                    handleSave={handleSubmit(onSubmit)}
                    patientData={response}
                    langaugeMasterList={getList(LanguageResponse, {
                      label: "languageName",
                      value: "languageId",
                    })}
                    accomodationMasterList={getList(AccomodationResponse, {
                      label: "accommodationName",
                      value: "accommodationId",
                    })}
                  />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <AddressInfo patientData={response} />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                  <PhysicianInfo
                    control={control}
                    reset={reset}
                    getValues={getValues}
                    setValue={setValue}
                    inputFieldNames={primaryPhysicianFormNames}
                    physicianAutocompleteName="primaryPhysicianPhysicianName"
                    handleSave={handleSubmit(onSubmit)}
                  />
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                  <AlternateContact
                    fields={fields}
                    emergencyContactValues={emergencyContactValues}
                    control={control}
                    relationshipList={getList(
                      relationshipList,
                      EmergencyContactOption
                    )}
                    handleRemove={handleRemove}
                    append={append}
                    patientData={response}
                    handleSave={handleSubmit(onSubmit)}
                  />
                </TabPanel>
                <InfoDialog
                  alertMessage={MandatoryFieldAlertMessage}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              </Box>
            )}
          </>
        )
      }
    </Permission>
  );
};

export default PatientHeader;
