import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { isNull } from "lodash";

import {
  CheckboxFormControl,
  PatientTableHeadCell,
  TableCellHeaderLeft,
  TableCellRendererStyle,
  TableCellStyle,
  TableContainerStyle,
  TableNameDetails,
  TableRowStyle,
  TableStyleModal,
} from "../../styles/modal";
import {
  AlignCenter,
} from "../../styles/style";
import {
  AssignedStaff,
  AssignStaffIncompleteRequest,
} from "../../../../models/Patient";
import fontWeight from "../../../../styles/mui/fontWeight";
import { colors } from "../../../../styles/colors";
import { getValue, length, serviceStatusColor, urgencyStatusColor } from "../../../../utils";
import { formatDate } from "../../../../utils/DateTime";
import { User, UserDetailsPayload, UserListWithFilterPayload } from "../../../../models/User";
import { isNil } from "../../../../utils";
import { MyPatientResponse } from "../../../../models/Api/AllPatientRecord";
import {
  StaffingAssignStaff,
  StaffingUnassignStaff,
} from "../../../../models/Staffing";
import { DateFormats } from "../../../../constants/Date";
import {
  AssignStaffHeaderLabelType,
} from "../../../../constants/AllPatientRecord";
import { ActionType } from "../../../../constants/Staffing";
import AssignStaffModalUI from "../../../../components/custom/assignStaffModalUI/components/AssignStaffModalUI";
import {
  TooltipStyle,
} from "../../../../styles/common/style";

export interface PropsFromState {
  activeTab: number;
  handleClear: () => void;
  openModal: boolean;
  patientList: any;
  selectedList: any | undefined;
  setAllSelect: (value: boolean) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: (page: number) => void;
  setPatientSelected: React.Dispatch<React.SetStateAction<any>>;
  setAllSelectedPatients: React.Dispatch<React.SetStateAction<any>>;
  type: any;
  user: UserDetailsPayload;
  userList: User[];
  myPatientUpdatedData: MyPatientResponse;
  action?: string;
}
export interface PropsFromDispatch {
  updateMyPatientList: (
    assignedToStaffId: number,
    payload: AssignedStaff
  ) => void;
  updateIncompletePatientList: (
    assignedToUserId: number,
    payload: AssignStaffIncompleteRequest
  ) => void;
  getStaffList: (payload: UserListWithFilterPayload) => void;
  postStaffingAssignStaff: (payload: StaffingAssignStaff) => void;
  postStaffingUnassignStaff: (payload: StaffingUnassignStaff) => void;
}
type Props = PropsFromState & PropsFromDispatch;
const AssignStaff = ({
  activeTab,
  getStaffList,
  openModal,
  patientList,
  selectedList,
  setAllSelect,
  setOpenModal,
  setPage,
  setPatientSelected,
  setAllSelectedPatients,
  updateIncompletePatientList,
  postStaffingAssignStaff,
  updateMyPatientList,
  user,
  userList,
  action,
  postStaffingUnassignStaff,
}: Props) => {
  const [selectedPatients, setSelectedPatients] = useState<any>([]);
  const [selectedPatientsData, setSelectedPatientsData] = useState<any>();
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [availableStaffResponse, setAvailableStaffResponse] =
    useState(userList);
  const [userName, setUserName] = useState<User | undefined | null>();

  const isAllSelected =
    selectedPatients.length > 0 &&
    selectedPatientsData.length === selectedPatients.length;

  const handleCheckbox = (e: any) => {
    const value = e.target.value;
    if (value === "all") {
      const newData = selectedPatients.map((list: any) =>
        (activeTab === 1 || activeTab === 0 || activeTab === 4)
          ? list.serviceRequestDisciplineId
          : activeTab === 3
          ? list.referralId
          : list.serviceRequestId
      );
      setSelectedPatientsData(
        selectedPatientsData.length === selectedPatients.length ? [] : newData
      );
      return;
    }

    const selectedPatientsList = [...selectedPatientsData];
    const index = selectedPatientsList.indexOf(Number(value));

    index === -1
      ? selectedPatientsList.push(value)
      : selectedPatientsList.splice(index, 1);
    const newList = selectedPatientsList.map(Number);
    setSelectedPatientsData(newList);
  };

  useEffect(() => {
    setAvailableStaffResponse(userList);
  }, [userList]);

  useEffect(() => {
    if (selectedList) {
      setSelectedPatients(selectedList ? selectedList : []);
      setSelectedPatientsData(
        selectedList
          ? selectedList.map((list: any) =>
            (activeTab === 1 || activeTab === 0 || activeTab === 4)
              ? list.serviceRequestDisciplineId
              : activeTab === 3
                ? list.referralId
                : list.serviceRequestId
            )
          : []
      );
    }
  }, [selectedList]);

  const handleAssign = () => {
    setPage(1);
    if (activeTab === 1 || activeTab == 0 || activeTab === 4) {
      if (!isNil(availableStaffResponse) && userName !== undefined) {
        const data: AssignedStaff = {
          assignedUserId: availableStaffResponse[0].userId,
          assignedUserFullName: availableStaffResponse[0].fullName,
          userCreated: user && user.email ? user.email : "",
          disciplineRequestIds: selectedPatientsData,
        };
        setOpenModal(false);
        setAllSelect(false);
        updateMyPatientList(availableStaffResponse[0].userId, data);
        setPatientSelected([]);
        setAllSelectedPatients([]);
        setErrorFlag(false);
      } else {
        setErrorFlag(true);
      }
    }
    if (activeTab === 2) {
      const updatedServiceRequestIds = selectedPatientsData
        .toString()
        .split(",");
      if (!isNil(availableStaffResponse) && userName !== undefined) {
        const data: AssignStaffIncompleteRequest = {
          assignedUserId: availableStaffResponse[0].userId,
          assignedUserFullName: availableStaffResponse[0].fullName,
          userUpdated: user && user.email ? user.email : "",
          serviceRequestIds: updatedServiceRequestIds,
        };
        setOpenModal(false);
        setAllSelect(false);
        updateIncompletePatientList(availableStaffResponse[0].userId, data);
        setPatientSelected([]);
        setAllSelectedPatients([]);
        setErrorFlag(false);
      } else {
        setErrorFlag(true);
      }
    }
    if (activeTab === 3) {
      const updatedServiceRequestIds = selectedPatientsData;
      if (
        !isNil(availableStaffResponse) &&
        userName !== undefined &&
        action === ActionType.ASSIGN_STAFF
      ) {
        const assignStaffdata: StaffingAssignStaff = {
          assignedUserId: availableStaffResponse[0].userId,
          assignedUserFullName: availableStaffResponse[0].fullName,
          referralIds: updatedServiceRequestIds,
        };
        setOpenModal(false);
        setAllSelect(false);
        postStaffingAssignStaff(assignStaffdata);
        setPatientSelected([]);
        setAllSelectedPatients([]);
        setErrorFlag(false);
      } else {
        setErrorFlag(true);
      }
      if (
        !isNil(availableStaffResponse) &&
        action === ActionType.UNASSIGN_STAFF
      ) {
        const unAssignStaffdata: StaffingUnassignStaff = {
          referralIds: updatedServiceRequestIds,
        };
        setOpenModal(false);
        setAllSelect(false);
        postStaffingUnassignStaff(unAssignStaffdata);
        setPatientSelected([]);
        setAllSelectedPatients([]);
        setErrorFlag(false);
      }
    }
  };

  const handleClose = () => {
    setPage(1);
    setPatientSelected([]);
    setAllSelectedPatients([]);
    setOpenModal(false);
    setAllSelect(false);
    setErrorFlag(false);
  };

  const onSelection = (
    _e: SyntheticEvent<Element, Event>,
    value: User | null
  ) => {
    setErrorFlag(false);
    setUserName(value);
    setAvailableStaffResponse(
      value != null && !isNil(userList)
        ? userList.filter(
            (v: User) =>
              v.fullName === value.fullName && v.userId === value.userId
          )
        : userList
    );
  };

  const tableRenderer = () => {
    return (
      <TableContainer sx={TableContainerStyle}>
        <Table sx={TableStyleModal} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCellHeaderLeft>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={"all"}
                      size="small"
                      onChange={handleCheckbox}
                      checked={isAllSelected}
                    />
                  }
                  label=""
                  sx={CheckboxFormControl}
                />
              </TableCellHeaderLeft>
              {patientList.map((tablecell: any, index: any) => {
                return (
                  <TableCell
                    key={index}
                    sx={PatientTableHeadCell}
                    width={tablecell.width}
                  >
                    <Box>
                      <Box sx={AlignCenter}>
                        <Typography
                          variant="body2"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.fonts[14]}
                        >
                          {tablecell.label}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {length(selectedPatients) ? (
              selectedPatients.map((row: any, index: number) => {
                return (
                  <TableRow key={index} sx={TableRowStyle}>
                    <TableCell
                      sx={TableCellStyle(selectedPatients.length, index)}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={
                              (activeTab === 1 || activeTab === 0 || activeTab === 4)
                                ? row.serviceRequestDisciplineId
                                : activeTab === 3
                                ? row.referralId
                                : row.serviceRequestId
                            }
                            onChange={handleCheckbox}
                            checked={selectedPatientsData.includes(
                              (activeTab === 1 || activeTab === 0 || activeTab === 4)
                                ? row.serviceRequestDisciplineId
                                : activeTab === 3
                                ? row.referralId
                                : row.serviceRequestId
                            )}
                            size="small"
                          />
                        }
                        label=""
                        sx={CheckboxFormControl}
                      />
                    </TableCell>
                    {patientList &&
                      patientList.map(
                        (tabelcell: AssignStaffHeaderLabelType) => {
                          if (tabelcell.key === 1) {
                            return (
                              <TableCell
                                width={tabelcell.width}
                                key={tabelcell.name}
                                sx={TableNameDetails(
                                  selectedPatients.length,
                                  index
                                )}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={fontWeight.Weight[6]}
                                  color={colors.fonts[18]}
                                >
                                  {getValue(
                                    selectedPatients[Number(index)],
                                    tabelcell.name,
                                    null
                                  )}
                                </Typography>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              width={tabelcell.width}
                              key={tabelcell.name}
                              sx={TableCellRendererStyle(
                                selectedPatients.length - 1 === index
                              )}
                            >
                              {tableCellRender(
                                selectedPatients[Number(index)],
                                tabelcell.name
                              )}
                            </TableCell>
                          );
                        }
                      )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={12}>
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const tableCellRender = (object: any, name: string) => {
    return (
      <>
        {name === "providerNpi" &&
        !isNull(getValue(object, name, null)) &&
        !isNull(getValue(object, "providerName", null)) ? (
          <Tooltip
            title={
              <Typography variant="subtitle1" fontWeight={fontWeight.Weight[4]}>
                {getValue(object, "providerName", null)}
              </Typography>
            }
            arrow
            slotProps={{
              popper: {
                sx: TooltipStyle,
              },
            }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={colors.black[5]}
            >
              {getValue(object, name, null)}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            variant="body1"
            fontWeight={fontWeight.Weight[5]}
            color={
              name == "serviceStatus" ? serviceStatusColor(getValue(object, name, null)) : name == "urgencyStatus"
                ? urgencyStatusColor(getValue(object, name, null))
                : colors.black[5]
            }
          >
            {name === "requestDateTime"
              ? formatDate(
                  getValue(object, name, null),
                  DateFormats.MM_DD_YYYY_comma_hh_mm_ss_a
                )
              : getValue(object, name, null)}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
     <Grid >
          {openModal && <
            AssignStaffModalUI
            openModal={openModal}
            action={action}
            handleClose={handleClose}
            handleAssign={handleAssign}
            userList={userList}
            getStaffList={getStaffList}
            userName={userName}
            availableStaffResponse={availableStaffResponse}
            onSelection={onSelection}
            tableRenderer={tableRenderer}
            errorFlag={errorFlag}
            selectedPatientsData={selectedPatientsData}
            selectedPatients={selectedPatients}
          />}
        </Grid>
    </>
  );
};
export default AssignStaff;
