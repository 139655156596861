import {
  FaEdit,
  FaRegFileAlt,
  FaRegTrashAlt,
  FaSyncAlt,
  FaDownload,
  FaFax,
} from "react-icons/fa";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputLabel,
  Tooltip,
} from "@mui/material";
import {
  Control,
  Controller,
  UseFormTrigger,
  useForm,
  useWatch,
} from "react-hook-form";
import FileSaver from "file-saver";
import { indexOf, isEmpty } from "lodash";
import { useParams } from "react-router";

import {
  displayFlex,
  flexAlignCentre,
} from "../../../../../styles/mui/styles/display";
import { accent, colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  ActionSection,
  AddDocumentSection,
  BodyTable,
  ButtonFormControl,
  CancelButton,
  DateFormControl,
  DocumentData,
  DocumentDetailsTableCell,
  DocumentTitle,
  DocumentTypeIcon,
  DropdownGrid,
  FileIcon,
  Gridbody,
  Header,
  HeaderItem,
  HorizontalDivider,
  SaveButton,
  ShowIcon,
  TabHorizontalDivider,
  TableWidth,
  DatePickerInput,
  HiddenTextField,
  addedDocumentField,
  RefreshIconStyle,
  DocumentName,
  DocumentNameUnderline,
  DisabledDocumentLink,
  DisabledDownloadIcon,
  HeaderTabelCell,
} from "../styles/DocumentReview";
import {
  BlobDoc,
  BlobData,
  ReAuthReviewBlobDoc,
} from "../../../../../models/Document";
import FileUpload from "../../../../../components/formComponents/FileUpload";
import {
  FileHeaderGrid,
  FileUploadBox,
  FlexBox,
  FormLabelSpace,
} from "../styles/Document";
import {
  checkSendFaxDocType,
  extractDocType,
  getValue,
  isNil,
  length,
} from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import {
  HeaderLabelDocumentReview,
  inputType,
} from "../../../../../constants/Constants";
import {
  CreatedByResponse,
  DocumentSourceResponse,
  FaxRecipientMasterResponse,
} from "../../../../../models/Api/Master";
import {
  DocumentSource,
  DocumentType,
  UserDetail,
} from "../../../../../models/Master";
import {
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
} from "../styles/requestStyle";
import {
  DocumentDownloadResponse,
  DocumentFileResponse,
} from "../../../../../models/Api/Document";
import { DateFormats } from "../../../../../constants/Date";
import { FormInputTimePicker } from "../../../../../components/formComponents/FormInputTimePicker";
import {
  MessageType,
  ResponseMessages,
} from "../../../../../constants/ToastMessage";
import { ReferralResponseMessages } from "../../../../../constants/AllPatientRecord";
import { rules } from "../../../../../utils/validation/Validation";
import { UserDetailsPayload } from "../../../../../models/User";
import SnackbarUtils from "../../../../../utils/SnackbarProvider";
import {
  DocumentTabType,
  FaxSentStatus,
  ReferralDocumentFileDispatchTypes,
  Source,
} from "../../../../../constants/Document";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import { DocumentDownloadDispatchTypes } from "../../../../../constants/Document";
import { getDocTypeIcon } from "../../../../../utils/DocIcons";
import Permission from "../../../../Permission";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import { IconDisabled } from "../../../../../styles/common/style";
import { documentFaxMaxSize, documentMaxSize, documentMinSize } from "../../../../../constants/Config";
import DocumentSendFax from "./DocumentSendFax";
import { InitiateFaxSendingResponse } from "../../../../../models/Api/Authorization";
import { Checkbox } from "../../../../../components/custom/checkbox";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { DropdownOption } from "../../../../../components/formComponents/constant/FormComponents";

export interface PropsFromDispatch {
  deleteDocumentFile: (blobId: any, userDeleted: string) => void;
  deleteReauthDocumentFile: (blobId: any, userDeleted: string) => void;
  documentDownload: (blobId: number) => void;
  getDocumentSource: () => void;
  getReferralDocuments: (referralId: number, type: number) => void;
  saveDocumentFile: (payload: any) => void;
  updateDocumentFile: (payload: any, referralId: string) => void;
  resetStates: (actionType: string[]) => void;
  saveInitiateFax: (payload: FormData) => void;
  postGenerateAuthTemplate: (referralId: string) => void;
}
export interface PropsFromState {
  documentCreatedBy: CreatedByResponse;
  documentDownloadData: DocumentDownloadResponse;
  documentSource: DocumentSourceResponse;
  documentType: DocumentType[];
  flowType?: string;
  isAuthDetails: boolean;
  refId?: string;
  saveDocumentData: DocumentFileResponse;
  user: UserDetailsPayload;
  tabFormControl?: Control<any, any>;
  tabFormTrigger?: UseFormTrigger<any>;
  faxProviderMasterData: FaxRecipientMasterResponse;
  generateAuthTemplate: ReAuthReviewBlobDoc[];
  type: number;
  faxSendResponse: InitiateFaxSendingResponse;
}

type AllProps = PropsFromState & PropsFromDispatch;

const DocumentsReview: React.FC<AllProps> = ({
  deleteDocumentFile,
  deleteReauthDocumentFile,
  documentCreatedBy,
  documentDownload,
  documentDownloadData,
  documentSource,
  documentType,
  flowType,
  getDocumentSource,
  getReferralDocuments,
  isAuthDetails,
  refId,
  saveDocumentData,
  saveDocumentFile,
  updateDocumentFile,
  user,
  resetStates,
  tabFormControl,
  tabFormTrigger,
  faxProviderMasterData,
  saveInitiateFax,
  postGenerateAuthTemplate,
  faxSendResponse,
  type,
  generateAuthTemplate,
}: AllProps) => {
  const { referralId } = useParams();
  const [files, setFiles] = useState<any>([]);
  const [faxFiles, setFileForFax] = useState<any>([]);
  const [createdBy, setCreatedBy] = useState<UserDetail | null>(null);
  const { loading: loadingDocumentSource, response: documentSourceResponse } =
    documentSource;
  const { response: createdByResponse } = documentCreatedBy;
  const [addDocument, setAddDocument] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [userDetail, setUserDetail] = useState<UserDetail[] | any[]>();
  const [isEdit, setIsEdit] = useState(false);
  const [editDoc, setEditDoc] = useState<any | null>();
  const { response: documentResponse, error: saveDocumentError } =
    saveDocumentData;
  const [documentReviewData, setDocumentReviewData] = useState<BlobDoc[]>([]);
  const { response: documentDownloadResponse, error: documentDownloadError } =
    documentDownloadData;
  const [fileName, setFileName] = useState("");
  const [documentError, setDocumentError] = useState<string>("");
  const [faxBlobId, setFaxBlobId] = useState<any>(0);
  const [addDocForFax, setAddDocForFax] = useState<boolean>(false);
  const [editFaxDocName, setEditFaxDocName] = useState<string>("");
  const [editFaxDocMediaType, setEditFaxDocMediaType] = useState<string>("");
  const maxFileSizeInMegaBytes = documentMaxSize.split("MB")[0];
  const maxFileSizeInBytes = Number(maxFileSizeInMegaBytes) * 1000000;
  const minFileSize = Number(documentMinSize.split("KB")[0]);
  const [selectedFaxBlobIds, setSelectedFaxBlobIds] = useState<BlobData[]>([]);

  const defaultValue = {
    dateTimeReceived: null,
    documentSourceId: "0",
    documentId: "0",
    createdBy: createdBy,
    fileName: "",
  };

  const methods = useForm<any>({
    defaultValues: defaultValue,
    mode: "all",
  });

  const {
    setValue,
    control,
    handleSubmit,
    clearErrors,
    unregister,
    getValues,
    watch,
    trigger,
  } = methods;

  useWatch({
    control,
  });
  const [openFax, setOpenFax] = useState<boolean>(false);
  const { response: faxProviderMasterResponse } = faxProviderMasterData;
  const faxRef = useRef<any>(null);

  useEffect(() => {
    if (!loadingDocumentSource) {
      getDocumentSource();
    }
    return () => {
      resetStates([
        DocumentDownloadDispatchTypes.DOCUMENT_DOWNLOAD_RESET,
        ReferralDocumentFileDispatchTypes.REFERRAL_DOCUMENT_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    getDocumentList();
  }, [referralId, refId]);

  useEffect(() => {
    if (
      !isNil(faxSendResponse.response) &&
      faxSendResponse.response.referralBlobId !== -1
    ) {
      setSelectedFaxBlobIds([]);
      getDocumentList();
    }
  }, [faxSendResponse]);

  useEffect(() => {
    if (getValue(generateAuthTemplate, "[0].referralBlobId", 0) > 0)
      setDocumentReviewData(generateAuthTemplate);
  }, [generateAuthTemplate]);

  useEffect(() => {
    if (documentResponse) {
      if (true === documentResponse) {
        //Invoking after delete API call
        setSelectedFaxBlobIds([]);
        getDocumentList();
      } else {
        setDocumentReviewData(documentResponse);
      }
    }
  }, [documentResponse]);

  useEffect(() => {
    if (!isEmpty(saveDocumentError)) {
      setDocumentError(saveDocumentError.data);
    } else {
      setDocumentError("");
      setAddDocument(false);
    }
  }, [saveDocumentError]);

  useEffect(() => {
    if (length(files)) {
      setDocumentError("");
    }
  }, [files]);

  useEffect(() => {
    if (documentReviewData && documentReviewData.length > 0) {
      tabFormTrigger && tabFormTrigger("addedDocuments");
    }
  }, [documentReviewData]);

  const getDocumentList = () => {
    if (referralId || refId) {
      getReferralDocuments(
        referralId ? Number(referralId) : refId ? Number(refId) : 0,
        type
      );
    }
  };

  const handleEditDocument = (row: any) => {
    const userDetails =
      userDetail &&
      length(userDetail) &&
      userDetail.filter((item: UserDetail) => {
        return item.fullName == row.createdBy;
      });
    setAddDocument(true);
    setIsEdit(true);
    setEditDoc(row);
    setFiles([{ name: row.originalDocumentName }]);
    setCreatedBy({
      userId: userDetails && length(userDetails) ? userDetails[0].userId : "",
      fullName: row.createdBy,
    });
    setValue("dateTimeReceived", dayjs(row.dateTimeReceived));
    setValue("documentSourceId", row.documentSourceId);
    setValue("documentId", row.documentId);
    setValue("createdBy", {
      userId: userDetails && length(userDetails) ? userDetails[0].userId : "",
      fullName: row.createdBy,
    });
  };

  const handleScrollView = () => {
    faxRef &&
      faxRef.current &&
      faxRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
  };

  useEffect(() => {
    if (openFax === true && isAuthDetails) {
      handleScrollView();
    }
  }, [openFax]);

  const handleEditFax = (row: any) => {
    setFaxBlobId(row.blobId);
    setEditFaxDocMediaType(row.mediaType);
    setEditFaxDocName(row.originalDocumentName);
    setFileForFax([]);

    const selectedFileSizeInMb = (
      row.blobContentLength /
      (1024 * 1024)
    ).toFixed(2);

    const blobObj = {
      fileName: row.originalDocumentName,
      blobId: row.blobId,
      blobSize: Number(selectedFileSizeInMb),
    };
    setSelectedFaxBlobIds([...selectedFaxBlobIds, blobObj]);
    setOpenFax(true);
    setAddDocForFax(true);
  };

  useEffect(() => {
    setUserDetail(createdByResponse);
  }, [createdByResponse]);

  useEffect(() => {
    if (files && files.length > 0) {
      clearErrors("fileName");
      setValue("fileName", "selected");
    } else {
      setValue("fileName", null);
    }
  }, [files]);

  const handleSaveFile = () => {
    const formData = new FormData();
    formData.append(
      "ReferralBlobId",
      editDoc && editDoc.referralBlobId
        ? editDoc.referralBlobId.toString()
        : "0"
    );
    formData.append("ReferralId", referralId ? referralId : refId ? refId : "");
    formData.append("DocumentId", getValues("documentId") || "0");
    formData.append(
      "BlobId",
      editDoc && editDoc.blobId ? editDoc.blobId.toString() : "0"
    );
    formData.append(
      "DateTimeReceived",
      getValues("dateTimeReceived")
        ? getValues("dateTimeReceived").toString()
        : dayjs().toString()
    );
    formData.append("DocumentSourceId", getValues("documentSourceId") || "0");
    formData.append("UserCreated", user.email);
    formData.append(
      "ReferralDocument",
      files && files.length > 0 ? files[0] : {}
    );
    formData.append("UserFullName", user.fullName);
    isEdit
      ? updateDocumentFile(
          formData,
          referralId ? referralId : refId ? refId : ""
        )
      : saveDocumentFile(formData);
    setValue("documentId", "0");
    setValue("documentSourceId", "0");
    setCreatedBy(null);
    setFiles([]);
    setEditDoc(null);
    setIsEdit(false);
  };

  const handleCancelFile = () => {
    setValue("documentId", "0");
    setValue("documentSourceId", "0");
    setCreatedBy(null);
    setFiles([]);
    setIsEdit(false);
    setAddDocument(false);
    unregister([
      "dateTimeReceived",
      "documentSourceId",
      "documentId",
      "createdBy",
      "fileName",
    ]);
  };

  const handleDeleteFile = (blobId: any) => {
    const userName = getValue(user, "email", "");
    if (flowType && flowType == "reAuth") {
      deleteReauthDocumentFile(blobId, userName);
    } else {
      deleteDocumentFile(blobId, userName);
    }
  };

  const downloadFile = (blobId: number, fileName: string) => {
    documentDownload(blobId);
    setFileName(fileName);
  };

  useEffect(() => {
    if (documentDownloadResponse && documentDownloadResponse.blob) {
      const blob = new Blob([documentDownloadResponse.blob], {
        type: documentDownloadResponse.headers["content-type"],
      });
      FileSaver.saveAs(blob, fileName);
      setFileName("");
    } else if (
      documentDownloadError &&
      documentDownloadError.error &&
      Object.keys(documentDownloadError.error).length == 0
    ) {
      SnackbarUtils.error(
        ReferralResponseMessages(MessageType.DOCUMENT_DOWNLOAD_ERROR)
      );
    }
  }, [documentDownloadResponse, documentDownloadError]);

  const addNewDocument = () => {
    setAddDocument(true);
    setValue("documentSourceId", undefined);
    setValue("documentId", undefined);
    setCreatedBy(null);
    setSelectedFaxBlobIds([]);
    setValue("dateTimeReceived", null);
    setValue("documentSourceId", null);
    setValue("documentId", null);
    setValue("createdBy", null);
    setValue("fileName", null);
  };

  const openSendFax = () => {
    if (selectedFaxBlobIds.length > 0) {
      setAddDocForFax(true);
      setFaxBlobId(-1);
    } else {
      setFaxBlobId(0);
      setAddDocForFax(false);
    }
    setOpenFax(true);
  };

  const handleGenerateAuthTemplate = () => {
    if (refId) postGenerateAuthTemplate(refId);
  };

  const handleRefreshFaxStatus = () => {
    getDocumentList();
  };

  const documentSourceList = (
    documentSourceResponse: DocumentSource[] | undefined
  ): DropdownOption[] => {
    if (documentSourceResponse && length(documentSourceResponse)) {
      return documentSourceResponse.map((documentSource) => ({
        label: documentSource.documentSourceName,
        value: documentSource.documentSourceId,
      }));
    }
    return [];
  };

  const documentTypeList = (
    documentType: DocumentType[] | undefined
  ): DropdownOption[] => {
    if (documentType && length(documentType)) {
      return documentType.map((document) => ({
        label: document.documentName,
        value: document.documentId,
      }));
    }
    return [];
  };

  const selectFaxBlobId = (
    blobId: number,
    docName: string,
    selectedBlobLength: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked === true) {
      const checkedFileSizeInMb = selectedFaxBlobIds.map(
        (data) => data.blobSize
      );
      const selectedFileSizeInMb = (selectedBlobLength / (1024 * 1024)).toFixed(
        2
      );

      const sumFileSize = checkedFileSizeInMb.reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
        0
      );

      if (
        Math.ceil(Number(sumFileSize) + Number(selectedFileSizeInMb)) <
        Number(documentFaxMaxSize)
      ) {
        const blobObj = {
          fileName: docName,
          blobId: blobId,
          blobSize: Number(selectedFileSizeInMb),
        };
        setSelectedFaxBlobIds([...selectedFaxBlobIds, blobObj]);
      } else {
        SnackbarUtils.warning(ResponseMessages(MessageType.SEND_FAX_MAX_SIZE));
      }
    } else {
      setSelectedFaxBlobIds(
        selectedFaxBlobIds.filter((id) => id.blobId !== blobId)
      );
    }
  };

  const headerTableCell = (
    value: string | number,
    width: string,
    displayIcon?: boolean,
    isDocNo?: boolean,
    key?: number
  ) => {
    return (
      <>
        <TableCell width={width} key={key}>
          <Box sx={HeaderTabelCell(isDocNo)}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[3]}
              color={colors.black[14]}
            >
              {value}
            </Typography>
            {displayIcon && (
              <FaSyncAlt
                size={"0.8rem"}
                style={RefreshIconStyle}
                onClick={() => handleRefreshFaxStatus()}
              />
            )}
          </Box>
        </TableCell>
      </>
    );
  };

  const faxReadyToDownload = (blobId: number, documentSourceName: string) => {
    return documentSourceName === Source.FAX && blobId === 0;
  };

  return (
    <Grid container pb={"2rem"}>
      <Grid item xs={12}>
        <Box sx={Header}>
          <Box sx={flexAlignCentre}>
            {isAuthDetails && showDocument ? (
              <FaChevronCircleDownIcon
                onClick={() => setShowDocument(false)}
                sx={ShowIcon}
              />
            ) : isAuthDetails && !showDocument ? (
              <FaChevronCircleUpIcon
                onClick={() => setShowDocument(true)}
                sx={ShowIcon}
              />
            ) : (
              ""
            )}
            <FaRegFileAlt
              size={"1.2rem"}
              color={colors.fonts[18]}
              style={isAuthDetails ? {} : FileIcon}
            />
            <Typography
              variant={isAuthDetails ? "subtitle1" : "subtitle2"}
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
              sx={DocumentTitle}
            >
              DOCUMENT REVIEW:
            </Typography>
            {tabFormControl && (
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[4]}
                color={colors.red[100]}
                mt={"0.2rem"}
              >
                *
              </Typography>
            )}
          </Box>
          <Box sx={addedDocumentField}>
            {isAuthDetails && (
              <>
                <Button
                  variant="contained"
                  onClick={() => handleGenerateAuthTemplate()}
                >
                  GENERATE AUTH TEMPLATE
                </Button>
                <Button
                  variant="contained"
                  onClick={() => openSendFax()}
                  disabled={addDocument || openFax}
                >
                  SEND FAX
                </Button>
              </>
            )}
          </Box>

          <Permission
            controlId={`${PatientRecordsAccess.DOCUMENT_CREATE}|${PatientRecordsAccess.DOCUMENT_READ}|`}
            passThrough
          >
            {(isDisabled: boolean) => (
              <Button
                variant="contained"
                onClick={() => addNewDocument()}
                disabled={addDocument || openFax || isDisabled}
              >
                ADD NEW DOCUMENT
              </Button>
            )}
          </Permission>
        </Box>
      </Grid>
      <Divider
        light
        sx={isAuthDetails ? HorizontalDivider : TabHorizontalDivider}
      />
      {!showDocument && (
        <>
          <Grid item xs={12} sx={AddDocumentSection(isAuthDetails)}>
            <Box>
              <Typography
                variant="h6"
                fontWeight={fontWeight.Weight[6]}
                color={colors.fonts[20]}
              >
                ADD / MODIFY:
              </Typography>
            </Box>
            <Box pt={"2rem"}>
              <TableContainer sx={TableWidth}>
                <Table aria-label="collapsible table" sx={BodyTable}>
                  <TableHead sx={HeaderItem}>
                    <TableRow>
                      {isAuthDetails && headerTableCell("", "2%")}
                      {HeaderLabelDocumentReview.map(
                        (row: any, index: number) => {
                          return headerTableCell(
                            row.label,
                            row.width,
                            row.displayIcon,
                            row.isDocNo,
                            index
                          );
                        }
                      )}

                      {isAuthDetails &&
                        headerTableCell("FAX STATUS", "12%", true)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentReviewData &&
                      length(documentReviewData) &&
                      documentReviewData.map((row: any) => {
                        return (
                          <TableRow key={row.referralBlobId}>
                            {isAuthDetails ? (
                              row.documentSourceName !== "Fax" &&
                              checkSendFaxDocType(row.mediaType) === true ? (
                                <TableCell>
                                  <Checkbox
                                    handleChange={(e) =>
                                      selectFaxBlobId(
                                        row.blobId,
                                        row.originalDocumentName,
                                        row.blobContentLength,
                                        e
                                      )
                                    }
                                    checked={selectedFaxBlobIds.some(
                                      (data) => data.blobId === row.blobId
                                    )}
                                    disabled={openFax || addDocument}
                                  ></Checkbox>
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )
                            ) : null}
                            {documentTableCell(row.sequenceNo, false, true)}
                            <TableCell sx={DocumentDetailsTableCell}>
                              <Box sx={flexAlignCentre}>
                                <Box sx={DocumentTypeIcon}>
                                  {getDocTypeIcon(
                                    extractDocType(row.mediaType),
                                    faxReadyToDownload(
                                      row.blobId,
                                      row.documentSourceName
                                    )
                                      ? colors.grey[1]
                                      : undefined
                                  )}
                                </Box>
                                <Typography
                                  variant="h5"
                                  fontWeight={fontWeight.Weight[6]}
                                  color={colors.primary.main}
                                  pt={"0.3rem"}
                                >
                                  <Link
                                    variant="h5"
                                    fontWeight={fontWeight.Weight[4]}
                                    color={colors.fonts[18]}
                                    onClick={() =>
                                      downloadFile(
                                        row.blobId,
                                        row.originalDocumentName
                                      )
                                    }
                                    sx={
                                      faxReadyToDownload(
                                        row.blobId,
                                        row.documentSourceName
                                      )
                                        ? DisabledDocumentLink
                                        : DocumentNameUnderline
                                    }
                                  >
                                    <Tooltip
                                      title={
                                        <Typography
                                          variant="subtitle2"
                                          fontWeight={fontWeight.Weight[3]}
                                          lineHeight="1rem"
                                        >
                                          {row.originalDocumentName}
                                        </Typography>
                                      }
                                      arrow
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight={fontWeight.Weight[5]}
                                        color={colors.fonts[18]}
                                        sx={
                                          faxReadyToDownload(
                                            row.blobId,
                                            row.documentSourceName
                                          )
                                            ? [
                                                DocumentName,
                                                DisabledDocumentLink,
                                              ]
                                            : DocumentName
                                        }
                                      >
                                        {row.originalDocumentName}
                                      </Typography>
                                    </Tooltip>
                                  </Link>
                                </Typography>
                              </Box>
                            </TableCell>
                            {documentTableCell(
                              row.showDateTime
                                ? formatDate(
                                    row.dateTimeReceived,
                                    DateFormats.MM_DD_YYYY_hh_mm_ss_a
                                  )
                                : "",
                              true
                            )}
                            {documentTableCell(row.documentSourceName)}
                            {documentTableCell(row.documentTypeName)}
                            {documentTableCell(row.userFullName)}
                            <TableCell sx={ActionSection}>
                              {row.isLetterDocument !== true &&
                                (isAuthDetails &&
                                indexOf(
                                  [Source.INBOUND, Source.FAX],
                                  row.documentSourceName
                                ) !== -1 ? (
                                  <>
                                    {row.sendFax && (
                                      <FaFax
                                        size={"1rem"}
                                        color={colors.fonts[18]}
                                        onClick={() => handleEditFax(row)}
                                      />
                                    )}
                                    {faxReadyToDownload(
                                      row.blobId,
                                      row.documentSourceName
                                    ) ? (
                                      <FaDownload
                                        size={"1rem"}
                                        style={DisabledDownloadIcon}
                                      />
                                    ) : (
                                      <FaDownload
                                        size={"1rem"}
                                        color={colors.fonts[18]}
                                        onClick={() =>
                                          downloadFile(
                                            row.blobId,
                                            row.originalDocumentName
                                          )
                                        }
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Permission
                                    controlId={`|${PatientRecordsAccess.DOCUMENT_READ}|${PatientRecordsAccess.DOCUMENT_UPDATE}`}
                                    passThrough
                                  >
                                    {(isDisabled: boolean) => (
                                      <>
                                        <FaEdit
                                          size={"1.1rem"}
                                          color={colors.fonts[18]}
                                          style={isDisabled ? IconDisabled : {}}
                                          onClick={() =>
                                            handleEditDocument(row)
                                          }
                                        />
                                        <FaRegTrashAlt
                                          size={"1rem"}
                                          color={accent.accentOrange[100]}
                                          style={isDisabled ? IconDisabled : {}}
                                          onClick={() =>
                                            handleDeleteFile(row.blobId)
                                          }
                                        />
                                      </>
                                    )}
                                  </Permission>
                                ))}
                            </TableCell>
                            {isAuthDetails &&
                              documentTableCell(
                                row.faxStatus === "None"
                                  ? ""
                                  : FaxSentStatus[
                                      row.faxStatus as keyof typeof FaxSentStatus
                                    ]
                              )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          {addDocument && (
            <Grid item xs={12} sx={Gridbody}>
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[5]}
                color={colors.fonts[20]}
                pt={"0.3rem"}
                pl={"1rem"}
              >
                ADD DOCUMENTATION:
              </Typography>

              <Divider light sx={HorizontalDivider} />

              <Grid item xs={12} sx={FileHeaderGrid}>
                <Grid container sx={FileUploadBox}>
                  <Grid item xs={3.5}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container sx={FlexBox}>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={fontWeight.Weight[5]}
                              color={colors.fonts[20]}
                              sx={FormLabelSpace}
                            >
                              Add files:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight={fontWeight.Weight[3]}
                                color={colors.black[5]}
                              >
                                Allowed file types (.doc, .docx, .pdf, .jpeg,
                                .jpg, .png, .xlsx, .xls, .txt)
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight={fontWeight.Weight[3]}
                                color={colors.black[5]}
                                lineHeight={1.5}
                              >
                                {`Max file size: ${maxFileSizeInMegaBytes} mb`}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FileUpload
                          inputType={inputType.referralDocs}
                          files={files}
                          setFiles={setFiles}
                          maxFileSizeInBytes={maxFileSizeInBytes}
                          minFileSize={minFileSize}
                          type={DocumentTabType.DOCUMENT}
                        />
                      </Grid>
                    </Grid>
                    <Controller
                      defaultValue={""}
                      control={control}
                      rules={rules.fileSelect}
                      name="fileName"
                      render={({ fieldState: { error } }) => (
                        <FormHelperText>
                          {error ? error.message : null}
                        </FormHelperText>
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.2} sx={displayFlex}>
                    <Divider orientation="vertical" flexItem />
                  </Grid>
                  <Grid item xs={2.5}>
                    <FormControl variant="standard" sx={DateFormControl}>
                      <DatePicker
                        name="dateTimeReceived"
                        control={control}
                        label={"Date Received:"}
                        helper={rules.dateTimeRequired}
                        allowFutureDate={false}
                        InputStyle={DatePickerInput}
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={DateFormControl}
                    >
                      <FormInputTimePicker
                        name="dateTimeReceived"
                        control={control}
                        setValue={setValue}
                        label="Time Received:"
                        helper={rules.timeRequired}
                        value={getValues("dateTimeReceived")}
                        allowFutureTime={false}
                        watch={watch}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        InputStyle={DatePickerInput}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={0.2} sx={displayFlex}>
                    <Divider orientation="vertical" flexItem />
                  </Grid>
                  <Grid item xs={5.6}>
                    <Grid container>
                      <Grid item xs={6} sx={DropdownGrid}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel required shrink={true} sx={displayFlex}>
                            <Typography
                              variant="h5"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[2]}
                            >
                              Source:
                            </Typography>
                          </InputLabel>
                          <FormInputDropdown
                            name="documentSourceId"
                            control={control}
                            helper={rules.documentSource}
                            list={documentSourceList(documentSourceResponse)}
                          />
                        </FormControl>

                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={DateFormControl}
                        >
                          <InputLabel required shrink={true} sx={displayFlex}>
                            <Typography
                              variant="h5"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[2]}
                            >
                              Doc Type:
                            </Typography>
                          </InputLabel>
                          <FormInputDropdown
                            name="documentId"
                            control={control}
                            helper={rules.documentType}
                            list={documentTypeList(documentType)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" sx={ButtonFormControl}>
                          <Button
                            variant="outlined"
                            onClick={handleCancelFile}
                            sx={CancelButton}
                          >
                            CANCEL
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleSubmit(handleSaveFile)}
                            sx={SaveButton}
                          >
                            SAVE
                          </Button>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {documentError && (
                  <Grid item xs={12}>
                    <HiddenTextField type="hidden" />
                    <FormHelperText>{documentError}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
      {openFax && (
        <DocumentSendFax
          saveInitiateFax={saveInitiateFax}
          faxProviderMasterResponse={faxProviderMasterResponse}
          setOpenFax={setOpenFax}
          openFax={openFax}
          user={user}
          resetStates={resetStates}
          faxFiles={faxFiles}
          setFileForFax={setFileForFax}
          isEdit={isEdit}
          faxSendResponse={faxSendResponse}
          showSenderType={false}
          faxBlobId={faxBlobId}
          setAddDocForFax={setAddDocForFax}
          addDocForFax={addDocForFax}
          setEditFaxDocName={setEditFaxDocName}
          editFaxDocName={editFaxDocName}
          editFaxDocMediaType={editFaxDocMediaType}
          setEditFaxDocMediaType={setEditFaxDocMediaType}
          faxRef={faxRef}
          setSelectedFaxBlobIds={setSelectedFaxBlobIds}
          selectedFaxBlobIds={selectedFaxBlobIds}
        />
      )}
    </Grid>
  );
};

const documentTableCell = (
  value: string | number,
  date?: boolean,
  isDocNo?: boolean
) => {
  return (
    <>
      <TableCell sx={DocumentDetailsTableCell}>
        <Typography
          variant="subtitle1"
          fontWeight={date ? fontWeight.Weight[5] : fontWeight.Weight[6]}
          color={colors.fonts[4]}
          sx={DocumentData(isDocNo)}
        >
          {value}
        </Typography>
      </TableCell>
    </>
  );
};

export default DocumentsReview;
