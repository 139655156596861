const otherColors = {
  1: "#D3CCF2",
  2: "#A3D5F1",
  3: "#F9CA89",
  4: "#FCA5A6",
  5: "#7ECFD2",
  6: "#85D8AC",
  7: "#ECE582",
  8: "#9CD3EF",
  9: "#8DDDE9",
  10: "#FF8290",
  11: "#9CD5EF",
  12: "#E0D9FF",
  13: "#6CADDF",
  14: "#F6E6AE",
  15: "#C4E698",
  16: "#E890A7",
  17: "#7CC0E6",
  18: "#E5E092",
  19: "#CDC5EF",
  20: "#CE9ECE",
  21: "#D7F5D5",
  22: "#F5AB98",
  23: "#9A94CF",
  24: "#E5D47E",
  25: "#E5B394",
  26: "#7ECFD2",
  27: "#A1C2CB",
  28: "#AAD9FF",
  29: "#DBE9F2",
  30: "#ECE8FF",
  31: "#69B0B2",
  32: "#B4E6E8",
  33: "#FFFFFF",
  35: "#1e1e1e",
  36: "#4e5264",
  37: "#ff8290",
  38: "#6bec81",
  40: "#141F52",
  41: "#D3DBEB8E",
  42: "#A8A7A7",
  43: "#00000008",
  44: "#F2F2F2",
  45: "#707070",
  46: "#1A2027",
  47: "#F4A460",
  48: "#6B8E23",
  49: "#19b40e",
  50: "#ed5027",
  51: "#f29333",
  52: "#02243B",
  53: "#2b75e5",
  54: "#4cb71f",
  55: "#33AE00",
  56: "#F8A268",
  57: "#e3ebf5",
  58: "#808080",
  59: "#cbcbcb",
  60: "#D3DBEB",
  61: "#1C6BF5",
  62: "#0D3693",
  63: "#2E2E2E",
  64: "#202020",
  65: "#B1B1B1",
  66: "#242424",
  67: "#f2702c",
  68: "#FF9E1F",
  69: "#5C9818",
  70: "#FF8290",
  71: "#ffefb1",
  72: "#a3a3a3"
};

const HeaderMenu = {
  1: "#272C47",
  2: "#0D3693",
};

const MainBackground = {
  1: "#F8F8F8",
  2: "FCFCFC"
};

const DashboardBreadCumb = {
  1: "#414852",
  2: "#676E7D",
  3: "#1C6BF5",
  4: "#3D3D3D",
};

const DashboardReferralProgress = {
  1: "#EEEEEE",
  2: "#A8B5C0",
  3: "#F5F6F8",
};

const SchedulerColors = {
  1: "#F0F2F8",
  2: "#E8F0F8",
  3: "#F3FBFF",
  4: "#F4F6Fa",
  5: "#DDE3ED",
  6: "#98A9C9",
  7: "#636A91",
  8: "#A7B6D1",
  9: "#00000005",
};

const MyPatient = {
  1: "#4a4a4a",
  2: "#474747",
  3: "#f95b5b",
  4: "#75d181",
  5: "#ffc644",
};

const BackButtonBG = {
  1: "#EFF1F5",
};

export default {
  otherColors,
  HeaderMenu,
  MainBackground,
  DashboardBreadCumb,
  DashboardReferralProgress,
  BackButtonBG,
  MyPatient,
  SchedulerColors,
};
