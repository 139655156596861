import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../store/ApplicationState";
import MyPatientDetails from "../components/MyPatientsDetails";
import {
  postIncompletePatientList,
  postMyPatientList,
} from "../../../actions/AllPatientRecord";
import { resetStates } from "../../../actions/Common";

import { postStaffingList } from "../../../actions/Staffing";
import { storeFilterPayload } from "../../../actions/Filter";
import { SearchFilterModel, StaffingQueue } from "../../../models/Filter";

const mapStateToProps = ({
  incompleteRequestState,
  loggedInUserState,
  myPatientListState,
  patientServiceProviderMasterState,
  putMyPatientListState,
  RemovePatientState,
  staffingListState,
  assignStaffIncompleteRequestState,
  staffingAssignStaffState,
  staffingUnassignStaffState,
  RemoveStaffingPatientState,
  filterState,
}: ApplicationState) => ({
  incompelteRequestRecord: incompleteRequestState,
  myPatientsRecord: myPatientListState,
  serviceProviderList: patientServiceProviderMasterState.response,
  user: loggedInUserState.response,
  myPatientUpdatedData: putMyPatientListState,
  removePatientState: RemovePatientState,
  staffingList: staffingListState,
  assignStaffIncompleteRequestData: assignStaffIncompleteRequestState,
  staffingAssignStaffData: staffingAssignStaffState,
  staffingUnassignStaffData: staffingUnassignStaffState,
  RemoveStaffingPatientState: RemoveStaffingPatientState,
  filterStatePayload: filterState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    storeFilterPayload: (type: number, payload?: SearchFilterModel) => {
      dispatch(storeFilterPayload(type, payload));
    },
    postMyPatientList: (payload?: SearchFilterModel) => {
      dispatch(postMyPatientList(payload));
    },
    postIncompletePatientList: (payload?: any) => {
      dispatch(postIncompletePatientList(payload));
    },
    getMyPatientListReset: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    postStaffingList: (payload: StaffingQueue) => {
      dispatch(postStaffingList(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPatientDetails);
