import {
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaChevronDown,
  FaUndo,
} from "react-icons/fa";

import { alpha, styled } from "@mui/system";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";

export const Gridbody = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 1.5rem 0 2rem",
  marginBottom: "0.8rem",
  paddingBottom: "1.2rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const FaxGridBody = () => ({
  background: others.otherColors[33],
  opacity: 1,
  padding: "1.75rem 1.5rem 1rem",
  margin: "1rem 1rem 0.8rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const NotesGridbody = () => ({
  background: others.otherColors[33],
  opacity: 1,
  padding: "1.5rem 1.5rem 0 2rem",
  marginBottom: "0.8rem",
  paddingBottom: "1.2rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const GridbodyStatus = (visits: number) => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 1.5rem 0 2rem",
  marginBottom: visits ? "-0.3125rem" : "0.8rem",
  paddingBottom: visits ? "0" : "1.2rem",
  borderRadius: visits ? "0.8rem 0.8rem 0rem 0rem" : "0.8rem",
  display: "flex",
  clipPath: visits ? "inset(-1.3125rem -0.9375rem 0.1875rem -1rem)" : "none",
});

export const Gridbodytemplate = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.5rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "0 1.5rem 0 2rem",
  marginBottom: "0.8rem",
  paddingBottom: "1.2rem",
  borderRadius: "0rem 0.8rem 0.8rem 0.8rem",
  display: "flex",
});

export const DisciplineGridbody = () => ({
  background: colors.backgroundColor[7],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.2rem 1.5rem",
  marginBottom: "0.8rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const NotesDisciplineGridbody = () => ({
  background: colors.backgroundColor[7],
  opacity: 1,
  padding: "1.75rem 1.5rem 1rem",
  margin: "1rem 1rem 0.8rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const NotesDisciplineGridbodyInfo = () => ({
  opacity: 1,
  padding: "1rem",
  margin: "1.2rem 0",
  borderRadius: "0.6rem",
  display: "flex",
  background: colors.backgroundColor[10],
});

export const AddNotesBody = () => ({
  background: others.MainBackground[1],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.5rem 1.5rem 0 2rem",
  marginBottom: "0.8rem",
  paddingBottom: "1.2rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const SurveyJsContainer = () => ({
  background: others.MainBackground[1],
  opacity: 1,
  marginBottom: "0.8rem",
  borderRadius: "0.8rem",
  display: "flex",
});

export const VerticalDivider = () => ({
  margin: "0 1.2rem",
});

export const HeaderField = () => ({
  paddingLeft: "0.6rem",
});

export const HeaderActionField = () => ({
  paddingLeft: "1.5rem",
});

export const HorizontalDivider = () => ({
  margin: "1rem 0",
  minWidth: "100%",
  width: "95%",
});

export const DisciplineFieldTableCell = () => ({
  textAlign: "center",
  paddingTop: "0.4rem",
});

export const Header = () => ({
  width: "100%",
  paddingRight: "1.5rem",
  display: "flex",
  justifyContent: "space-between",
});

export const TableWidth = () => ({
  minWidth: "92%",
});

export const BodyTable = () => ({
  "& .MuiTableCell-root": {
    borderBottom: `0.05rem solid ${alpha(colors.black[7], 0.35)}`,
    borderRight: "none",
    borderLeft: "none",
  },
  "& th:first-of-type": {
    borderRadius: "0.5rem 0 0 0.5rem",
    borderBottom: "0",
  },
  "& th:last-child": {
    borderRadius: "0 0.5rem 0.5rem 0",
    borderBottom: "0",
  },
});

export const HeaderItem = () => ({
  backgroundColor: colors.backgroundColor[8],
  color: colors.black[2],
  fontWeight: fontWeight.Weight[4],
  borderRadius: "0.5rem",
  height: "1rem",
  position: "sticky",
  top: 0,
});

export const FaChevronCircleDownIcon = styled(FaChevronCircleDown)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const FaChevronCircleUpIcon = styled(FaChevronCircleUp)(() => ({
  color: colors.fonts[18],
  fontSize: "1.2rem",
  cursor: "pointer",
}));

export const FaRevertIcon = styled(FaUndo)(() => ({
  color: colors.black[1],
  margin: "0 0.47rem 0 0.47rem",
  fontSize: "0.8rem",
  cursor: "pointer",
}));
export const FaChevronDownIcon = styled(FaChevronDown)(() => ({
  color: colors.black[1],
  margin: "0 0rem 0 2rem",
  fontSize: "0.8rem",
  cursor: "pointer",
}));

export const GridTableContent = () => ({
  padding: "1rem 0 1rem 1rem",
});

export const DisciplineTablecell = () => ({
  paddingTop: "0.4rem",
  paddingRight: "1rem",
});

export const ModifyButton = () => ({
  height: "1.5rem",
  padding: "0.47rem 0 0.47rem 0",
  width: "10rem",
});

export const ResumeIntakeButton = () => ({
  height: "1.5rem",
  width: "8.5rem",
  padding: 0,
});
export const StatusDataButton = () => ({
  height: "1.5rem",
  width: "10.5rem",
  fontWeight: fontWeight.Weight[2],
  padding: ".47rem 0 .47rem 0rem",
  background: others.otherColors[71],
  color: colors.black[1],
});

export const CancelBtn = () => ({
  background: others.otherColors[33],
});

export const CancelBtnStyle = () => ({
  background: others.otherColors[33],
  width: "4.5rem",
  height: "1.5rem",
});

export const DateFormControl = () => ({
  marginRight: "2rem",
});

export const FormControlStyle = () => ({
  paddingBottom: "2rem",
});

export const ReceivedMethodsStyle = () => ({
  padding: "0 2rem 0 1rem"
})

export const IconBox = {
  color: colors.fonts[18],
  width: "1.8rem",
};

export const FaIconBox = {
  width: "0.7rem",
  height: "0.7rem",
  cursor: "pointer",
};

export const CounterDivider = () => ({
  margin: "0.5rem 0",
  width: "100%",
});

export const displayFlex = () => ({
  display: "flex",
});

export const FaEditStyle = {
  color: colors.fonts[18],
  cursor: "pointer",
};

export const TableMain = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    borderRight: "none",
    borderLeft: "none",
    paddingLeft: "0.5rem",
    paddingRight: "0.7rem",
  },
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderRight: "1rem",
  },
  "& th:last-of-type": {
    borderRadius: "0 0.7rem 0.7rem 0",
  },
}));

export const TableMainHead = styled(TableHead)(() => ({
  backgroundColor: colors.backgroundColor[8],
  borderBottom: "unset",
  padding: ".4rem 0 0 0",

  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderBottom: "none",
  },
  "& th:last-child": {
    borderRadius: "0 0.7rem 0.7rem 0",
    borderBottom: "none",
  },
}));

export const TableMainHeadRow = styled(TableRow)(() => ({
  height: "2rem",
}));

export const TableCellStickyLeftSecond = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  background: others.otherColors[33],
  zIndex: "1",
}));

export const TableCellStickyHeaderLeftSecond = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  backgroundColor: colors.backgroundColor[8],
}));

export const TableCellStickyHeaderLeft = styled(TableCell)(() => ({
  position: "sticky",
  width: "3rem",
  left: 0,
  backgroundColor: colors.backgroundColor[8],
  "& .MuiTableCell-root": {
    paddingLeft: "0.91rem",
  },
}));

export const TableCellStickyHeader = styled(TableCell)(() => ({
  borderBottom: "none",
  "&.MuiTableCell-root:last-child": {
    backgroundColor: colors.backgroundColor[8],
    position: "sticky",
    right: 0,
  },
}));

export const AlignCenter = {
  display: "flex",
  alignItems: "center",
};

export const ContainerStyle = {
  "&::-webkit-scrollbar": {
    width: "2rem",
    height: "0.2rem",
  },
  "&::-webkit-scrollbar-track": {
    width: 0.1,
    marginLeft: "6rem",
    marginRight: "50rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.black[7],
    borderRadius: 0.1,
    width: 0.1,
  },
};

export const Flex = () => ({
  display: "flex",
});

export const PatientTableCell = (removeBorder: boolean) => ({
  borderRight: 0,
  padding: 0,
  textAlign: "left",
  paddingRight: "1%",
  paddingLeft: "0rem",
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const VisitTableCell = (removeBorder: boolean) => ({
  borderRight: 0,
  padding: 0,
  textAlign: "center",
  paddingRight: "1%",
  paddingLeft: "0rem",
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const SortBox = () => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0.5rem",
  svg: {
    "&:first-of-type": {
      marginBottom: "-0.8rem",
    },
  },
});

export const TableCellSticky = styled(TableCell)(() => ({
  position: "sticky",
  right: 0,
  background: others.otherColors[33],
}));

export const TableCellStickyLeft = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  background: others.otherColors[33],
  zIndex: "1",
}));

export const TableNameDetails = (removeBorder: boolean) => ({
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
  textDecoration: "underline",
  textDecorationColor: colors.fonts[3],
  borderRight: 0,
  textAlign: "center",
  padding: 0,
});

export const ViewBoxStyle = {
  width: "auto",
  display: "flex",
  alignItems: "center",
};

export const checkBoxStyle = {
  "& span": {
    padding: "0rem ",
  },
};

export const CursorPointer = () => ({
  cursor: "pointer",
});

export const TableHeight = {
  height: "3rem",
};

export const DisplayServiceList = () => ({
  display: "flex",
  flexDirection: "row",
  minWidth: "max-content",
});

export const DropdownMenu = () => ({
  position: "absolute",
  display: "inline-block",
  width: "100%",
  "&:hover": {
    div: {
      display: "block",
    },
  },
});

export const DropdownContent = () => ({
  display: "none",
  margin: "0 1rem 0 1rem",
  padding: "0.5rem 1rem",
  background: others.otherColors[33],
  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
  borderRadius: "0.5rem",
  position: "absolute",
  zIndex: 1,
  a: {
    display: "block",
    textDecoration: "none",
    padding: "1rem 2rem 1rem 1rem",
    width: "100%",
    cursor: "auto",
  },
});
export const DropdownLink = () => ({
  display: "block",
  textDecoration: "none",
  padding: "1rem 2rem 1rem 1rem",
  width: "100%",
  cursor: "auto",
});

export const HeaderTitle = () => ({
  paddingTop: "0.3rem",
  paddingLeft: "1rem",
});

export const BackBtn = () => ({
  width: "4.5rem",
});

export const ServiceDetailsGridContainer = () => ({
  paddingTop: "0.5rem",
  paddingBottom: "1.5rem",
});

export const DateTimeGrid = () => ({
  paddingTop: "0.8rem",
});

export const DisciplineTypeGrid = () => ({
  paddingTop: "0.8rem",
  paddingLeft: "2rem",
});

export const DataGrid = () => ({
  paddingTop: "1rem",
});

export const UrgencyChangeGrid = () => ({
  paddingTop: "1rem",
  paddingLeft: "4rem",
});

export const UrgencyDateTimeChangeGrid = () => ({
  paddingTop: "1.5rem",
  paddingLeft: "1rem",
});

export const FaMinusIconGrid = () => ({
  paddingLeft: "1rem",
  paddingTop: "1.2rem",
});

export const RequestedVisitIcon = () => ({
  paddingLeft: "1rem",
  paddingTop: "1.2rem",
});

export const InputTextLabel = () => ({
  "& .MuiInputLabel-root": {
    display: "flex",
    marginLeft: "-2rem",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: "-2rem",
  },
});

export const SelectControl = () => ({
  margin: "0.7rem",
  minWidth: "6rem",
});

export const SelectControlRequest = () => ({
  margin: "0.7rem",
  minWidth: "5.1rem",
});

export const DescriptionStyle = () => ({
  margin: "0.7rem",
  minWidth: "100%",
  marginLeft: "-0.1rem",
  background: colors.white[1]
});

export const NotesDiagnosisStyle = () => ({
  minWidth: "100%",
  marginLeft: "0.8rem",
  paddingRight: "1rem",
});

export const RequestedVisits = () => ({
  marginTop: "-1.6rem",
  "& .MuiFormHelperText-root": {
    position: "absolute",
    width: "150%",
    top: "2.813rem",
  },
});

export const HeaderTableCell = () => ({
  borderBottom: "unset",
  "&.MuiTableCell-root:last-child": {
    borderBottom: "none",
  },
});

export const ReviewButton = {
  width: "5rem",
};

export const ServiceData = {
  paddingLeft: "0.4rem",
};

export const Action = {
  paddingLeft: "1rem",
};

export const TemplateGrid = () => ({
  paddingTop: "3.5rem",
});

export const TranslationText = () => ({
  paddingTop: "1rem",
});

export const BoxShadow = () => ({
  boxShadow: `0.1rem 0.1rem ${others.otherColors[72]}`,
  maxWidth: "17rem",
  pb: "0.2rem",
});

export const InruleTitle = () => ({
  paddingLeft: "1.2rem",
  paddingTop: "0.7rem",
});

export const ButtonGrid = () => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingRight: "0.5rem",
});

export const ServiceRequesterName = () => ({
  paddingTop: "0.8rem",
});
