import {
  Divider,
  Grid,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Controller,
  UseFormGetValues,
  UseFormResetField,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  DateFormControl,
  GridContainer,
  RequestHeader,
  VerticalDevider,
  ReceivedMethodBox,
  RequestDetailGrid,
  AttorneyDivider,
  AttorneyGrid,
  HealthPlanControl,
  ReceivedMethodRadio,
  AttorneyBox,
  RequiredSymbol,
} from "../styles/requestStyle";
import { rules } from "../../../../../utils/validation/Validation";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import {
  displayFlex,
  flexAlignCentre,
  flexRow,
} from "../../../../../styles/mui/styles/display";
import {
  ReceivedMethods,
  RelationshipMaster,
  PatientRequest,
  UrgencyStatusMaster,
  EmergencyContactTable,
} from "../../../../../models/Patient";
import {
  ReceivedMethodMasterResponse,
  UrgencyStatusMasterResponse,
  RelationshipMasterResponse,
} from "../../../../../models/Api/Patient";
import {
  fromProviderPortal,
  getValue,
  isNil,
  length,
  isExpedited,
  getList,
} from "../../../../../utils";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import {
  AuthorizationTypeMasterResponse,
  CareCoordinationProgramResponse,
  HealthProgramResponse,
  PriorAuthReferralSourceMasterResponse,
} from "../../../../../models/Api/Master";
import {
  CareCoordinationProgram,
  HealthProgram,
} from "../../../../../models/Master";
import {
  RadioButton,
  EmergencyContactOption,
} from "../../../../../constants/AllPatientRecord";
import { FormInputTimePicker } from "../../../../../components/formComponents/FormInputTimePicker";
import { ServiceRequestType } from "../../../../../constants/Master";
import { DropdownOption } from "../../../../../components/formComponents/constant/FormComponents";
import EmergencyContactData from "../../../../../components/custom/emergencyContact/EmergencyContact";
import {
  EmergencyContactTypes,
  REQUEST_FACILITY_NAME,
} from "../../../../../constants/Constants";
import { MasterEndPointValues } from "../../../../../constants/MasterValues";

export interface PropsFromState {
  receivedMethodData: ReceivedMethodMasterResponse;
  urgencyStatus: UrgencyStatusMasterResponse;
  relationshipData: RelationshipMasterResponse;
  values: PatientRequest;
  control: any;
  setValue: (id: any, value: any, boolean: any) => void;
  fields: any[];
  append: any;
  remove: (id: number) => void;
  healthProgram: HealthProgramResponse;
  careCoordination: CareCoordinationProgramResponse;
  emergencyContactValues: any;
  clearErrors: (name: string) => void;
  useWatch?: any;
  watch: UseFormWatch<any>;
  trigger: UseFormTrigger<any>;
  getValues: UseFormGetValues<any>;
  patientRequestDetail: PatientRequest | any;
  AuthorizationType: AuthorizationTypeMasterResponse;
  priorAuthReferralSourceResponse: PriorAuthReferralSourceMasterResponse;
  resetField: UseFormResetField<any>;
}

export interface PropsFromDispatch {
  getReceivedMethodDetails: (serviceRequestTypeId: number) => void;
  getUrgencyStatusMasterResp: () => void;
  getRelationshipMasterResp: () => void;
  getHealthProgram: () => void;
  getCareCoordinationProgram: () => void;
  getAuthorizationTypeMaster: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const RequestDetail: React.FC<AllProps> = ({
  getReceivedMethodDetails,
  getUrgencyStatusMasterResp,
  getRelationshipMasterResp,
  receivedMethodData,
  urgencyStatus,
  relationshipData,
  values,
  control,
  fields,
  append,
  remove,
  setValue,
  getHealthProgram,
  getCareCoordinationProgram,
  healthProgram,
  careCoordination,
  emergencyContactValues,
  clearErrors,
  useWatch,
  getValues,
  watch,
  trigger,
  patientRequestDetail,
  getAuthorizationTypeMaster,
  AuthorizationType,
  priorAuthReferralSourceResponse,
  resetField,
}: AllProps) => {
  const [receivedMethod, setReceivedMethod] = useState<
    ReceivedMethods[] | any[]
  >([]);
  const [urgencyStatusList, setUrgencyStatusList] = useState<
    UrgencyStatusMaster[] | any[]
  >([]);
  const [relationshipList, setRelationshipList] = useState<
    RelationshipMaster[] | any[]
  >([]);
  const [emergencyContactRelationid, setEmergencyContactRelationid] = useState<
    EmergencyContactTable[] | any[]
  >([]);

  const { loading: loadingReceivedMethod, response: receviedMethodResponse } =
    receivedMethodData;
  const { loading: loadingUrgencyStatus, response: urgencyStatusResponse } =
    urgencyStatus;
  const { loading: loadingRelationship, response: relationshipResponse } =
    relationshipData;
  const { loading: loadingHealthProgram, response: healthProgramResponse } =
    healthProgram;
  const {
    loading: loadingcareCoordination,
    response: careCoordinationResponse,
  } = careCoordination;
  const {
    loading: loadingAuthorizationType,
    response: AuthorizationTypeResponse,
  } = AuthorizationType;

  const { response: ReferralSourceResponse } = priorAuthReferralSourceResponse;

  useEffect(() => {
    if (!loadingReceivedMethod) {
      getReceivedMethodDetails(ServiceRequestType.AUTH_ID);
    }
    if (!loadingUrgencyStatus) {
      getUrgencyStatusMasterResp();
    }
    if (!loadingRelationship) {
      getRelationshipMasterResp();
    }
    if (!loadingHealthProgram) {
      getHealthProgram();
    }
    if (!loadingcareCoordination) {
      getCareCoordinationProgram();
    }
    if (!loadingAuthorizationType) {
      getAuthorizationTypeMaster();
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && name.includes(".relationId") && type === "change") {
        const index = parseInt(name.split(".")[1]);
        if (relationshipList && typeof index === "number") {
          const relationship = relationshipList.find(
            (relationship) =>
              relationship.relationId === parseInt(getValue(value, name))
          );
          if (relationship) {
            setValue(
              `emergencyContact.${index}.relationshipName`,
              relationship.relationshipName,
              true
            );
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, relationshipList]);

  useEffect(() => {
    const updatedList = fields.map((element) => ({
      id: fields.indexOf(element),
      relationshipId: element.relationId,
    }));

    setEmergencyContactRelationid(updatedList);
  }, [fields]);

  useEffect(() => {
    setReceivedMethod(receviedMethodResponse || []);

    const urgencyStatus =
      urgencyStatusResponse &&
      length(urgencyStatusResponse) &&
      urgencyStatusResponse.map((item) => {
        return {
          label: item.urgencyStatus,
          value: item.urgencyId,
        };
      });

    setUrgencyStatusList(urgencyStatus || []);
    setRelationshipList(relationshipResponse || []);
  }, [receviedMethodResponse, urgencyStatusResponse, relationshipResponse]);

  useEffect(() => {
    if (
      isNil(patientRequestDetail && patientRequestDetail.referralDetail) &&
      AuthorizationTypeResponse &&
      length(AuthorizationTypeResponse) &&
      AuthorizationTypeResponse[0].authorizationTypeName != ""
    ) {
      const { authorizationTypeId } = AuthorizationTypeResponse[0];
      setValue("referralDetail.authorizationTypeId", authorizationTypeId, true);
    }
  }, [patientRequestDetail, AuthorizationTypeResponse]);

  useEffect(() => {
    if (
      isNil(patientRequestDetail && patientRequestDetail.referralDetail) &&
      urgencyStatusResponse &&
      length(urgencyStatusResponse) &&
      urgencyStatusResponse[0].urgencyStatus != ""
    ) {
      const { urgencyId } = urgencyStatusResponse[0];
      setValue("referralDetail.urgencyId", urgencyId, true);
    }
  }, [patientRequestDetail, urgencyStatusResponse]);

  const onChange = (e: any) => {
    setValue(e.target.name, e.target.value, true);
    const recMethod =
      receivedMethod &&
      length(receivedMethod) &&
      receivedMethod.filter((item) => {
        return item.receivedMethodId == e.target.value;
      });
    setValue(
      "referralDetail.receivedMethodName",
      getValue(recMethod, "[0].receivedMethodName", ""),
      true
    );
    clearErrors(e.target.name);
  };

  const referralDetail = useWatch({
    name: "referralDetail",
    control,
  });

  useEffect(() => {
    const urgency =
      urgencyStatusResponse &&
      length(urgencyStatusResponse) &&
      values &&
      values.referralDetail &&
      values.referralDetail.urgencyId
        ? urgencyStatusResponse.filter((item) => {
            return item.urgencyId == values.referralDetail.urgencyId;
          })
        : [];
    setValue(
      "referralDetail.urgencyStatus",
      urgency && length(urgency) ? urgency[0].urgencyStatus : "",
      true
    );
  }, [referralDetail && referralDetail.urgencyId]);

  const handleRemove = (index: any) => {
    remove(index);
    setEmergencyContactRelationid(
      emergencyContactRelationid.filter(
        (id) =>
          id.relationshipId !==
          getValue(values, `emergencyContact.${index}.relationId`)
      )
    );
  };

  const healthProgramList = (
    healthProgramResponse: HealthProgram[] | undefined
  ): DropdownOption[] => {
    if (healthProgramResponse && length(healthProgramResponse)) {
      return healthProgramResponse.map((healthProgram) => ({
        label: healthProgram.healthProgramName,
        value: healthProgram.healthProgramId,
      }));
    }
    return [];
  };

  const careCoordinationList = (
    careCoordinationResponse: CareCoordinationProgram[] | undefined
  ): DropdownOption[] => {
    if (careCoordinationResponse && length(careCoordinationResponse)) {
      return careCoordinationResponse.map((careCoordination) => ({
        label: careCoordination.careProgramName,
        value: careCoordination.careProgramId,
      }));
    }
    return [];
  };

  const checkPrivateDuty = (AuthTypeId: number) => {
    if (AuthorizationTypeResponse && length(AuthorizationTypeResponse)) {
      const authStatusObject = AuthorizationTypeResponse.find(
        (item) => item.authorizationTypeId === AuthTypeId
      );
      if (authStatusObject) {
        return authStatusObject.isPrivateDuty;
      }
    }
  };

  const handleAuthTypeId = (event: any) => {
    const checkIsPrivateDuty = checkPrivateDuty(event.target.value);
    if (checkIsPrivateDuty === true) {
      const getHealthPlan = ReferralSourceResponse.find(
        (data: any) => data.facilityTypeId === MasterEndPointValues.RequestFacilityTypeId
      );
      setValue(
        "referralSource.facilityTypeId",
        getValue(getHealthPlan, "facilityTypeId", 0),
        true
      );
      setValue("referralSource.facilityName", REQUEST_FACILITY_NAME, true);
      setValue(
        "requester.facilityTypeId",
        getValue(getHealthPlan, "facilityTypeId", 0),
        true
      );
      setValue("requester.facilityName", REQUEST_FACILITY_NAME, true);
    } else {
      resetField("referralSource.facilityTypeId");
      resetField("referralSource.facilityName");
      resetField("requester.facilityName");
      resetField("requester.facilityTypeId");
    }
  };

  return (
    <Grid pb={"2rem"}>
      <Typography
        variant="subtitle1"
        fontWeight={fontWeight.Weight[5]}
        color={colors.fonts[20]}
        sx={RequestHeader}
      >
        REQUEST DETAILS:
      </Typography>
      <Divider orientation="horizontal" flexItem />
      <Grid container spacing={"3rem"} sx={GridContainer}>
        <Grid item xs={2.2}>
          <FormControl variant="standard" sx={DateFormControl}>
            <DatePicker
              name="referralDetail.receivedDateTime"
              control={control}
              label={"Date Request Received:"}
              helper={rules.dateTimeRequired}
              allowFutureDate={false}
            />
          </FormControl>
        </Grid>
        <Grid item sx={displayFlex} xs={2.5}>
          <FormControl variant="standard" fullWidth>
            <FormInputTimePicker
              name="referralDetail.receivedDateTime"
              control={control}
              setValue={setValue}
              label="Time Request Received:"
              helper={rules.timeRequired}
              value={getValues("referralDetail.receivedDateTime")}
              watch={watch}
              trigger={trigger}
              clearErrors={clearErrors}
              allowFutureTime={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={0.5} sx={displayFlex}>
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item sx={displayFlex} xs={2.2}>
          <FormControl variant="standard" fullWidth>
            <FormInputDropdown
              name="referralDetail.urgencyId"
              control={control}
              label="Choose Urgency Status:"
              helper={rules.urgencyStatus}
              list={urgencyStatusList}
              InputStyle={isExpedited(getValues("referralDetail.urgencyId"))}
            />
          </FormControl>
        </Grid>
        <Grid item xs={0.5} sx={displayFlex}>
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={3} sx={{ display: "none" }}> {/* tbd - will be removed via 9072 */}
          <Box mb={"1rem"}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[3]}
              color={colors.black[13]}
            >
              Is the Patient Homebound ?
            </Typography>
          </Box>
          <Controller
            defaultValue=""
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="controlled-radio-buttons-group"
                sx={flexRow}
                {...field}
              >
                {RadioButton &&
                  RadioButton.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="subtitle2"
                          color={colors.fonts[4]}
                          fontWeight={fontWeight.Weight[4]}
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  ))}
              </RadioGroup>
            )}
            name={`referralDetail.isHomeBound`}
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" fullWidth>
            <FormInputDropdown
              name="referralDetail.authorizationTypeId"
              control={control}
              label="Authorization Type:"
              helper={rules.authType}
              list={getList(AuthorizationTypeResponse, {
                label: "authorizationTypeName",
                value: "authorizationTypeId",
              })}
              onChangeHandler={(e: any) => handleAuthTypeId(e)}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Divider orientation="horizontal" flexItem />
      <Grid item xs={12}>
        <Grid container sx={RequestDetailGrid} spacing={3}>
          <Grid item xs={4}>
            <Box sx={ReceivedMethodBox}>
              <Box sx={displayFlex}>
                <Typography
                  variant="subtitle2"
                  color={colors.fonts[20]}
                  fontWeight={fontWeight.Weight[5]}
                >
                  Choose Received Methods
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={colors.red[100]}
                  sx={RequiredSymbol}
                >
                  *
                </Typography>
              </Box>
              <Typography
                variant="body1"
                color={others.otherColors[65]}
                fontWeight={fontWeight.Weight[2]}
                mt={"0.5rem"}
              >
                Please choose the mode through which the referral has been
                received.
              </Typography>
            </Box>
            <Divider orientation="horizontal" flexItem />
            <Controller
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <>
                  <RadioGroup
                    aria-labelledby="controlled-radio-buttons-group"
                    row={true}
                    sx={ReceivedMethodRadio}
                    {...field}
                    onChange={onChange}
                  >
                    {receivedMethod &&
                      length(receivedMethod) &&
                      receivedMethod
                        .filter(
                          (singleOption: ReceivedMethods) =>
                            !singleOption.isIntegrationMethod ||
                            fromProviderPortal(
                              singleOption,
                              values,
                              "referralDetail.receivedMethodId"
                            )
                        )
                        .map((singleOption: ReceivedMethods) => (
                          <Grid
                            item
                            xs={6}
                            key={singleOption.receivedMethodId}
                            pb={"1.2rem"}
                          >
                            <FormControlLabel
                              value={Number(singleOption.receivedMethodId)}
                              label={
                                <Box sx={flexAlignCentre}>
                                  <Typography
                                    variant="subtitle2"
                                    color={colors.black[2]}
                                    fontWeight={fontWeight.Weight[5]}
                                  >
                                    {singleOption.receivedMethodName}
                                  </Typography>
                                </Box>
                              }
                              control={<Radio />}
                            />
                          </Grid>
                        ))}
                  </RadioGroup>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </>
              )}
              name={`referralDetail.receivedMethodId`}
              control={control}
              rules={rules.receivedMethod}
            />
          </Grid>
          <Grid item xs={0.5} sx={displayFlex}>
            <Divider orientation="vertical" flexItem sx={VerticalDevider} />
          </Grid>
          <Grid item xs={7.5}>
            <EmergencyContactData
              fields={fields}
              emergencyContactValues={emergencyContactValues}
              control={control}
              relationshipList={getList(
                relationshipList,
                EmergencyContactOption
              )}
              handleRemove={handleRemove}
              append={append}
              contactType={EmergencyContactTypes.REQUEST_EMERGENCY_CONTACT}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" flexItem />
      <Grid container sx={AttorneyBox} spacing={"1.5rem"}>
        <Grid item xs={6}>
          <Box sx={flexAlignCentre}>
            <Typography
              variant="body1"
              color={colors.fonts[4]}
              fontWeight={fontWeight.Weight[5]}
            >
              Power of Attorney:
            </Typography>

            <Divider orientation="vertical" flexItem sx={AttorneyDivider} />

            <Typography
              variant="body2"
              color={others.otherColors[65]}
              fontWeight={fontWeight.Weight[2]}
            >
              Add details below based on the letter received.
            </Typography>
          </Box>

          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField
                  variant="standard"
                  {...field}
                  value={field.value || ""}
                  fullWidth
                  placeholder="Add here..."
                />
                <FormHelperText
                  sx={{
                    color: colors.red[100],
                    position: "absolute",
                  }}
                >
                  {error ? error.message : ""}
                </FormHelperText>
              </>
            )}
            name={`referralDetail.powerOfAttorney`}
            control={control}
            rules={rules.powerOfAttorney}
          />
        </Grid>

        <Grid item xs={0.5} sx={displayFlex}>
          <Divider orientation="vertical" flexItem />
        </Grid>

        <Grid item xs={4} sx={AttorneyGrid}>
          <FormControl fullWidth variant="standard" sx={HealthPlanControl}>
            <Typography
              variant="body1"
              fontWeight={fontWeight.Weight[2]}
              color={colors.fonts[2]}
            >
              Health Plan Program:
            </Typography>
            <FormInputDropdown
              name={`referralDetail.healthProgramId`}
              control={control}
              list={healthProgramList(healthProgramResponse)}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <Typography
              variant="body1"
              fontWeight={fontWeight.Weight[2]}
              color={colors.fonts[2]}
            >
              Care Coordination Program:
            </Typography>
            <FormInputDropdown
              name={`referralDetail.careProgramId`}
              control={control}
              list={careCoordinationList(careCoordinationResponse)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestDetail;
