import { useEffect, useState } from "react";
import { isEmpty, isNil, isNull } from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";

import {
  BottomGrid,
  Gridbody,
  Header,
  HeaderTitle,
  HorizontalDivider,
} from "../styles/ServiceProviderDetails";
import { colors } from "../../../../../styles/colors";
import { displayFlex, flexAllCentre } from "../../../../../styles/mui/styles/display";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AutoComplete,
  ChipGridItem,
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  GridbodyInfo,
  RemainingChar,
    ShowIcon,
} from "../styles/AgencyCommunication";
import {
  CategoryStatus,
  IconFaInfoAlt,
} from "../../../../AdminCenter/routes/NoteManagement/styles/NotesList";
import {
  ButtonGrid,
  CancelBtn,
  DescriptionStyle,
} from "../styles/Disciplines";
import {
  getValue,
  length,
  messageForInputCharLength,
  validInputTextLength,
} from "../../../../../utils";
import {
  ClearButton,
  ClearButtonGrid,
  InputCloseIcon,
  SelectedOption,
} from "../../../../MyPatients/styles/style";
import { AgencyCommunicaton } from "../../../../../models/Master";
import { AgencyCommunicationNote } from "../../../../../models/Notes";
import { PatientSearchLabel } from "../../../../AdminCenter/routes/UserManagement/styles/userProfile";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { AgencyCommunicationNoteTextLength } from "../../../../../constants/Notes";
import { rules } from "../../../../../utils/validation/Validation";
import AutocompleteMultiCheckInput from "../../../../../components/formComponents/AutocompleteMultiCheckInput";
import { AgencyCommunicationNoteResponse } from "../../../../../models/Api/Notes";

export interface PropsFromDispatch {
  getAgencyCommunicationMaster: () => void;
  postAgencyCommunicationNote: (payload: AgencyCommunicationNote) => void;
}
export interface PropsFromState {
  agencyCommunicationTemplates: AgencyCommunicaton[];
  agencyCommunicationNoteResponse: AgencyCommunicationNoteResponse;
}
type AllProps = PropsFromDispatch & PropsFromState;

const AgencyCommunication: React.FC<AllProps> = ({
  getAgencyCommunicationMaster,
  postAgencyCommunicationNote,
  agencyCommunicationTemplates,
  agencyCommunicationNoteResponse,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<
    AgencyCommunicaton[] | any
  >([]);
  const [inputValue, setInputValue] = useState("");
  const [remainingChar, setRemainingChar] = useState<number>(0);
  const [disableButtons, setDisableButtons] = useState<boolean>(true);
  const [showContainer, setShowContainer] = useState<boolean>(false);
  const { authNo } = useParams();

  const methods = useForm<any>({
    mode: "all",
  });

  const { control, watch, setValue, handleSubmit, clearErrors } = methods;

  useWatch({
    name: "comment",
    control,
  });

  useEffect(() => {
    closeContainer();
    getAgencyCommunicationMaster();
  }, []);

  useEffect(() => {
    if (agencyCommunicationNoteResponse.response) {
      closeContainer();
    }
  }, [agencyCommunicationNoteResponse]);

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (!isNil(value) && !isNil(name)) {
        const agencyCommunicationText = getValue(value, "comment");
        if (
          !isNull(agencyCommunicationText) &&
          agencyCommunicationText.length >= 0
        ) {
          setRemainingChar(agencyCommunicationText.length);
        }
        setInputValue(agencyCommunicationText);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data: AgencyCommunicationNote) => {
    postAgencyCommunicationNote({
      referralId: Number(authNo),
      noteTemplateId: 0,
      comment: data.comment,
      userCreated: null,
      fullNameCreated: null,
    });
  };

  const handleChange = (_e: any, value: AgencyCommunicaton[]) => {
    if (isEmpty(value)) {
      setSelectedOptions([]);
    }
    if (!isEmpty(value)) {
      const selectedValue = value || [];
      const newOptions = selectedValue.filter(
        (option: AgencyCommunicaton) => !selectedOptions.includes(option)
      );
      setSelectedOptions(value);
      if (
        newOptions.length > 0 &&
        validInputTextLength(AgencyCommunicationNoteTextLength, remainingChar)
      ) {
        setInputValue(
          (prevValue) =>
            prevValue +
            (prevValue ? "\n" : "") +
            newOptions
              .map((option: AgencyCommunicaton) => option.templateDescription)
              .join("\n")
        );
        setDisableButtons(false);
      } else {
        if (!isEmpty(selectedValue)) {
          const note = selectedValue
          .map((option: AgencyCommunicaton) => option.templateDescription)
          .join("\n");
          const inputLength = note.length;
          setInputValue(note);
          resetTextInputValue(inputLength, note);
        } else {
          setInputValue("");
        }
        setDisableButtons(true);
        clearInputFieldError();
      }
    } else {
      setInputValue("");
      setDisableButtons(true);
    }
  };

  const handleDelete = (opt: AgencyCommunicaton) => {
    setInputValue("");
    const updatedOptions = selectedOptions.filter(
      (option: AgencyCommunicaton) => option.templateId !== opt.templateId
    );
    setSelectedOptions(updatedOptions);
    handleChange("", updatedOptions);
  };

  const handleClear = () => {
    setSelectedOptions([]);
    setInputValue("");
    setDisableButtons(true);
    clearInputFieldError();
    setValue("comment", "");
  };

  const resetTextInputValue = (inputLength: number, note: string) => {
    if (inputLength <= AgencyCommunicationNoteTextLength) {
      setValue(
        "comment",
        note
      );
    }
  } 

  const closeContainer = () => {
    setShowContainer(true);
    setDisableButtons(true);
    clearInputFieldError();
    handleClear();
  };

  const clearInputFieldError = () => {
    clearErrors("comment");
  };

  const handleDescription = (event: any, label: string) => {
    setValue(label, event.target.value);
  };

  useEffect(() => {
    if (
      validInputTextLength(AgencyCommunicationNoteTextLength, remainingChar)
    ) {
      setValue("comment", inputValue);
      clearInputFieldError();
    }
    if (inputValue.length > 0) {
      setDisableButtons(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (showContainer && inputValue.length === 0) {
      setDisableButtons(true);
    }
    if (!showContainer) {
      setValue("comment", "");
      setInputValue("");
      setDisableButtons(true);
      clearInputFieldError();
    }
  }, [showContainer]);

  const header = () => {
    return (
      <Box sx={Header}>
        <Box sx={displayFlex}>
          {showContainer ? (
            <FaChevronCircleDownIcon
              onClick={() => setShowContainer(false)}
              sx={ShowIcon}
            />
          ) : !showContainer ? (
            <FaChevronCircleUpIcon
              onClick={() => setShowContainer(true)}
              sx={ShowIcon}
            />
          ) : (
            ""
          )}
          <Typography
            variant="subtitle1"
            color={colors.fonts[20]}
            fontWeight={fontWeight.Weight[5]}
            sx={HeaderTitle}
          >
            Agency Communication:
          </Typography>
        </Box>
      </Box>
    );
  };

  const autocompleteBox = () => {
    return (
      <Grid container>
        <Grid item xs={4} sx={CategoryStatus}>
          <Grid item xs={12}>
            <Box sx={PatientSearchLabel}>
              <Typography
                variant="subtitle2"
                color={colors.fonts[2]}
                fontWeight={fontWeight.Weight[3]}
              >
                Choose Template:
              </Typography>
            </Box>
            <AutocompleteMultiCheckInput
              options={agencyCommunicationTemplates}
              sx={AutoComplete}
              handleChange={handleChange}
              value={selectedOptions}
              control={control}
              name={"agencyCommunicationTemplate"}
              labelKey="templateName"
            />
          </Grid>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={7.5}>
          <Grid container sx={GridbodyInfo}>
            <Grid item xs={0.8} sx={flexAllCentre}>
              <IconFaInfoAlt />
            </Grid>
            <Grid item xs={11.2}>
              <Typography
                variant="h6"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[5]}
              >
                INFO: Information typed in this box will be added to the
                communication sent in the authorization notification.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const chipGrid = () => {
    return (
      <Grid container>
        {!isEmpty(selectedOptions) &&
          length(selectedOptions) &&
          selectedOptions.map((item: AgencyCommunicaton, index: number) => {
            return (
              <Grid item xs={"auto"} key={index}>
                <Box sx={SelectedOption(4)}>
                  <Typography
                    color={colors.fonts[18]}
                    fontWeight={fontWeight.Weight[5]}
                    variant="body2"
                  >
                    {item.templateName}
                  </Typography>
                  <Typography
                    color={colors.fonts[18]}
                    fontWeight={fontWeight.Weight[6]}
                    ml={"0.1rem"}
                  >
                    <IoIosClose
                      onClick={() => handleDelete(item)}
                      size="19"
                      style={InputCloseIcon}
                    />
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        {!isEmpty(selectedOptions) && length(selectedOptions) && (
          <Grid item xs={"auto"} sx={ClearButtonGrid}>
            <Button variant="outlined" onClick={handleClear} sx={ClearButton}>
              CLEAR
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const agencyNoteInputBox = () => {
    return (
      <Grid item xs={12} sx={CategoryStatus}>
        <Grid item xs={12}>
          <FormControl sx={DescriptionStyle} variant="standard">
            <FormInputText
              name="comment"
              control={control}
              label="Add Comment"
              inputVariant="outlined"
              inputRows={5}
              multiline={true}
              helper={rules.maxLengthAgencyNoteDescription}
              handleChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleDescription(event, "comment")}
              textLength={AgencyCommunicationNoteTextLength}
            />
          </FormControl>
        </Grid>
        <Typography
          textAlign="right"
          variant="body2"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[5]}
          sx={RemainingChar}
        >
          {messageForInputCharLength(
            AgencyCommunicationNoteTextLength,
            remainingChar
          )}
        </Typography>
      </Grid>
    );
  };

  const footer = () => {
    return (
      <Box sx={displayFlex}>
        <Button
          sx={CancelBtn}
          onClick={() => {
            closeContainer();
            setDisableButtons(false);
          }}
        >
          CANCEL
        </Button>
        <Box pl={"1.5rem"}>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={disableButtons === true ? true : false}
          >
            SAVE
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Grid container sx={Gridbody}>
        <Grid item xs={12}>
          {header()}
        </Grid>
        <Divider sx={HorizontalDivider} />
        {!showContainer && (
          <Grid container sx={BottomGrid}>
            <Grid item xs={12} p={2}>
              {autocompleteBox()}
              <Grid item xs={12} sx={ChipGridItem}>
                {chipGrid()}
              </Grid>
              <Grid container>{agencyNoteInputBox()}</Grid>
              <Grid item xs={12} sx={ButtonGrid}>
                {footer()}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AgencyCommunication;
