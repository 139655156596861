import { Grid } from "@mui/material";
import { styled } from "@mui/system";

import { colors } from "../../../../../styles/colors";

export const FlexBox = () => ({
  display: "flex",
  flexDirection: "row",
});

export const HeaderBox = () => ({
  paddingLeft: "2.5rem",
});

export const SubTitle = () => ({
  paddingTop: "1rem",
});
export const DocSubTitle = () => ({
  opacity: 0.6,
  paddingTop: "1rem",
});
export const DividerStyle = {
  marginBottom: "0.97rem",
  marginTop: "1.24rem",
};

export const OptionBox = () => ({
  paddingBottom: "1rem",
  paddingTop: "0.8rem",
});

export const QuestionTheoryNotes = () => ({
  paddingTop: "0.5rem",
  paddingRight: "1.6rem",
});

export const FormLabelSpace = () => ({
  paddingRight: "1rem",
});

export const ContentGrid = () => ({
  paddingLeft: "2.5rem",
  paddingTop: "1.4rem",
  paddingBottom: "1.5rem",
});

export const AddDocumentGrid = () => ({
  paddingTop: "2rem",
});

export const AddDocumentSubtitle = () => ({
  paddingBottom: "1rem",
  paddingTop: "1rem",
});
export const ContentBox = () => ({
  paddingTop: "1.4rem",
});

export const Question = () => ({
  paddingTop: "0.5rem",
});

export const SupportingDocsOption = () => ({
  paddingBottom: "1.2rem",
  paddingTop: "0.7rem",
  paddingLeft: "0.2rem",
});

export const DateBox = () => ({
  paddingTop: "1.5rem",
  paddingLeft: "0.2rem",
  paddingBottom: "1.5rem",
});

export const OptionProgressNoteDocBox = () => ({
  paddingTop: "1rem",
  paddingBottom: "1rem",
});

export const FileData = () => ({
  paddingTop: "0.6rem",
});

export const FileDataGrid = () => ({
  paddingTop: "2rem",
  paddingLeft: "4rem",
});

export const FileUploadBox = () => ({
  paddingLeft: "0.8rem",
  display: "flex",
});

export const SwitchBox = () => ({
  paddingTop: "2.5rem",
  paddingLeft: "3.5rem",
  paddingBottom: "2.5rem",
});

export const FileTitle = () => ({
  paddingTop: "1.5rem",
  paddingLeft: "1.2rem",
});

export const SwitchText = () => ({
  paddingLeft: "1.2rem",
});

export const FileHeaderGrid = () => ({
  paddingTop: "1.5rem",
  paddingBottom: "0.5rem",
  display: "contents",
});

export const InfoIcon = () => ({
  fontSize: "1.2rem",
  paddingLeft: "2.5rem",
  paddingTop: "0.3rem",
  paddingRight: "1rem",
  color: colors.fonts[11],
});

export const GridForDocument = styled(Grid)(() => ({
  textAlign: "left",
  paddingBottom: "0.5rem",
}));

export const DocumentBox = () => ({
  display: "flex",
  flexDirection: "row",
});

export const PdfIcon = () => ({
  fontSize: "1.3rem",
  color: colors.fonts[11],
  paddingBottom: "0.5rem",
  paddingLeft: "1.5rem",
});

export const ReferralDocumentBox = () => ({
  width: "14rem",
  paddingLeft: "2rem",
  paddingTop: "0.25rem",
  textDecoration: "underline",
});

export const DeleteIcon = () => ({
  fontSize: "0.9rem",
  color: colors.fonts[11],
  paddingBottom: "0.5rem",
  paddingLeft: "2rem",
});

export const BackButton = () => ({
  color: colors.primary["main"],
  background: colors.black[7],
});
