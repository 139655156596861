import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import { MyPatientFilterDispatchTypes } from "../models/DispatchTypes/Filter";
import { MyPatientFilterActionDispatchTypes } from "../constants/Filter";
import { FilterModel } from "../models/Api/Filter";

export const storeFilterPayload: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    MyPatientFilterDispatchTypes
  >
> = ( type: number, payload?: FilterModel) => {
  return async (dispatch: Dispatch) => {
    switch (type) {
      case 0:
        dispatch({
          response: payload,
          type: MyPatientFilterActionDispatchTypes.MY_PATIENT_FILTER_RESET,
        });
        break;
      case 1:
        dispatch({
          response: payload,
          type: MyPatientFilterActionDispatchTypes.MY_PATIENT_FILTER_UPDATE,
        });
        break;
      default:
        break;
    }
  };
};
