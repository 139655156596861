import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { ReceivedMethodRadio } from "../styles/requestStyle";
import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { rules } from "../../../../../utils/validation/Validation";
import { PhoneFaxExtInput } from "../../../../../components/formComponents/PhoneFaxExtInput";
import {
  DescriptionStyle,
  NotesDisciplineGridbody,
  FaxGridBody,
} from "../../AuthorizationDetails/styles/Disciplines";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  ButtonFormControlFax,
  CancelButton,
  SendFaxDocumentTypeIcon,
  SendFaxFeilds,
  SendFaxHeading,
  SenFaxGridbody,
} from "../styles/DocumentReview";
import { colors } from "../../../../../styles/colors";
import {
  FileHeaderGrid,
  FileUploadBox,
  FlexBox,
  FormLabelSpace,
} from "../styles/Document";
import FileUpload from "../../../../../components/formComponents/FileUpload";
import { FaxRecipientMaster } from "../../../../../models/Master";
import { InitiateFaxSendingProcess } from "../../../../../models/Authorization";
import { PostInitiateFaxActionDispatchTypes } from "../../../../../constants/Authorization";
import { UserDetailsPayload } from "../../../../../models/User";
import {
  inputType,
} from "../../../../../constants/Constants";
import { extractDocType, getValue, isNil, length } from "../../../../../utils";
import {
  DocumentTabType,
  faxDefaultValues,
} from "../../../../../constants/Document";
import { InitiateFaxSendingResponse } from "../../../../../models/Api/Authorization";
import { getDocTypeIcon } from "../../../../../utils/DocIcons";
import { documentFaxMaxSize, documentMaxSize, documentMinSize } from "../../../../../constants/Config";
import { BlobData } from "../../../../../models/Document";

export interface DocSendFaxProps {
  saveInitiateFax: (payload: FormData) => void;
  faxProviderMasterResponse: FaxRecipientMaster[];
  setOpenFax: React.Dispatch<React.SetStateAction<boolean>>;
  openFax: boolean;
  user: UserDetailsPayload;
  resetStates: (actionType: string[]) => void;
  faxFiles: any;
  setFileForFax: React.Dispatch<React.SetStateAction<any>>;
  isEdit: boolean;
  faxSendResponse: InitiateFaxSendingResponse;
  showSenderType: boolean;
  faxBlobId: any;
  addDocForFax: boolean;
  setAddDocForFax: React.Dispatch<React.SetStateAction<boolean>>;
  setEditFaxDocName: React.Dispatch<React.SetStateAction<string>>;
  editFaxDocName: string;
  editFaxDocMediaType: string;
  setEditFaxDocMediaType: React.Dispatch<React.SetStateAction<string>>;
  faxRef: any;
  setSelectedFaxBlobIds: React.Dispatch<React.SetStateAction<BlobData[]>>;
  selectedFaxBlobIds: BlobData[];
}

type AllProps = DocSendFaxProps;

const DocumentSendFax: React.FC<AllProps> = ({
  saveInitiateFax,
  faxProviderMasterResponse,
  setOpenFax,
  resetStates,
  faxFiles,
  setFileForFax,
  isEdit,
  faxSendResponse,
  showSenderType,
  faxBlobId,
  addDocForFax,
  setAddDocForFax,
  editFaxDocName,
  editFaxDocMediaType,
  faxRef,
  setSelectedFaxBlobIds,
  selectedFaxBlobIds,
}) => {
  const [faxProvider, setFaxProvider] = useState<FaxRecipientMaster[]>([]);
  const maxFileSizeInMegaBytes = documentMaxSize.split("MB")[0];
  const minFileSize = Number(documentMinSize.split("KB")[0]);
  const maxFileSizeInBytes = Number(maxFileSizeInMegaBytes) * 1000000;
  const maxFileSizeInMB = maxFileSizeInBytes
    ? maxFileSizeInBytes / 1000000
    : 1000000;
  const { authNo } = useParams();
  const { response: DocSentResponse } = faxSendResponse;

  const faxMethods = useForm<any>({
    defaultValues: faxDefaultValues,
    mode: "all",
  });

  const { handleSubmit: faxHandleSubmit, control: faxControl } = faxMethods;

  const addNewDocumentforFax = () => {
    setFileForFax([]);
    setAddDocForFax(true);
  };

  useEffect(() => {
    setFaxProvider(faxProviderMasterResponse);
  }, [faxProviderMasterResponse]);

  const handleSendFax = (data: InitiateFaxSendingProcess) => {
    const formData = new FormData();
    formData.append("referralFaxParameterId", "0");
    formData.append("referralId", authNo ? authNo : "");
    formData.append("referenceId", "");
    formData.append(
      "blobIdList",
      faxBlobId === 0
        ? ""
        : selectedFaxBlobIds.map((id) => id.blobId).toString()
    );

    formData.append("faxToNumber", getValue(data, "faxNumber"));
    formData.append("faxToName", getValue(data, "sendTo"));
    formData.append("faxFrom", getValue(data, "sentFrom"));

    formData.append("faxFromName", "");

    formData.append("additionalInformation", getValue(data, "notes"));
    formData.append("faxStatus", "");
    if (faxFiles && faxFiles.length > 0) {
      for (let i = 0; i < faxFiles.length; i++) {
        formData.append("faxFile", faxFiles[i], faxFiles[i].name);
      }
    } else {
      formData.append("faxFile", "");
    }
    saveInitiateFax(formData);
    resetStates([PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_RESET]);
  };

  const handleCloseSendFax = () => {
    setSelectedFaxBlobIds([]);
    setOpenFax(false);
    setAddDocForFax(false);
  };

  const handleDocFaxCancel = () => {
    setSelectedFaxBlobIds([]);
    setFileForFax([]);
    setAddDocForFax(false);
  };

  useEffect(() => {
    if (!isNil(DocSentResponse) && DocSentResponse.referralBlobId !== -1) {
      setFileForFax([]);
      setOpenFax(false);
      setAddDocForFax(false);
      resetStates([PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_RESET]);
    }
  }, [DocSentResponse]);

  return (
    <Grid container sx={NotesDisciplineGridbody} ref={faxRef}>
      <Grid container sx={FaxGridBody}>
        <Grid container sx={SendFaxHeading}>
          <Grid item xs={9.8}>
            <Typography
              variant="h4"
              fontWeight={fontWeight.Weight[6]}
              color={colors.fonts[20]}
            >
              SEND FAX:
            </Typography>
          </Grid>
          <Grid item xs={2.2}>
            <Button
              variant="contained"
              onClick={() => addNewDocumentforFax()}
              disabled={addDocForFax}
            >
              UPLOAD DOCUMENT
            </Button>
          </Grid>
        </Grid>
        {showSenderType !== false && (
          <Grid container pl={"3rem"}>
            <Grid item xs={12}>
              <Controller
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <>
                    <RadioGroup
                      aria-labelledby="controlled-radio-buttons-group"
                      row={true}
                      sx={ReceivedMethodRadio}
                      {...field}
                    >
                      {faxProvider &&
                        length(faxProvider) &&
                        faxProvider &&
                        faxProvider.map((singleOption: any) => (
                          <Grid
                            item
                            xs={2}
                            key={singleOption.faxReceiverId}
                            pb={"1.2rem"}
                          >
                            <FormControlLabel
                              value={Number(singleOption.faxReceiverId)}
                              label={
                                <Box sx={flexAlignCentre}>
                                  <Typography
                                    variant="subtitle2"
                                    color={colors.black[2]}
                                    fontWeight={fontWeight.Weight[5]}
                                  >
                                    {singleOption.faxReceiver}
                                  </Typography>
                                </Box>
                              }
                              control={<Radio />}
                            />
                          </Grid>
                        ))}
                    </RadioGroup>

                    <FormHelperText>
                      {error ? error.message : null}
                    </FormHelperText>
                  </>
                )}
                name={``}
                control={faxControl}
              />
            </Grid>
          </Grid>
        )}
        <Grid container sx={SendFaxFeilds}>
          <Grid item xs={3.8}>
            <FormInputText
              name="sendTo"
              control={faxControl}
              label="To:"
              helper={rules.FaxTo}
              textLength={100}
              multiline={true}
            />
          </Grid>
          <Grid item xs={3.8}>
            <FormInputText
              name="sentFrom"
              control={faxControl}
              label="From:"
              textLength={100}
              multiline={true}
              helper={rules.FaxFrom}
            />
          </Grid>
          <Grid item xs={3.8}>
            <PhoneFaxExtInput
              label="Fax:"
              control={faxControl}
              phoneFaxName={`faxNumber`}
              rules={rules.sendFax}
              isFax
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} pl={"3rem"} pt={"1.5rem"}>
            <FormControl sx={DescriptionStyle} variant="standard">
              <FormInputText
                name="notes"
                control={faxControl}
                label="Additional Information"
                inputVariant="outlined"
                inputRows={5}
                multiline={true}
                textLength={250}
                helper={rules.FaxAdditionalInfo}
              />
            </FormControl>
          </Grid>
        </Grid>

        {addDocForFax === true && (
          <Grid container>
            <Grid item xs={12} sx={SenFaxGridbody}>
              <Grid item xs={4.5} sx={FileHeaderGrid}>
                <Grid container sx={FileUploadBox} spacing={3}>
                  {faxBlobId === 0 ? (
                    <Grid item xs={6}>
                      <Box sx={FlexBox}>
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.fonts[20]}
                          sx={FormLabelSpace}
                        >
                          Add files:
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.black[5]}
                        >
                          Allowed file types (.pdf, .jpeg, .jpg, .png)
                        </Typography>
                      </Box>
                      <Box sx={FlexBox}>
                        <Typography
                          variant="body1"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.black[5]}
                          pt={"0.2rem"}
                          pl={"4.4rem"}
                        >
                          Max allowed size for each file: {maxFileSizeInMB}MB
                        </Typography>
                      </Box>
                      <Box sx={FlexBox}>
                        <Typography
                          variant="body1"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.black[5]}
                          pt={"0.2rem"}
                          pl={"4.4rem"}
                        >
                          Max allowed size for all files: {documentFaxMaxSize}MB
                        </Typography>
                      </Box>
                      <FileUpload
                        inputType={inputType.referralDocs}
                        files={faxFiles}
                        setFiles={setFileForFax}
                        type={DocumentTabType.AUTH_DETAILS}
                        maxFileSizeInBytes={maxFileSizeInBytes}
                        minFileSize={minFileSize}
                      />
                      {!isEdit && (
                        <Controller
                          defaultValue={""}
                          control={faxControl}
                          name="faxFile"
                          render={() => <FormHelperText></FormHelperText>}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <Grid container>
                        {selectedFaxBlobIds.length === 0 && faxBlobId > -1 ? (
                          <Grid item xs={12}>
                            <Box sx={flexAlignCentre}>
                              <Box sx={SendFaxDocumentTypeIcon}>
                                {getDocTypeIcon(
                                  extractDocType(editFaxDocMediaType)
                                )}
                              </Box>
                              <Typography
                                variant="h5"
                                fontWeight={fontWeight.Weight[6]}
                                color={colors.primary.main}
                              >
                                {editFaxDocName}
                              </Typography>
                            </Box>
                          </Grid>
                        ) : selectedFaxBlobIds.length > 0 ? (
                          selectedFaxBlobIds.map((name: any) => (
                            <Grid item xs={12} key={name}>
                              <Box sx={flexAlignCentre}>
                                <Box sx={SendFaxDocumentTypeIcon}>
                                  {getDocTypeIcon(
                                    extractDocType(editFaxDocMediaType)
                                  )}
                                </Box>
                                <Typography
                                  variant="h5"
                                  fontWeight={fontWeight.Weight[6]}
                                  color={colors.primary.main}
                                >
                                  {name.fileName}
                                </Typography>
                              </Box>
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            variant="h5"
                            fontWeight={fontWeight.Weight[6]}
                            color={colors.primary.main}
                          >
                            Please select Files
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {faxBlobId === 0 && (
                    <Grid item xs={2.5} mt={"5.2rem"}>
                      <FormControl variant="standard">
                        <Button
                          variant="outlined"
                          onClick={handleDocFaxCancel}
                          sx={CancelButton}
                        >
                          CANCEL
                        </Button>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" sx={ButtonFormControlFax}>
              <Button
                variant="outlined"
                onClick={handleCloseSendFax}
                sx={CancelButton}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                onClick={faxHandleSubmit(handleSendFax)}
              >
                SEND FAX
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentSendFax;
