import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../store/ApplicationState";
import AssignStaff from "../components/MyPatientsDetails/AssignStaff";
import {
  PutIncompletePatientListRecord,
  PutMyPatientListRecord,
} from "../../../actions/AllPatientRecord";
import {
  AssignedStaff,
  AssignStaffIncompleteRequest,
} from "../../../models/Patient";
import { getUserListWithFilter } from "../../../actions/User";
import { UserListWithFilterPayload, User, UserDetailsPayload } from "../../../models/User";
import { StaffingAssignStaff, StaffingUnassignStaff } from "../../../models/Staffing";
import {
  postStaffingAssignStaff,
  postStaffingUnassignStaff,
} from "../../../actions/Staffing";

const mapStateToProps = ({
  loggedInUserState,
  myPatientListState,
  userListWithFilterState,
  putMyPatientListState,
}: ApplicationState) => ({
  MyPatientsRecord: myPatientListState,
  user: loggedInUserState.response as UserDetailsPayload,
  userList: userListWithFilterState.response as User[],
  myPatientUpdatedData: putMyPatientListState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, Record<string, never>, Action<any>>
) => {
  return {
    updateMyPatientList: (
      assignedToStaffId: number,
      payload: AssignedStaff
    ) => {
      dispatch(PutMyPatientListRecord(assignedToStaffId, payload));
    },
    updateIncompletePatientList: (
      assignedToUserId: number,
      payload: AssignStaffIncompleteRequest
    ) => {
      dispatch(PutIncompletePatientListRecord(assignedToUserId, payload));
    },
    getStaffList: (payload:UserListWithFilterPayload) => {
      dispatch(getUserListWithFilter(payload));
    },
    postStaffingAssignStaff: (payload: StaffingAssignStaff) => {
      dispatch(postStaffingAssignStaff(payload));
    },
    postStaffingUnassignStaff: (payload: StaffingUnassignStaff) => {
      dispatch(postStaffingUnassignStaff(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignStaff);
