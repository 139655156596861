import { Grid } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { FormState, UseFormGetValues, UseFormTrigger } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { isArray, isEmpty, size } from "lodash";

import {
  Gridbody,
  GridContainer,
  OuterbodyGrid,
} from "../styles/PatientAuthorizationDetails";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import ReAuthTimeline from "./ReAuthTimeline";
import {
  AuthorizationDetailTabs,
  GeneralInfo,
  ReAuthComplete,
  SelectedAuthorization,
} from "../../../../../models/Authorization";
import { DocumentBody } from "../styles/AuthPhysicianDetails";
import AuthPhysicianDetails from "../containers/PhysicianDetails";
import Disciplines from "../containers/Disciplines";
import DocumentsReview from "../../ReferralIntake/containers/DocumentsReview";
import ServiceProviderDetails from "../containers/ServiceProviderDetails";
import {
  EligibilityCheckDispatchTypes,
  Icd10RecordsActionDispatchTypes,
  PatientDetailType,
  ReAuthReferralRequestDispatchType,
} from "../../../../../constants/AllPatientRecord";
import {
  ReferralDiscipline,
  UpdatedReferralDiscipline,
} from "../../../../../models/Disciplines";
import { SaveIcd10Records } from "../../../../../models/Patient";
import { UrgencyStatusMasterResponse } from "../../../../../models/Api/Patient";
import AuthorizationDetailsTabs from "./AuthorizationDetailsTabs";
import {
  DisciplineRequestorResponse,
  DisciplineTemplateResponse,
  DisciplineTypeResponse,
  ReferralServiceStatusResponse,
  ServiceStatusReasonResponse,
  PsychologicalHistoryResponse,
  PriorAuthReferralSourceMasterResponse,
  DocumentTypeResponse,
  ReAuthReferralSourceMasterResponse,
} from "../../../../../models/Api/Master";
import { CheckEligibilityResponse } from "../../../../../models/Api/AllPatientRecord";
import { UpdateDisciplineResponse } from "../../../../../models/Api/Discipline";
import CreateReferral from "../../PatientDetails/containers/CreateReferral";
import ReauthCompleted from "../components/ReauthComplete";
import ResumeIntake from "../containers/ResumeIntake";
import {
  EligibilityRecord,
  RationaleTemplatePayload,
} from "../../../../../models/Master";
import { getList, getValue } from "../../../../../utils";
import {
  PutAuthorizationDetailTabResponse,
  ReAuthTimelineResponse,
  ResumeIntakeResponse,
} from "../../../../../models/Api/Authorization";
import ReferralNote from "../containers/ReferralNote";
import { EligibilityRecordActionDispatchTypes } from "../../../../../constants/Master";
import { PostFollowingPhysicianDetailsActionDispatchTypes } from "../../../../../constants/PhysicianDetails";
import { ReAuthServiceRequestActionDispatchType } from "../../../../../constants/Service";
import {
  DocumentReviewActionDispatchTypes,
  DocumentTabType,
} from "../../../../../constants/Document";
import { UpdateDisciplineActionDispatchTypes } from "../../../../../constants/Discipline";
import {
  PatientInformationActionDispatchTypesView,
  ReAuthHealthPlanChangedMessage,
} from "../../../../../constants/Patient";
import {
  AuthorizationDetailTabsActionDispatchTypes,
  GeneralInfoActionDispatchTypes,
  PutAuthorizationDetailTabActionDispatchTypes,
  ServiceProviderDetailsActionDispatchTypes,
} from "../../../../../constants/Authorization";
import InfoDialog from "../../../../../components/custom/infoDialogue/components/InfoDialog";
import AgencyCommunication from "../containers/AgencyCommunication";
import { DropdownOption } from "../../../../../components/formComponents/constant/FormComponents";

export interface propsFromDispatch {
  authValues: AuthorizationDetailTabs;
  getDisciplineRequestorResp: () => void;
  getDisciplineTemplateResp: (payload: RationaleTemplatePayload) => void;
  getDisciplineTypeResp: () => void;
  getPatient: (patientId: number, type: number) => void;
  getReauthComplete: (referralId: number) => void;
  getReAuthTimelineData: (referralId: string) => void;
  getReferralServicestatusResp: (value: number) => void;
  getResumeIntakeInformation: (referralId: number) => void;
  getServiceStatusReasonResp: (serviceId: number) => void;
  getUrgencyMasterResp: () => void;
  getUpdateSurveyDetails: (data: any) => void;
  getValues: UseFormGetValues<any>;
  icd10RecordsAppend: any;
  icd10RecordsFields: any[];
  icd10RecordsRemove: any;
  icd10Response: any;
  icdvalues: SaveIcd10Records; //TBD
  setValue: (id: any, value: any, boolean: any) => void;
  updateDisciplinesData: (
    authDisciplineId: number,
    payload: UpdatedReferralDiscipline
  ) => void;
  updatePhysicianReviewData: (
    authDisciplineId: number,
    payload: ReferralDiscipline
  ) => void;
  getPsychologicalHistory: () => void;
  getPriorAuthReferralSourceFacility: () => void;
  getReAuthReferralSourceFacility: () => void;
  resetStates: (actionType: string[]) => void;
  getDocumentType: () => void;
  getAuthorizationStatusMasterResp: () => void;
  getCareCoordinationProgram: () => void;
  getCareGiverMasterResp: () => void;
  getDischargeReasonMasterResp: () => void;
  getHealthProgram: () => void;
  getStaffingStatusMasterResp: () => void;
  getUrgencyStatusMasterResp: () => void;
  getPastMedicalHistory: () => void;
  getLevelOfFunctionRecords: (referralId: number) => void;
  getAuthorizationDetailTabs: (referralId: string) => void;
}

export interface PropsFromState {
  authPastMedicalHistoryInfo: any;
  checkEligibilityData: CheckEligibilityResponse;
  control: any;
  disciplineRequestorStatus: DisciplineRequestorResponse;
  disciplineTemplateStatus: DisciplineTemplateResponse;
  disciplineTypeStatus: DisciplineTypeResponse;
  eligibilityRecord: EligibilityRecord;
  generalInfoValues: GeneralInfo;
  patientDetailData: PatientInformationViewResponse;
  pageStatus?: any;
  patientData: PatientInformationViewResponse | any;
  patientSelectedAuthStatus: SelectedAuthorization;
  reauthCompleteData: ReAuthComplete;
  reAuthTimeline: ReAuthTimelineResponse;
  referralServiceStatus: ReferralServiceStatusResponse;
  resumeIntakeInformation: ResumeIntakeResponse;
  serviceStatusReasonStatus: ServiceStatusReasonResponse;
  updatedDisciplineResp: UpdateDisciplineResponse;
  urgencyStatus: UrgencyStatusMasterResponse;
  watch: any;
  psychologicalHistoryData: PsychologicalHistoryResponse;
  priorAuthReferralSource: PriorAuthReferralSourceMasterResponse;
  reAuthReferralSourceFacility: ReAuthReferralSourceMasterResponse;
  reset: any;
  clearErrors: any;
  trigger: UseFormTrigger<any>;
  documentTypeResponse: DocumentTypeResponse;
  putAuthorizationDetailTabData: PutAuthorizationDetailTabResponse;
  formState: FormState<any>;
  authorizationDetailTabs: AuthorizationDetailTabs;
}

type AllProps = PropsFromState & propsFromDispatch;

const PatientAuthorizationDetails: React.FC<AllProps> = ({
  authValues,
  checkEligibilityData,
  control,
  disciplineRequestorStatus,
  disciplineTemplateStatus,
  disciplineTypeStatus,
  eligibilityRecord,
  getDisciplineRequestorResp,
  getDisciplineTemplateResp,
  getDisciplineTypeResp,
  getPatient,
  getReauthComplete,
  getReAuthTimelineData,
  getReferralServicestatusResp,
  getResumeIntakeInformation,
  getServiceStatusReasonResp,
  getUrgencyMasterResp,
  getUpdateSurveyDetails,
  getValues,
  icd10RecordsAppend,
  icd10RecordsFields,
  icd10RecordsRemove,
  icd10Response,
  icdvalues,
  pageStatus,
  patientDetailData,
  reAuthTimeline,
  psychologicalHistoryData,
  watch,
  urgencyStatus,
  referralServiceStatus,
  resumeIntakeInformation,
  serviceStatusReasonStatus,
  setValue,
  updateDisciplinesData,
  updatePhysicianReviewData,
  getPsychologicalHistory,
  updatedDisciplineResp,
  priorAuthReferralSource,
  reAuthReferralSourceFacility,
  getPriorAuthReferralSourceFacility,
  getReAuthReferralSourceFacility,
  reset,
  clearErrors,
  trigger,
  resetStates,
  getDocumentType,
  documentTypeResponse,
  reauthCompleteData,
  putAuthorizationDetailTabData,
  getAuthorizationStatusMasterResp,
  getCareCoordinationProgram,
  getCareGiverMasterResp,
  getDischargeReasonMasterResp,
  getHealthProgram,
  getStaffingStatusMasterResp,
  getUrgencyStatusMasterResp,
  getPastMedicalHistory,
  formState,
  authorizationDetailTabs,
  getLevelOfFunctionRecords,
  getAuthorizationDetailTabs
}: AllProps) => {
  const [localAuthNo, setLocalAuthNo] = useState("");
  const [resumeIntakeData, setResumeIntakeData] =
    useState<ResumeIntakeResponse>();
  const [renderReferralNotes, setRenderReferralNotes] =
    useState<boolean>(false);
  const [serviceDetails, setServiceDetails] = useState(false);

  const [
    priorAuthReferralSourceFacilityList,
    setPriorAuthReferralSourceFacilityList,
  ] = useState<DropdownOption[]>([]);

  const [
    reAuthReferralSourceFacilityList,
    setReAuthReferralSourceFacilityList,
  ] = useState<DropdownOption[]>([]);

  const { response: PriorAuthReferralSourceFacilityResponse } =
    priorAuthReferralSource;
  const { response: ReAuthReferralSourceFacilityResponse } =
    reAuthReferralSourceFacility;

  const { pathname } = useLocation();
  const { loading } = patientDetailData;
  const { loading: loadingDocumentType, response: documentType } =
    documentTypeResponse;

  const location = useLocation();
  const { authNo } = useParams();

  const disciplineRef = useRef<HTMLDivElement>(null);

  const urlParams = new URLSearchParams(location.search);
  const reAuthType = urlParams.get("reAuthType");
  const [isManual, setIsManual] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setLocalAuthNo(authNo || "0");
    getPsychologicalHistory();
    if (!loadingDocumentType) {
      getDocumentType();
    }
    return () => {
      resetStates([
        PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_RESET,
        GeneralInfoActionDispatchTypes.GENERAL_INFO_RESET,
        PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_RESET,
        AuthorizationDetailTabsActionDispatchTypes.AUTH_DETAIL_TABS_RESET,
        EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET,
        EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_RESET,
        ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_RESET,
        Icd10RecordsActionDispatchTypes.ICD_10_RESET,
        PostFollowingPhysicianDetailsActionDispatchTypes.FOLLOWING_PHYSICIAN_DETAILS_RESET,
        ReAuthServiceRequestActionDispatchType.RE_AUTH_SERVICE_REQUEST_RESET,
        DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_RESET,
        UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_RESET,
        ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    getUrgencyStatusMasterResp();
    getStaffingStatusMasterResp();
    getCareGiverMasterResp();
    getDischargeReasonMasterResp();
    getAuthorizationStatusMasterResp();
    getHealthProgram();
    getCareCoordinationProgram();
    getPsychologicalHistory();
    getPastMedicalHistory();
    getPriorAuthReferralSourceFacility();
    getReAuthReferralSourceFacility();
    getLevelOfFunctionRecords(Number(authNo));
  }, []);

  useEffect(() => {
    setResumeIntakeData(resumeIntakeInformation);
  }, [resumeIntakeInformation]);

  useEffect(() => {
    if (localAuthNo) {
      getResumeIntakeInformation(Number(localAuthNo));
      getReauthComplete(Number(localAuthNo));
      getReAuthTimelineData(localAuthNo);
    }
  }, [localAuthNo]);

  useEffect(() => {
    if (
      PriorAuthReferralSourceFacilityResponse &&
      isArray(PriorAuthReferralSourceFacilityResponse)
    ) {
      setPriorAuthReferralSourceFacilityList(
        getList(PriorAuthReferralSourceFacilityResponse, {
          label: "facilityTypeName",
          value: "facilityTypeId",
        })
      );
    }
    if (
      ReAuthReferralSourceFacilityResponse &&
      isArray(ReAuthReferralSourceFacilityResponse)
    ) {
      setReAuthReferralSourceFacilityList(
        getList(ReAuthReferralSourceFacilityResponse, {
          label: "facilityTypeName",
          value: "facilityTypeId",
        })
      );
    }
  }, [
    PriorAuthReferralSourceFacilityResponse,
    ReAuthReferralSourceFacilityResponse,
  ]);

  useEffect(() => {
    if (!loading) {
      if (authNo) {
        setLocalAuthNo(authNo ? authNo : "");
        getPatient(Number(authNo || 0), PatientDetailType.Referral);
      }
    }
  }, []);

  const handleRef = (e: any) => {
    e.preventDefault();
    disciplineRef &&
      disciplineRef.current &&
      disciplineRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [pathname]);
  const isReAuthCompleted = () => {
    return (
      reAuthType === "1" && getValue(reauthCompleteData, "serviceRequestId") > 0
    );
  };
  const isPriorAuthIntakeStageComplete = () =>
    getValue(patientDetailData, "response.isPriorAuthIntakeStageComplete");

  const isReAuthIntakeStageComplete = () =>
    getValue(patientDetailData, "response.isReAuthIntakeStageComplete");

  const proceedManuallySection = () => {
    return (
      <CreateReferral
        responseData={patientDetailData}
        pageStatus={pageStatus}
        referralId={localAuthNo}
        eligibilityRecord={eligibilityRecord}
        isModified={false}
        isProceedManually={true}
        checkEligibilityOpenModal={false}
        setIsManual={setIsManual}
        isManual={isManual}
        setIsOpen={setIsOpen}
      />
    );
  };

  const supplementalInfo = getValue(
    checkEligibilityData,
    "response.supplementalInformation.info",
    []
  );
  const benefitPlanUid = getValue(
    checkEligibilityData,
    "response.additionalNotes.benefitPlanUid",
    ""
  );
  const scrubbedSubscriberID = getValue(
    checkEligibilityData,
    "response.additionalNotes.scrubbedSubscriberID",
    ""
  );

  const shouldProceedManually =
    size(supplementalInfo) == 0 ||
    isEmpty(benefitPlanUid) ||
    isEmpty(scrubbedSubscriberID);

  const shouldRenderCreateReferral =
    checkEligibilityData &&
    checkEligibilityData.loading === false &&
    !shouldProceedManually;

  return (
    <>
      <Grid container sx={Gridbody}></Grid>
      <Grid>
        {shouldRenderCreateReferral && (
          <CreateReferral
            responseData={patientDetailData}
            pageStatus={pageStatus}
            referralId={localAuthNo}
            eligibilityRecord={eligibilityRecord}
            isModified={false}
          />
        )}
      </Grid>
      <Grid>{shouldProceedManually && proceedManuallySection()}</Grid>
      <Grid>
        {isReAuthCompleted() && (
          <ReauthCompleted
            handleRef={handleRef}
            reauthCompleteData={reauthCompleteData}
          />
        )}
      </Grid>
      <Grid>
        {getValue(resumeIntakeData, "response", []).length > 0 ? (
          <ResumeIntake referralId={localAuthNo} />
        ) : (
          getValue(reAuthTimeline, "response", []).length > 0 && (
            <ReAuthTimeline
              getReAuthTimelineData={getReAuthTimelineData}
              reAuthTimeline={reAuthTimeline}
              handleRef={handleRef}
              updatedDisciplineResp={updatedDisciplineResp}
              putAuthorizationDetailTabData={putAuthorizationDetailTabData}
            />
          )
        )}
      </Grid>
      <Grid container sx={GridContainer}>
        <Grid item xs={12} sx={OuterbodyGrid}>
          <AuthorizationDetailsTabs
            values={authValues}
            watch={watch}
            control={control}
            reset={reset}
            icd10RecordsFields={icd10RecordsFields}
            icd10RecordsAppend={icd10RecordsAppend}
            icd10RecordsRemove={icd10RecordsRemove}
            icd10Response={icd10Response}
            icdvalues={icdvalues}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            trigger={trigger}
            psychologicalHistoryData={psychologicalHistoryData}
            formState={formState}
            authorizationDetailTabs={authorizationDetailTabs}
          />
          <ReferralNote
            setRenderReferralNotes={setRenderReferralNotes}
            renderReferralNotes={renderReferralNotes}
            setServiceDetails={setServiceDetails}
          />
          <Grid ref={disciplineRef}>
            <Disciplines
              urgencyStatus={urgencyStatus}
              getUrgencyMasterResp={getUrgencyMasterResp}
              getReferralServicestatusResp={getReferralServicestatusResp}
              referralServiceStatus={referralServiceStatus}
              disciplineTypeStatus={disciplineTypeStatus}
              getDisciplineTypeResp={getDisciplineTypeResp}
              getServiceStatusReasonResp={getServiceStatusReasonResp}
              getDisciplineTemplateResp={getDisciplineTemplateResp}
              disciplineTemplateStatus={disciplineTemplateStatus}
              serviceStatusReasonStatus={serviceStatusReasonStatus}
              getDisciplineRequestorResp={getDisciplineRequestorResp}
              disciplineRequestorStatus={disciplineRequestorStatus}
              updateDisciplinesData={updateDisciplinesData}
              updatePhysicianReviewData={updatePhysicianReviewData}
              getReAuthTimelineData={getReAuthTimelineData}
              reAuthTimeline={reAuthTimeline}
              updatedDisciplineResp={updatedDisciplineResp}
              getUpdateSurveyDetails={getUpdateSurveyDetails}
              priorAuthReferralSourceFacilityList={
                priorAuthReferralSourceFacilityList
              }
              reAuthReferralSourceFacilityList={
                reAuthReferralSourceFacilityList
              }
              patientDetailData={patientDetailData}
              setRenderReferralNotes={setRenderReferralNotes}
              setServiceDetails={setServiceDetails}
              serviceDetails={serviceDetails}
            />
          </Grid>
          <AgencyCommunication />
          <ServiceProviderDetails
            modifyDetails={isPriorAuthIntakeStageComplete()}
            getReAuthTimelineData={getReAuthTimelineData}
            getAuthorizationDetailTabs={getAuthorizationDetailTabs}
            setValue={setValue}
            control={control}
          />
          <AuthPhysicianDetails
            modifyOrderingProvider={isPriorAuthIntakeStageComplete()}
            modifyFollowingProvider={isReAuthIntakeStageComplete()}
          />
          <Grid sx={DocumentBody}>
            <DocumentsReview
              isAuthDetails={true}
              refId={localAuthNo}
              documentType={documentType || []}
              type={DocumentTabType.AUTH_DETAILS}
            />
          </Grid>
        </Grid>
        <InfoDialog
          alertMessage={ReAuthHealthPlanChangedMessage}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </Grid>
    </>
  );
};

export default PatientAuthorizationDetails;
